import { Fill, Style, Text, Stroke, Icon, Circle, Image, RegularShape } from 'ol/style';
import * as Color from 'color';
import Feature, { FeatureLike } from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { DashStyle, ILabelOptions, IStyle, IStyleCondition, MapStyleSource } from './models/analytics-style';
import { Map } from 'ol';
import { StyleFunction } from 'ol/style/Style';
import { THEME_COLORS } from '..';
import { MultiLineString } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';

let cachedLayerStyles = {};

const RegShapePointsMapper = {
  triangle: 3,
  square: 4,
  pentagon: 5,
  hexagon: 6,
};

const RegShapeAngleMapper = {
  triangle: 0,
  square: Math.PI / 4,
  pentagon: 0,
  hexagon: 0,
};

export function resetLayerCache() {
  cachedLayerStyles = {};
}

export function defaultStyle(feature: FeatureLike) {
  const DEFAULT_STYLES = {
    Point: new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: 'assets/images/map/marker.svg',
        scale: 0.8,
        color: feature.get('drawStyle')?.color ?? THEME_COLORS.GENERIC.PRIMARY,
        anchor: [0.5, 0.95],
      }),
    }),
    LineString: [
      new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 5,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: feature.get('drawStyle')?.color ?? THEME_COLORS.GENERIC.PRIMARY,
          width: 3,
          lineCap: 'round',
        }),
      }),
    ],
    Polygon: new Style({
      stroke: new Stroke({
        color: feature.get('drawStyle')?.color ?? THEME_COLORS.GENERIC.PRIMARY,
        width: 3,
        lineCap: 'round',
      }),
      fill: new Fill({
        color: feature.get('drawStyle')?.color
          ? Color(feature.get('drawStyle')?.color).alpha(0.2).string()
          : Color(THEME_COLORS.GENERIC.PRIMARY).alpha(0.2).string(),
      }),
    }),
  };

  return DEFAULT_STYLES[feature.getGeometry().getType()];
}

export function drawStyle(feature: FeatureLike, myDrawStyle?: IStyle, geomType?: Type): Style[] {
  let styles = [];
  const geometry = feature.getGeometry();
  const categoryStyle: IStyle = myDrawStyle ? myDrawStyle : feature.get('drawStyle');
  const image = getImage(categoryStyle, false, geometry.getType(), geomType);

  const lineDash = getLineDash(categoryStyle.dashStyle, categoryStyle.strokeWidth);

  let lineBorder: Style;

  if (geomType !== 'Polygon' && geometry instanceof LineString && categoryStyle.lineBorderWidth > 0) {
    const isRoute = Boolean(feature.get('transportMode'));
    lineBorder = getLineBorder(categoryStyle, isRoute, feature);
  }

  const general = new Style({
    image,
    fill: new Fill({
      color: categoryStyle.fillColor
        ? categoryStyle.fillColor
        : categoryStyle.color
        ? Color(categoryStyle.color).alpha(0.2).string()
        : 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: categoryStyle.color ? categoryStyle.color : THEME_COLORS.GENERIC.PRIMARY,
      width: categoryStyle.strokeWidth ? categoryStyle.strokeWidth : 3,
      lineDash: feature.get('dashed') ? [10, 10] : categoryStyle.lineStyle === 'dashed' ? lineDash : null,
      lineCap: 'round',
    }),
  });

  styles = lineBorder ? [lineBorder, general] : [general];

  if (categoryStyle.lineStyle === 'arrow' && geometry instanceof LineString && !feature.get('transportMode')) {
    styles = getArrowStyle(feature, styles, categoryStyle);
  } else if (
    geometry instanceof Point &&
    categoryStyle.pointStyle === 'icon' &&
    categoryStyle.iconBackground &&
    categoryStyle.iconBackground.type === 'circle'
  ) {
    const bgCircle = new Style({
      image: new Circle({
        radius: categoryStyle.iconBackground.circleRadius,
        fill: new Fill({
          color: categoryStyle.iconBackground.color,
        }),
        stroke: new Stroke({
          color: categoryStyle.iconBackground.circleStrokeColor,
          width: categoryStyle.iconBackground.circleStrokeWidth,
        }),
      }),
    });

    styles = [bgCircle, general];
  } else if (
    geometry instanceof Point &&
    categoryStyle.pointStyle === 'icon' &&
    categoryStyle.iconBackground &&
    categoryStyle.iconBackground.type === 'marker'
  ) {
    const bgMarker = new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: `assets/images/map/marker.svg`,
        scale: categoryStyle.iconBackground.markerScale ? categoryStyle.iconBackground.markerScale / 10 : 0.1,
        anchor: [0.5, 0.8],
        color: categoryStyle.iconBackground.color ? categoryStyle.iconBackground.color : THEME_COLORS.GENERIC.PRIMARY,
      }),
    });

    styles = [bgMarker, general];
  }

  return styles;
}

export function getMapStyle(
  geometryType: Type | 'Route',
  styleConditions: IStyleCondition[],
  styleSource: MapStyleSource,
  map: Map
): Style[] | StyleFunction {
  let result: Style[] | StyleFunction = [];
  styleConditions.forEach((styleCondition) => {
    const styleObj = getStyle(styleCondition, map, geometryType);

    if (geometryType === 'Point' && styleSource === 'cluster') {
      const clusterStyle = getStyle(styleCondition, map, geometryType, true);
      result = (feature: Feature) => {
        return updateClusterSize(feature, clusterStyle, styleCondition.style);
      };

      return;
    }

    if (geometryType === 'LineString' && styleCondition.style.lineStyle === 'arrow') {
      result = (feature: Feature) => {
        return getArrowStyle(feature, styleObj, styleCondition.style);
      };
      return;
    }

    if (geometryType === 'Route') {
      const colorArray = Color(styleCondition.style.color).rgb().array();
      if (colorArray && colorArray[3] && colorArray[3] < 1) {
        result = (feature: Feature) => {
          let style = cachedLayerStyles[feature.getId()];
          if (!style) {
            style = getStyle(styleCondition, map);
            cachedLayerStyles[feature.getId()] = style;
          }
          return style;
        };
        return;
      }
    }

    if (
      geometryType === 'LineString' ||
      ((geometryType === 'Point' || geometryType === 'Polygon') && styleCondition.label?.field)
    ) {
      result = (feature: Feature) => {
        let style = cachedLayerStyles[`${feature.getId()}-${Math.round(map.getView().getZoom() * 10)}`];
        if (!style) {
          style = getStyle(styleCondition, map, geometryType, null, feature);
          cachedLayerStyles[`${feature.getId()}-${Math.round(map.getView().getZoom() * 10)}`] = style;
        }
        return style;
      };
      return;
    }

    if (
      geometryType === 'Point' &&
      styleCondition.style.pointStyle === 'icon' &&
      styleCondition.style.iconBackground &&
      styleCondition.style.iconBackground.type === 'circle'
    ) {
      const bgCircle = new Style({
        image: new Circle({
          radius: styleCondition.style.iconBackground.circleRadius,
          fill: new Fill({
            color: styleCondition.style.iconBackground.color,
          }),
          stroke: new Stroke({
            color: styleCondition.style.iconBackground.circleStrokeColor,
            width: styleCondition.style.iconBackground.circleStrokeWidth,
          }),
        }),
      });

      if (Array.isArray(result)) {
        result.push(bgCircle);
      } else {
        result = [bgCircle];
      }
    } else if (
      geometryType === 'Point' &&
      styleCondition.style.pointStyle === 'icon' &&
      styleCondition.style.iconBackground &&
      styleCondition.style.iconBackground.type === 'marker'
    ) {
      const bgMarker = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          src: `assets/images/map/marker.svg`,
          scale: styleCondition.style.iconBackground.markerScale
            ? styleCondition.style.iconBackground.markerScale / 10
            : 0.1,
          anchor: [0.5, 0.8],
          color: styleCondition.style.iconBackground.color
            ? styleCondition.style.iconBackground.color
            : THEME_COLORS.GENERIC.PRIMARY,
        }),
      });

      if (Array.isArray(result)) {
        result.push(bgMarker);
      } else {
        result = [bgMarker];
      }
    }

    if (Array.isArray(result)) {
      result.push(...styleObj);
      return;
    }

    result = styleObj;
  });

  return result;
}

function getStyle(
  styleCondition: IStyleCondition,
  map: Map,
  geometryType?: Type | 'Route',
  isCluster?: boolean,
  feature?: Feature
): Style[] {
  const style = styleCondition.style;

  const general = new Style({
    image: getImage(style, isCluster),
    fill: new Fill({
      color: style.fillColor,
    }),
    stroke: new Stroke({
      color: style.color,
      width: style.strokeWidth + Math.random() / 1e9,
      lineDash: style.lineStyle === 'dashed' ? getLineDash(style.dashStyle, style.strokeWidth) : null,
      lineCap: 'round',
    }),
    text: new Text({
      text: canShowLabel(styleCondition.label, map, feature)
        ? `${
            feature.get(styleCondition.label?.field.toLowerCase()) ||
            feature.get(styleCondition.label?.field.toLowerCase()) === 0
              ? feature.get(styleCondition.label?.field?.toLowerCase())
              : ''
          }`
        : '',
      fill: new Fill({
        color: styleCondition.label?.textColor ?? '#fff',
      }),
      stroke: styleCondition.label?.showHalo
        ? new Stroke({
            color: styleCondition.label?.haloColor ?? '#000',
            width: 3,
          })
        : null,
      textAlign: getTextAlignment(styleCondition.label, geometryType),
      placement: geometryType === 'LineString' ? 'line' : 'point',
      overflow: false,
      padding: [20, 20, 20, 20],
      offsetY: getTextOffsetY(styleCondition.label, geometryType),
      offsetX: getTextOffsetX(styleCondition.label, geometryType),
      font: `${styleCondition.label?.fontSize}px Inter, sans-serif`,
    }),
  });

  let lineBorder: Style;

  if ((geometryType === 'LineString' || geometryType === 'Route') && style.lineBorderWidth > 0) {
    lineBorder = getLineBorder(style);
  }

  const finalStyle = lineBorder ? [lineBorder, general] : [general];

  return finalStyle;
}

function canShowLabel(labelOptions: ILabelOptions, map: Map, feature: Feature): boolean {
  return Boolean(
    feature &&
      labelOptions?.field &&
      labelOptions?.field !== 'NO_LABELS' &&
      map.getView().getZoom() <= labelOptions?.maxZoom &&
      map.getView().getZoom() >= labelOptions?.minZoom
  );
}

function getTextAlignment(labelOptions: ILabelOptions, geometryType: Type | 'Route') {
  if (geometryType !== 'Point') {
    return 'center';
  }

  return labelOptions.offsetX < 0 ? 'right' : labelOptions.offsetX > 0 ? 'left' : 'center';
}

function getTextOffsetY(labelOptions: ILabelOptions, geometryType: Type | 'Route'): number {
  if (geometryType !== 'Point') {
    return 0;
  }

  return labelOptions.offsetY;
}

function getTextOffsetX(labelOptions: ILabelOptions, geometryType: Type | 'Route'): number {
  if (geometryType !== 'Point') {
    return 0;
  }

  return labelOptions.offsetX;
}

function getImage(style: IStyle, isCluster?: boolean, geometryType?: string, originalGeomType?: string): Image {
  let image: Image;
  image = new Circle({
    radius: style.circleRadius - 1,
    fill: new Fill({
      color: style.color ? style.color : THEME_COLORS.GENERIC.PRIMARY,
    }),
    stroke: new Stroke({
      color: style.circleStrokeColor ? style.circleStrokeColor : '#FFFFFF',
      width: style.circleStrokeWidth ? style.circleStrokeWidth : 2,
    }),
  });

  if (isCluster || (geometryType && geometryType !== 'Point') || (originalGeomType && originalGeomType !== 'Point')) {
    return image;
  }

  if (['triangle', 'square', 'pentagon', 'hexagon'].includes(style.pointStyle)) {
    const points = RegShapePointsMapper[style.pointStyle];
    const angle = RegShapeAngleMapper[style.pointStyle];
    image = new RegularShape({
      points,
      angle,
      radius: style.circleRadius,
      fill: new Fill({
        color: style.color ? style.color : THEME_COLORS.GENERIC.PRIMARY,
      }),
      stroke: new Stroke({
        color: style.circleStrokeColor ? style.circleStrokeColor : '#FFFFFF',
        width: style.circleStrokeWidth ? style.circleStrokeWidth : 2,
      }),
    });
    return image;
  }

  if (style.pointStyle === 'star') {
    image = new RegularShape({
      points: 5,
      angle: 0,
      radius: style.circleRadius,
      radius2: style.circleRadius * 0.45,
      fill: new Fill({
        color: style.color ? style.color : THEME_COLORS.GENERIC.PRIMARY,
      }),
      stroke: new Stroke({
        color: style.circleStrokeColor ? style.circleStrokeColor : '#FFFFFF',
        width: style.circleStrokeWidth ? style.circleStrokeWidth : 2,
      }),
    });
    return image;
  }

  if (!style.pointStyle || style.pointStyle === 'marker') {
    image = new Icon({
      crossOrigin: 'anonymous',
      src: `assets/images/map/marker.svg`,
      scale: style.markerScale ? style.markerScale : 1,
      anchor: [0.5, 0.95],
      color: style.color ? style.color : THEME_COLORS.GENERIC.PRIMARY,
    });
    return image;
  }
  if (style.pointStyle === 'icon' && style.icon) {
    if (style.iconBackground && style.iconBackground.type && style.iconBackground.iconColor) {
      image = new Icon({
        crossOrigin: 'anonymous',
        src: style.icon.replace('api.maplix.io', 'api.maplix.com'),
        scale: style.iconScale ? style.iconScale / 10 : 0.1,
        anchor: style.iconAnchor ? style.iconAnchor : [0.5, 0.5],
        color: style.iconBackground.iconColor,
      });
    } else {
      image = new Icon({
        crossOrigin: 'anonymous',
        src: style.icon.replace('api.maplix.io', 'api.maplix.com'),
        scale: style.iconScale ? style.iconScale / 10 : 0.1,
        anchor: style.iconAnchor ? style.iconAnchor : [0.5, 0.5],
      });
    }
    return image;
  }

  return image;
}

function getLineDash(dashStyle: DashStyle, width: number): number[] {
  return dashStyle === 'regular'
    ? [width * 2.25, width * 2.5]
    : dashStyle === 'short'
    ? [width, width * 1.75]
    : dashStyle === 'long-short'
    ? [width * 3.75, width * 1.75]
    : [width, width * 4];
}

function getLineBorder(style: IStyle, isRoute?: boolean, feature?: FeatureLike): Style {
  return new Style({
    stroke: new Stroke({
      width: style.strokeWidth + Math.random() / 1e9 + 2 * style.lineBorderWidth,
      color: style.lineBorderColor,
      lineDash:
        feature && feature.get('dashed')
          ? [10, 10]
          : isRoute || style.lineStyle !== 'dashed'
          ? null
          : getLineDash(style.dashStyle, style.strokeWidth),
    }),
  });
}

function getArrowStyle(feature: FeatureLike, olStyle: Style[], style: IStyle): Style[] {
  const styles = olStyle;
  const geometry = feature.getGeometry() as LineString | MultiLineString;
  const amountSegments = geometry.getCoordinates().length - 1;
  let i = 1;

  const allLineStrings: LineString[] = [];

  if (geometry instanceof MultiLineString) {
    allLineStrings.push(...geometry.getLineStrings());
  } else if (geometry instanceof LineString) {
    allLineStrings.push(geometry);
  }

  allLineStrings.forEach((line) => {
    line.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const middle = [start[0] + dx / 2, start[1] + dy / 2];
      const rotation = Math.atan2(dy, dx);

      if (style.arrowStyle === 'regular' && i === amountSegments) {
        styles.push(
          new Style({
            geometry: new Point(end),
            image: new RegularShape({
              radius: 10,
              points: 3,
              angle: Math.PI / 2,
              rotateWithView: true,
              rotation: -rotation,
              fill: new Fill({
                color: style.color,
              }),
              stroke: new Stroke({
                color: style.lineBorderColor ?? style.color,
                width: style.lineBorderWidth ?? 0,
              }),
            }),
          })
        );
      } else if (style.arrowStyle === 'segment') {
        styles.push(
          new Style({
            geometry: new Point(middle),
            image: new RegularShape({
              radius: 10,
              points: 3,
              angle: Math.PI / 2,
              rotateWithView: true,
              rotation: -rotation,
              fill: new Fill({
                color: style.color,
              }),
              stroke: new Stroke({
                color: style.lineBorderColor ?? style.color,
                width: style.lineBorderWidth ?? 0,
              }),
            }),
          })
        );
      }

      i += 1;
    });
  });

  return styles;
}

function updateClusterSize(feature: Feature, olStyle: Style[], style: IStyle): Style[] {
  const sizeFactor = style.clusterSizeFactor;
  if (feature.get('features')) {
    const size = feature.get('features').length;
    const clusterStyle = new Style({
      image: new Circle({
        radius: size > 1 ? (style.circleRadius - 1) * Math.sqrt(size * sizeFactor) : style.circleRadius - 1,
        fill: new Fill({
          color: style.color ? style.color : THEME_COLORS.GENERIC.PRIMARY,
        }),
        stroke: new Stroke({
          color: style.circleStrokeColor ? style.circleStrokeColor : '#FFFFFF',
          width: style.circleStrokeWidth ? style.circleStrokeWidth : 2,
        }),
      }),
      text: new Text({
        font: '12px Inter, sans-serif',
        text: size > 1 ? size.toString() : '',
        fill: new Fill({
          color: '#fff',
        }),
        offsetY: size * sizeFactor < 10 ? 2 / size : 0,
      }),
    });

    olStyle = [clusterStyle];
  }

  return olStyle;
}
