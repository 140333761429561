<div class="h5 mb-3" *ngIf="question && !disabled">Show this question if:</div>

<div class="wrapper">
  <ng-container *ngFor="let rule of rules['controls']; let i = index">
    <!-- This is needed for old surveys with deprecated logic on category -->
    <ng-container *ngIf="rule.get('type').value !== 'category'">
      <div class="d-flex pb-2 justify-content-between align-items-center" [formGroup]="rule">
        <div class="d-flex flex-wrap">
          <ng-select
            [clearable]="false"
            [items]="ruleTypes"
            formControlName="type"
            bindValue="id"
            bindLabel="label"
            placeholder="Rule type"
            class="small mr-1 mt-1"
          >
          </ng-select>
          <ng-select
            [clearable]="false"
            [items]="allQuestions"
            *ngIf="rule.get('type').value === 'question'"
            formControlName="questionid"
            (change)="onChangeQuestion($event)"
            bindValue="id"
            bindLabel="label"
            placeholder="Question"
            class="small mr-1 mt-1"
            appendTo=".modal-body"
          >
          </ng-select>
          <ng-container
            *ngIf="subQuestions[rule.get('questionid').value]?.length && rule.get('type').value === 'question'"
          >
            <ng-select
              [clearable]="false"
              [items]="subQuestions[rule.get('questionid').value]"
              formControlName="subquestionid"
              placeholder="Subquestion"
              bindValue="id"
              bindLabel="label"
              class="small mr-1 mt-1"
              appendTo=".modal-body"
            ></ng-select>
          </ng-container>
          <ng-container *ngIf="rule.get('type').value === 'question' && rule.get('questionid').value">
            <ng-select
              [clearable]="false"
              [items]="operators[rule.get('questionid').value]"
              formControlName="operator"
              bindValue="id"
              bindLabel="label"
              placeholder="Operator"
              class="small mr-1 mt-1"
              appendTo=".modal-body"
            >
            </ng-select>
            <ng-select
              [clearable]="false"
              [items]="fieldValues[rule.get('questionid').value]"
              formControlName="value"
              bindValue="id"
              bindLabel="label"
              *ngIf="fieldValues[rule.get('questionid').value]?.length"
              [multiple]="false"
              placeholder="Answer option"
              class="small mr-1 mt-1"
              appendTo=".modal-body"
            >
            </ng-select>
            <input
              type="text"
              class="form-control small mr-1 mt-1 d-inline"
              formControlName="value"
              *ngIf="!fieldValues[rule.get('questionid').value]?.length && !dates[rule.get('questionid').value]"
              placeholder="Answer"
            />
            <input
              type="text"
              class="form-control small"
              bsDatepicker
              formControlName="value"
              [bsConfig]="bsConfig"
              placeholder="dd/mm/yyyy"
              readonly
              *ngIf="dates[rule.get('questionid').value]"
            />
          </ng-container>
          <ng-container *ngIf="rule.get('type').value === 'transportMode'">
            <ng-select
              [clearable]="false"
              [items]="modeOperators"
              formControlName="operator"
              bindValue="id"
              bindLabel="label"
              placeholder="Operator"
              class="small mr-1 mt-1"
              appendTo=".modal-body"
            >
            </ng-select>
            <ng-select
              [clearable]="false"
              [items]="transportModes"
              formControlName="value"
              bindValue="id"
              appendTo=".modal-body"
              bindLabel="label"
              placeholder="Transport mode"
              class="small mr-1 mt-1"
            >
            </ng-select>
          </ng-container>
        </div>

        <i
          class="remixicon-indeterminate-circle-fill text-danger clickable mx-2 mt-1"
          (click)="onRemoveRule(rule)"
          ngbTooltip="Delete condition"
        ></i>
      </div>
    </ng-container>

    <div class="row no-gutters">
      <div class="col-12">
        <div
          class="h5 d-flex pb-2 justify-content-center my-0"
          *ngIf="i < rules['controls'].length - 1 && rules['controls'].length > 1"
        >
          <i
            class="remixicon-arrow-left-s-fill px-2 clickable"
            (click)="onChangeGroupOperator(rule)"
            *ngIf="!disabled"
          ></i>
          <span>{{ rule.get('groupOperator').value }}</span>
          <i
            class="remixicon-arrow-right-s-fill px-2 clickable"
            (click)="onChangeGroupOperator(rule)"
            *ngIf="!disabled"
          ></i>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    class="d-flex align-items-center my-2 clickable"
    (click)="onAddRule()"
    *ngIf="!disabled || rules['controls'].length >= 3"
  >
    <i class="remixicon-add-circle-fill mx-2 text-primary"></i>
    <span class="mx-1">Add condition</span>
  </div>

  <div class="element-container mt-3" *ngIf="!question && rules['controls'].length >= 3">
    You can only add up to three conditions per rule.
  </div>
</div>
