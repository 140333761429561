import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWithSidebarComponent } from './pages/map-with-sidebar/map-with-sidebar.component';
import { UiModule } from '@maplix/ui';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { BaselayerToggleComponent } from './components/baselayer-toggle/baselayer-toggle.component';
import { LegendLayerIconComponent } from './components/legend-layer-icon/legend-layer-icon.component';
import { MapLayersComponent } from './components/map-layers/map-layers.component';
import { MapPopupComponent } from './components/map-popup/map-popup.component';
import { SidebarToggleComponent } from './components/sidebar-toggle/sidebar-toggle.component';
import { PipesModule } from '@maplix/pipes';
import { MapFullComponent } from './pages/map-full/map-full.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImportSurveyLayersComponent } from './components/import-survey-layers/import-survey-layers.component';
import { ImportWmsLayersComponent } from './components/import-wms-layers/import-wms-layers.component';
import { ImportWfsLayersComponent } from './components/import-wfs-layers/import-wfs-layers.component';
import { ImportUploadLayersComponent } from './components/import-upload-layers/import-upload-layers.component';
import { VectorLayerStyleComponent } from './components/vector-layer-style/vector-layer-style.component';
import { RuleEditorComponent } from './components/rule-editor/rule-editor.component';
import { CloudModule } from '@maplix/cloud';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MapSidebarComponent } from './components/map-sidebar/map-sidebar.component';
import { MapAttributionComponent } from './components/map-attribution/map-attribution.component';
import { MapExportComponent } from './components/map-export/map-export.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PopupFieldtypeRadioComponent } from './components/map-popup-fieldtypes/popup-fieldtype-radio/popup-fieldtype-radio.component';
import { PopupFieldtypeCheckboxComponent } from './components/map-popup-fieldtypes/popup-fieldtype-checkbox/popup-fieldtype-checkbox.component';
import { PopupFieldtypeSelectComponent } from './components/map-popup-fieldtypes/popup-fieldtype-select/popup-fieldtype-select.component';
import { PopupFieldtypeRangeComponent } from './components/map-popup-fieldtypes/popup-fieldtype-range/popup-fieldtype-range.component';
import { PopupFieldtypeFileUploadComponent } from './components/map-popup-fieldtypes/popup-fieldtype-file-upload/popup-fieldtype-file-upload.component';
import { PopupFieldtypeTableComponent } from './components/map-popup-fieldtypes/popup-fieldtype-table/popup-fieldtype-table.component';
import { PopupFieldtypeRankingComponent } from './components/map-popup-fieldtypes/popup-fieldtype-ranking/popup-fieldtype-ranking.component';
import { PopupFieldtypeRatingComponent } from './components/map-popup-fieldtypes/popup-fieldtype-rating/popup-fieldtype-rating.component';
import { PopupFieldtypeTextboxComponent } from './components/map-popup-fieldtypes/popup-fieldtype-textbox/popup-fieldtype-textbox.component';
import { PopupFieldtypeDateComponent } from './components/map-popup-fieldtypes/popup-fieldtype-date/popup-fieldtype-date.component';
import { PopupFieldtypeBudgetComponent } from './components/map-popup-fieldtypes/popup-fieldtype-budget/popup-fieldtype-budget.component';
import { PopupFieldtypeImageSelectComponent } from './components/map-popup-fieldtypes/popup-fieldtype-image-select/popup-fieldtype-image-select.component';
import { DirectivesModule } from '@maplix/directives';
import { MapEditorComponent } from './pages/map-editor/map-editor.component';
import { CompareSliderComponent } from './components/compare-slider/compare-slider.component';
import { MapEditLayersComponent } from './components/map-edit-layers/map-edit-layers.component';
import { MapListComponent } from './components/map-list/map-list.component';
import { MapZoomIndicatorComponent } from './components/map-zoom-indicator/map-zoom-indicator.component';
import { VectorLayerLabelsComponent } from './components/vector-layer-labels/vector-layer-labels.component';
import { MapActionsComponent } from './pages/map-actions/map-actions.component';
import { MapCommentPopupComponent } from './components/map-comment-popup/map-comment-popup.component';

@NgModule({
  declarations: [
    MapWithSidebarComponent,
    BaselayerToggleComponent,
    MapPopupComponent,
    MapLayersComponent,
    LegendLayerIconComponent,
    SidebarToggleComponent,
    AddressSearchComponent,
    MapFullComponent,
    ImportSurveyLayersComponent,
    ImportWmsLayersComponent,
    ImportWfsLayersComponent,
    ImportUploadLayersComponent,
    VectorLayerStyleComponent,
    RuleEditorComponent,
    MapSidebarComponent,
    MapAttributionComponent,
    MapExportComponent,
    PopupFieldtypeRadioComponent,
    PopupFieldtypeCheckboxComponent,
    PopupFieldtypeSelectComponent,
    PopupFieldtypeRangeComponent,
    PopupFieldtypeFileUploadComponent,
    PopupFieldtypeTableComponent,
    PopupFieldtypeRankingComponent,
    PopupFieldtypeRatingComponent,
    PopupFieldtypeTextboxComponent,
    PopupFieldtypeDateComponent,
    PopupFieldtypeBudgetComponent,
    PopupFieldtypeImageSelectComponent,
    MapEditorComponent,
    CompareSliderComponent,
    MapEditLayersComponent,
    MapListComponent,
    MapZoomIndicatorComponent,
    VectorLayerLabelsComponent,
    MapActionsComponent,
    MapCommentPopupComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    PipesModule,
    TranslateModule,
    CloudModule,
    SweetAlert2Module.forRoot(),
    DragDropModule,
    DirectivesModule,
  ],
  exports: [
    MapWithSidebarComponent,
    BaselayerToggleComponent,
    MapPopupComponent,
    MapLayersComponent,
    LegendLayerIconComponent,
    SidebarToggleComponent,
    AddressSearchComponent,
    MapFullComponent,
    ImportSurveyLayersComponent,
    ImportWmsLayersComponent,
    ImportWfsLayersComponent,
    ImportUploadLayersComponent,
    VectorLayerStyleComponent,
    RuleEditorComponent,
    MapAttributionComponent,
    MapEditorComponent,
    MapEditLayersComponent,
    MapListComponent,
    MapZoomIndicatorComponent,
    MapActionsComponent,
  ],
  entryComponents: [
    ImportSurveyLayersComponent,
    ImportWmsLayersComponent,
    ImportWfsLayersComponent,
    ImportUploadLayersComponent,
    VectorLayerStyleComponent,
    RuleEditorComponent,
  ],
})
export class MapsModule {
  static forRoot(environment) {
    return {
      ngModule: MapsModule,
      providers: [{ provide: 'environment', useValue: environment }],
    };
  }
}
