import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'map' })
export class MapPipe implements PipeTransform {
  public transform(value: any, fn: (value: any, ...args: any[]) => any, ...args: any[]): any {
    if (!fn) {
      return value;
    }

    return fn(value, ...args);
  }
}