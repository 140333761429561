import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ILayerDataset } from '@maplix/cloud';
import { ISurvey } from '@maplix/utils';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'maplix-dataset-card',
  templateUrl: './dataset-card.component.html',
  styleUrls: ['./dataset-card.component.scss'],
})
export class DatasetCardComponent implements OnInit {
  @ViewChild('deleteSwal', { static: false })
  private deleteSwal: SwalComponent;

  @Input()
  public readonly dataset: ILayerDataset;

  @Input()
  public readonly survey: ISurvey;

  @Input()
  public readonly isSelected: boolean;

  @Output()
  private onSelect: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onDelete: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onInfo: EventEmitter<void> = new EventEmitter();

  public icon: string;

  public typeLabel: string;

  constructor() {}

  ngOnInit() {
    if (!this.dataset && !this.survey) {
      return;
    }

    this.icon = this.survey
      ? 'remixicon-file-list-2-fill'
      : this.dataset.type === 'WMS'
      ? 'remixicon-server-fill'
      : this.dataset.type === 'UPLOAD' && !this.dataset.geomType
      ? 'remixicon-folder-upload-fill'
      : this.dataset.geomType === 'Point'
      ? 'fas fa-map-marker-alt'
      : this.dataset.geomType === 'LineString'
      ? 'fas fa-slash'
      : this.dataset.geomType === 'Polygon'
      ? 'fas fa-vector-square'
      : 'remixicon-map-2-line';

    this.typeLabel = this.survey ? 'Survey' : this.dataset.type ? this.dataset.type : 'Dataset';
  }

  public getDefaultTitle(survey: ISurvey) {
    if (!survey) {
      return;
    }

    const defaultLang = survey.general.defaultLanguage.code;

    return survey.general.title.find((title) => title.i18n === defaultLang).value ?? 'Untitled survey';
  }

  public onClickCard() {
    this.onSelect.emit();
  }

  public async onDeleteDataset() {
    const result = await this.deleteSwal.fire();

    if (result && result.value) {
      this.onDelete.emit();
    }
  }

  public onOpenDetails() {
    this.onInfo.emit();
  }
}
