<ng-container *ngIf="(onInfo | hasObservers) && dataset?.status !== 'PENDING'; else noClickTmpl">
  <ng-container *ngIf="onInfo | hasObservers">
    <maplix-grid-card
      [image]="survey ? survey.general.thumbnail : null"
      [icon]="icon"
      [type]="typeLabel"
      [name]="dataset ? dataset.name : (survey | map: getDefaultTitle)"
      [nameClass]="
        survey && (survey | map: getDefaultTitle) === 'Untitled survey' ? 'text-muted font-weight-normal' : ''
      "
      [isSelected]="isSelected"
      (action)="onClickCard()"
      (onInfo)="onOpenDetails()"
    ></maplix-grid-card>
  </ng-container>
</ng-container>

<ng-template #noClickTmpl>
  <maplix-grid-card
    [image]="survey ? survey.general.thumbnail : null"
    [icon]="icon"
    [type]="typeLabel"
    [name]="dataset ? dataset.name : (survey | map: getDefaultTitle)"
    [nameClass]="survey && (survey | map: getDefaultTitle) === 'Untitled survey' ? 'text-muted font-weight-normal' : ''"
    [isSelected]="isSelected"
    [badgeType]="dataset?.status === 'PENDING' ? 'light-secondary' : null"
    [badgeIcon]="dataset?.status === 'PENDING' ? 'fas fa-circle-notch fa-spin' : null"
    [badgeText]="dataset?.status === 'PENDING' ? 'Processing' : null"
    [disabled]="dataset?.status === 'PENDING'"
    (action)="onClickCard()"
  ></maplix-grid-card>
</ng-template>

<swal
  #deleteSwal
  [title]="'Delete dataset'"
  class="maplix-confirm-swall"
  [text]="'Are you sure you want to delete this dataset? This action cannot be undone.'"
  icon="warning"
  [showCancelButton]="true"
  [focusCancel]="false"
  [showCloseButton]="false"
  [customClass]="{
    confirmButton: 'btn mx-1 btn-danger',
    cancelButton: 'btn btn-light mx-1'
  }"
  [confirmButtonText]="'Delete'"
  [cancelButtonText]="'Cancel'"
  [buttonsStyling]="false"
>
</swal>
