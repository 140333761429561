import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@maplix/ui';
import { OverviewModalWrapperComponent } from './components/overview-modal-wrapper/overview-modal-wrapper.component';
import { DatasetOverviewComponent } from './components/dataset-overview/dataset-overview.component';
import { ImagesOverviewComponent } from './components/images-overview/images-overview.component';
import { PipesModule } from '@maplix/pipes';
import { DatasetCardComponent } from './components/dataset-card/dataset-card.component';
import { CreateWmsComponent } from './components/create-wms/create-wms.component';
import { CreateUploadLayerComponent } from './components/create-upload-layer/create-upload-layer.component';
import { CreateModalWrapperComponent } from './components/create-modal-wrapper/create-modal-wrapper.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DirectivesModule } from '@maplix/directives';
import { DatasetDetailComponent } from './components/dataset-detail/dataset-detail.component';
import { IconUploadComponent } from './components/icon-upload/icon-upload.component';

@NgModule({
  declarations: [
    OverviewModalWrapperComponent,
    DatasetOverviewComponent,
    ImagesOverviewComponent,
    DatasetCardComponent,
    CreateWmsComponent,
    CreateUploadLayerComponent,
    CreateModalWrapperComponent,
    DatasetDetailComponent,
    IconUploadComponent,
  ],
  imports: [CommonModule, UiModule, PipesModule, SweetAlert2Module, DirectivesModule],
  exports: [
    OverviewModalWrapperComponent,
    DatasetOverviewComponent,
    ImagesOverviewComponent,
    CreateModalWrapperComponent,
    DatasetCardComponent,
    IconUploadComponent,
  ],
  entryComponents: [OverviewModalWrapperComponent, CreateModalWrapperComponent],
})
export class CloudModule {}
