import { Coordinate } from 'ol/coordinate';

export const toDMS = ([lng, lat]: Coordinate): [string, string] => {
  const lngDeg = Math.floor(Math.abs(lng));
  const lngMin = Math.floor((Math.abs(lng) - lngDeg) * 60);
  const lngMinRest = (Math.abs(lng) - lngDeg) * 60 - lngMin;
  const lngSec = Math.round(lngMinRest * 60);

  const latDeg = Math.floor(Math.abs(lat));
  const latMin = Math.floor((Math.abs(lat) - latDeg) * 60);
  const latMinRest = (Math.abs(lat) - latDeg) * 60 - latMin;
  const latSec = Math.round(latMinRest * 60);

  return [
    `${latDeg}°${latMin}'${latSec}${lat > 0 ? ' N' : ' S'}`,
    `${lngDeg}°${lngMin}'${lngSec}${lng > 0 ? ' E' : ' W'}`,
  ];
};

export const formatLength = (length: number, order: number = 100): string => {
  if (length >= 1000) {
    return Math.round((length / 1000) * order) / order + 'km';
  }

  return Math.round(length * order) / order + 'm';
};

export const formatArea = (area: number): string => {
  if (area >= 10000) {
    return Math.round((area / 1000000) * 100) / 100 + 'km²';
  }

  return Math.round(area * 100) / 100 + 'm²';
};
