import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';

@NgModule({
  imports: [CommonModule],
})
export class ApiModule {
  static forRoot(environment) {
    return {
      ngModule: ApiModule,
      providers: [ApiService, {provide: 'environment', useValue: environment}]
    };
  }
}
