<a
  href="https://www.maplix.com"
  target="_blank"
  class="maplix-logo align-items-center small text-muted"
  [class.d-flex]="!hideOnSmartphone"
  [class.d-none]="hideOnSmartphone"
  [class.d-md-flex]="hideOnSmartphone"
>
  Powered by&nbsp;
  <a href="https://www.maplix.com/" target="_blank"><img src="assets/images/maplix_logo_primary.svg" /></a>
</a>
