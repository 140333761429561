<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body perfect-scroll">
  <ng-container [ngSwitch]="dataType">
    <ng-container *ngSwitchCase="'dataset'">
      <maplix-dataset-overview
        [apiUrl]="apiUrl"
        [skipSurvey]="skipSurvey"
        [filter]="filter"
        [showFilter]="showFilter"
        [selectedDataset]="selectedElement"
        [selectedSurvey]="selectedElement"
        (onSelect)="onSelectElement($event)"
        (onCreate)="onCreateNewDataset($event)"
      ></maplix-dataset-overview>
    </ng-container>
    <ng-container *ngSwitchCase="'image'">
      <maplix-images-overview
        [apiUrl]="apiUrl"
        [selectedImage]="selectedElement"
        (onSelect)="onSelectElement($event)"
        (onUpload)="onUploadImage($event)"
      ></maplix-images-overview>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer" *ngIf="showLightButton || showAccentButton || showPrimaryButton">
  <div class="d-flex align-items-center justify-content-end">
    <maplix-icon-text-button
      [buttonType]="'light'"
      [buttonText]="lightButtonText"
      [icon]="lightButtonIcon"
      (action)="lightButtonClick()"
      class="mr-1"
      *ngIf="showLightButton"
    ></maplix-icon-text-button>
    <maplix-icon-text-button
      [buttonType]="'danger'"
      [buttonText]="dangerButtonText"
      [icon]="dangerButtonIcon"
      (action)="dangerButtonClick()"
      class="mr-1"
      *ngIf="showAccentButton"
    ></maplix-icon-text-button>
    <maplix-icon-text-button
      [buttonType]="'primary'"
      [buttonText]="primaryButtonText"
      [icon]="primaryButtonIcon"
      (action)="primaryButtonClick()"
      *ngIf="showPrimaryButton"
      [disabled]="primaryButtonDisabled"
    >
    </maplix-icon-text-button>
  </div>
</div>
