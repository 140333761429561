import { Component, OnInit } from '@angular/core';
import { DataType } from '../../interfaces';
import { ObjectId } from '@maplix/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'maplix-overview-modal-wrapper',
  templateUrl: './overview-modal-wrapper.component.html',
  styleUrls: ['./overview-modal-wrapper.component.scss'],
})
export class OverviewModalWrapperComponent implements OnInit {
  public modalTitle: string = 'Select a dataset';

  public dataType: DataType = 'dataset';

  public lightButtonText: string = 'Cancel';

  public accentButtonText: string = 'Delete';

  public primaryButtonText: string = 'Select';

  public lightButtonIcon: string = 'remixicon-indeterminate-circle-fill';

  public accentButtonIcon: string = 'remixicon-delete-bin-6-line';

  public primaryButtonIcon: string = 'remixicon-check-line';

  public showLightButton: boolean = false;

  public showAccentButton: boolean = false;

  public showPrimaryButton: boolean = true;

  public showFilter: boolean = true;

  public selectedElement: any;

  public apiUrl: string;

  public primaryButtonDisabled: boolean = true;

  public skipSurvey: ObjectId;

  public filter: string;

  constructor(public readonly modal: NgbActiveModal) {}

  ngOnInit() {}

  public lightButtonClick() {
    this.modal.dismiss();
  }

  public accentButtonClick() {
    this.modal.close();
  }

  public async primaryButtonClick() {
    this.modal.close(this.selectedElement);
  }

  public onSelectElement(element: any) {
    this.selectedElement = element;
    this.primaryButtonDisabled = !this.selectedElement;

    if (!this.showPrimaryButton && element) {
      this.primaryButtonClick();
    }
  }

  public onCreateNewDataset(type: string) {
    this.modal.close({ type, isNewDataset: true });
  }

  public onUploadImage() {
    this.modal.close('upload');
  }

  public onSetFormValidity(valid: boolean) {
    this.primaryButtonDisabled = !valid;
  }
}
