import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { removeEmptyProperties } from '../remove-empty-properties';

//removes empty properties from the request body to save data transfer and data storage

@Injectable()
export class CleanObjectInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if ((req.method === 'POST' || req.method === 'PUT') && req.body && !req.headers.has('No-Clean')) {
      removeEmptyProperties(req.body);
    }

    if (req.headers.has('No-Clean')) {
      req = req.clone({ headers: req.headers.delete('No-Clean') });
    }

    return next.handle(req);
  }
}
