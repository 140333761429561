import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkspaceService } from '@maplix/services';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';

interface IDataGroup {
  minDate: moment.Moment;
  maxDate: moment.Moment;
  label: string;
}

@Component({
  selector: 'maplix-images-overview',
  templateUrl: './images-overview.component.html',
  styleUrls: ['./images-overview.component.scss'],
})
export class ImagesOverviewComponent implements OnInit {
  @Input()
  private readonly apiUrl: string;

  @Input()
  public selectedImage: string;

  @Output()
  private readonly onSelect: EventEmitter<string> = new EventEmitter();

  @Output()
  private readonly onUpload: EventEmitter<void> = new EventEmitter();

  public page: number = 1;

  public pageSize: number = 50;

  public totalItems: number = 0;

  public uploads: { fileName: string; url: string }[];

  public loading: boolean;

  private destroyed: Subject<void> = new Subject();

  public dataGroups: IDataGroup[] = [
    {
      minDate: moment().startOf('day'),
      maxDate: moment().endOf('day'),
      label: 'Today',
    },
    {
      minDate: moment().subtract(6, 'days').startOf('day'),
      maxDate: moment().subtract(1, 'days').endOf('day'),
      label: 'Earlier this week',
    },
    {
      minDate: moment().subtract(1, 'month').add(1, 'day').startOf('day'),
      maxDate: moment().subtract(7, 'days').endOf('day'),
      label: 'Last month',
    },
    {
      minDate: moment('1970-01-01').startOf('day'),
      maxDate: moment().subtract(1, 'month').endOf('day'),
      label: 'Older',
    },
  ];

  constructor(private http: HttpClient, private workspace: WorkspaceService) {}

  ngOnInit() {
    this.getUploads();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private getUploads() {
    this.loading = true;
    this.http
      .get<any>(
        `${this.apiUrl}uploads/list?where={"$and":[{"metadata.content_type":{"$regex":"^(?i)image"}}, {"$or": [{"thumbnail": false}, {"thumbnail": {"$exists": false}}]}]}&max_results=${this.pageSize}&page=${this.page}&sort=[("_created", -1)]`
      )
      .pipe(
        tap((response) => {
          this.uploads = response._items.map((upload) => {
            return {
              fileName: upload.metadata.original_filename,
              url: `${this.apiUrl}${upload.metadata.file}`,
              _updated: upload._updated,
            };
          });
          this.totalItems = response._meta.total;
          this.loading = false;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public getNewPage($event: number) {
    this.page = $event ? $event : 1;
    this.getUploads();
  }

  public selectImage(url: string) {
    this.onSelect.emit(url);
  }

  public uploadImage() {
    this.onUpload.emit(null);
  }

  public getUploadsForGroup(uploads: any[], group: IDataGroup) {
    if (!uploads) {
      return;
    }

    return uploads.filter((upload) => moment(upload._updated).isBetween(group.minDate, group.maxDate, 'second', '[]'));
  }
}
