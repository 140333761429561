import { Directive, Inject } from '@angular/core';
import { IMapDataLayer, IMapResult, IMapWMS, ISurveyImage, ISurveyMap } from '@maplix/utils';
import { FormArray, FormGroup } from '@maplix/forms';
import { Subject } from 'rxjs';
import { MapLayerService } from '../../services';

@Directive()
export class ImportLayers {
  protected destroyed: Subject<void> = new Subject<void>();

  public mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage> = this.mapService.mapForm;

  public apiUrl: string = this.environment.api;

  public visibilityItems = [
    { label: 'Visible', value: true, icon: 'remixicon-eye-line' },
    { label: 'Hidden', value: false, icon: 'remixicon-eye-off-line' },
  ];

  public popupItems = [
    { label: 'Show popup', value: true, icon: 'remixicon-notification-badge-line' },
    { label: 'No popup', value: false, icon: 'remixicon-eye-off-line' },
  ];

  constructor(@Inject('environment') private environment: any, protected mapService: MapLayerService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public onRemoveDataLayer(
    dataLayer: FormGroup<IMapDataLayer>,
    mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage>
  ) {
    const index = (mapForm.getControl('dataLayers') as FormArray<IMapDataLayer>).controls.findIndex(
      (layer: FormGroup<IMapDataLayer>) => layer.getFormControl('id').value === dataLayer.getFormControl('id').value
    );

    (mapForm.getControl('dataLayers') as FormArray<IMapDataLayer>).removeAt(index);
  }

  public onSetMinZoom(service: FormGroup<IMapDataLayer>, zoom: number) {
    service.controls.minZoom.setValue(zoom);
    service.controls.minZoom.markAsDirty();
  }

  public onSetMaxZoom(service: FormGroup<IMapDataLayer>, zoom: number) {
    service.controls.maxZoom.setValue(zoom);
    service.controls.maxZoom.markAsDirty();
  }

  public onSetOpacity(service: FormGroup<IMapWMS>, opacity: number) {
    service.controls.opacity.setValue(opacity);
    service.controls.opacity.markAsDirty();
  }

  // public onApply(layer) {
  //   if (!this.applyFunction) {
  //     return;
  //   }

  //   this.applyFunction(layer);
  // }
}
