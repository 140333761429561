import { IStyle } from '../analytics-style';
import { I18n } from '../i18n';
import { ObjectId } from '../object-id';
import { IUserDetails } from './users';

export interface IActionPlan {
  _id?: ObjectId;
  reference: string;
  organisation: ObjectId;
  general?: IActionPlanGeneral;
  actions?: (IActionPlanItem | IActionPlanGroupItem)[];
  options?: IActionPlanOptions;
  _archived?: Date;
}

export interface IActionPlanGeneral {
  title: I18n[];
  description: I18n[];
  thumbnail?: string;
}

export interface IActionPlanOptions {
  targets: string[];
  responsibles: string[];
}

export interface IActionPlanItem {
  id: string;
  reference: string;
  type: string;
  status: string;
  group?: string;
  details: IActionPlanItemDetails;
  location: IActionPlanItemLocation;
  management: IActionPlanItemManagement;
  planning: IActionPlanItemPlanning;
  attachments: string[];
}

export interface IActionPlanGroupItem {
  id: string;
  reference: string;
  type: 'GROUP';
  details: IActionPlanGroupItemDetails;
}

export interface IActionPlanItemDetails {
  category: string;
  title: I18n[];
  description: I18n[];
  goal: IActionPlanItemGoal;
  target: string[];
}

export interface IActionPlanGroupItemDetails {
  title: I18n[];
  description: I18n[];
}

export interface IActionPlanItemGoal {
  id: ObjectId;
  pillars?: string[];
}

export interface IActionPlanItemLocation {
  type?: string; // Drawn feature(s) or feature(s) from other source
  geometry?: any; // Geojson feature(s) if drawn
  source?: string; // source if feature from other dataset
  features?: string[]; // string of id(s) if feature from other dataset
  style?: IStyle;
}

export interface IActionPlanItemManagement {
  budget: number;
  duration: IActionPlanItemDuration;
  responsibles: string[];
  partners: string[];
  impact: string;
  effort: string;
  priority: string;
  timing: string;
  _qw?: boolean;
}

export interface IActionPlanItemDuration {
  amount: number;
  granularity: string;
}

export interface IActionPlanItemPlanning {
  startDate: Date;
  endDate: Date;
}

export interface IActionComment {
  id: string;
  author: ObjectId; // References user id
  created: Date;
  geometry?: any; // Geojson object of point location (if necessary)
  advice?: 'POSITIVE' | 'NEGATIVE' | null;
  message: string;
}

export enum ActionCommentStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  REOPENED = 'REOPENED',
}

export interface IActionCommentThread {
  _id?: ObjectId;
  actionplan: ObjectId;
  action: string;
  author: ObjectId | IUserDetails;
  originalMessage: string;
  status: ActionCommentStatus;
  geometry?: any;
  counters: {
    comments: 0;
  };
  _created?: Date;
  _archived?: Date;
}

export interface IActionComment {
  _id?: ObjectId;
  thread: ObjectId;
  actionplan: ObjectId;
  action: string;
  author: ObjectId;
  message: string;
  _created?: Date;
  _archived?: Date;
}
