import { MaplixApp } from './collections';

export interface IStripeProduct {
  id: string;
  active: boolean;
  name: string;
  defaultPrice: IStripePrice;
  prices: IStripePrice[];
  metadata: IStripeMetadata;
}

export interface IStripePrice {
  id: string;
  active: boolean;
  currency: string;
  unitAmount: number;
  saves?: number;
}

export interface IStripeMetadata {
  app: MaplixApp;
  credits: string;
}

export function stripeProductMapper(product: any) {
  return {
    ...product,
    defaultPrice: stripePriceMapper(product.default_price ? product.default_price : product.prices.filter(Boolean)[0]),
    prices: product.prices.filter(Boolean).map((price) => stripePriceMapper(price)),
  };
}

function stripePriceMapper(price: any) {
  return {
    ...price,
    unitAmount: price.unit_amount / 100,
  };
}
