import { FormArray, FormGroup } from '@maplix/forms';
import {
  IMapDataLayer,
  IMapSurveyLayer,
  IMapSurveyVector,
  IMapWMSLayer,
} from '@maplix/utils';
import { Map } from 'ol';

export function onToggleLayerVisibility({
  layer,
  map,
}: {
  layer: FormGroup<IMapDataLayer>;
  map: Map;
}): Promise<boolean> {
  return new Promise((resolve) => {
    const visible = layer.get('selectedInMap').value;

    if (hasSubLayers(layer.getRawValue()) && !isCombined(layer.getRawValue())) {
      const olLayer = map
        .getLayers()
        .getArray()
        .find((x) => x.get('maplixId') === layer.get('id').value);

      if (olLayer) {
        olLayer.setVisible(visible);
      }

      if (layer.get('styleConditions')) {
        layer.get('styleConditions')['controls'].forEach((condition) => {
          condition
            .get('selectedInMap')
            .setValue(visible, { emitEvent: false });
        });
      }

      (layer.get(
        'layers'
      ) as FormArray<IMapSurveyLayer | IMapWMSLayer>).controls
        .filter(
          (l: FormGroup<IMapSurveyLayer | IMapWMSLayer>) =>
            l.get('selectedInModal').value
        )
        .forEach((l: FormGroup<IMapSurveyLayer | IMapWMSLayer>) => {
          l.get('selectedInMap').setValue(visible, { emitEvent: false });

          if (l.get('styleConditions')) {
            l.get('styleConditions')['controls'].forEach((condition) => {
              condition
                .get('selectedInMap')
                .setValue(visible, { emitEvent: false });

              const olLayer = map
                .getLayers()
                .getArray()
                .find((x) => x.get('maplixId') === condition.get('id').value);

              if (olLayer) {
                olLayer.setVisible(visible);
              }
            });
          }

          const olLayer = map
            .getLayers()
            .getArray()
            .find((x) => x.get('maplixId') === l.get('id').value);

          if (olLayer) {
            olLayer.setVisible(visible);
          }
        });
    } else {
      if (layer.get('styleConditions')) {
        layer.get('styleConditions')['controls'].forEach((condition) => {
          condition
            .get('selectedInMap')
            .setValue(visible, { emitEvent: false });

          const olLayer = map
            .getLayers()
            .getArray()
            .find((x) => x.get('maplixId') === condition.get('id').value);

          if (olLayer) {
            olLayer.setVisible(visible);
          }
        });
      }

      const olLayer = map
        .getLayers()
        .getArray()
        .find((x) => x.get('maplixId') === layer.get('id').value);

      if (olLayer) {
        olLayer.setVisible(visible);
      }
    }
    resolve(true);
  });
}

function hasSubLayers(layerData: IMapDataLayer): boolean {
  return 'layers' in layerData;
}

function isCombined(layerData: IMapDataLayer): boolean {
  return (layerData as IMapSurveyVector).combineSubLayers;
}
