import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CacheInterceptor, CleanObjectInterceptor } from '@maplix/utils';
import { StravaPluginModule } from '@maplix/strava-plugin';
import { ApiModule } from '@maplix/api';
import { ServicesModule } from '@maplix/services';
import { DirectivesModule } from '@maplix/directives';
import { UiModule } from '@maplix/ui';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'nl',
      useDefaultLang: true,
    }),
    BsDatepickerModule.forRoot(),
    UiModule,
    DirectivesModule.forRoot(environment),
    ServicesModule.forRoot(environment),
    ApiModule.forRoot(environment),
    StravaPluginModule.forRoot(environment),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CleanObjectInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
