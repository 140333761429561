export const THEME_COLORS = {
  GENERIC: {
    PRIMARY: '#02C798',
    SECONDARY: '#FFD52B',
    INFO: '#00A7CC',
    DANGER: '#EB633D',
    PURPLE: '#675AA9',
    DARK: '#1A485B',
    ORANGE: '#fd7e14',
    FONT: '#243039',
    GREY: '#adb5bd',
  },
  STRAVA: {
    PRIMARY: '#FC4C02',
  },
};
