<div class="card-body py-2 overflow-auto" *ngIf="dataLayers">
  <div class="datalayer-container">
    <div
      *ngFor="let dataLayer of dataLayers['controls']; index as index"
      [formGroup]="dataLayer"
      class="datalayer-element"
      [class.hidden-from-legend]="!dataLayer.get('showOnLegend').value"
      [class.d-none]="!dataLayer.get('showOnLegend').value"
    >
      <div class="d-flex mb-1">
        <div
          class="collapse-icon clickable"
          (click)="onToggleCollapse(dataLayer.get('id').value)"
          *ngIf="dataLayer.get('type').value === 'WMS' || (dataLayer.getRawValue() | map: hasVisibleChildren)"
        >
          <i
            [class]="
              collapsedLayers?.includes(dataLayer.get('id').value)
                ? 'remixicon-arrow-right-s-fill'
                : 'remixicon-arrow-down-s-fill'
            "
          ></i>
        </div>
        <div class="custom-control custom-checkbox d-flex align-items-start justify-content-between w-100">
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="dataLayer.get('id').value"
            [value]="dataLayer.get('id').value"
            formControlName="selectedInMap"
            (change)="onChangeVisibility(dataLayer)"
          />
          <label class="custom-control-label" [for]="dataLayer.get('id').value">
            <ng-container
              *ngFor="
                let label of dataLayer.get('title')['controls'] | formGroupFilter: { field: 'i18n', filter: language }
              "
            >
              <div class="d-flex align-items-start">
                <ng-container
                  *ngIf="dataLayer.get('type').value !== 'WMS' && !(dataLayer.getRawValue() | map: hasVisibleChildren)"
                >
                  <maplix-legend-layer-icon
                    [geomType]="dataLayer.get('geomType')?.value"
                    [lineType]="dataLayer.get('lineType')?.value"
                    [style]="dataLayer.getRawValue() | map: getStyleForLegend"
                    [styleSource]="dataLayer.getRawValue() | map: getStyleSourceForLegend"
                  ></maplix-legend-layer-icon>
                </ng-container>
                {{ label.get('value').value }}
              </div>
            </ng-container>
          </label>
          <ng-container
            *ngFor="let h of dataLayer.get('helptext')?.value | filter: { field: 'i18n', filter: language }"
          >
            <ng-template #layerPopContent>
              <div class="ql-text-element">
                <maplix-quill-view [content]="h.value" [sanitize]="true"></maplix-quill-view>
              </div>
            </ng-template>
            <i
              class="remixicon-information-line clickable ml-2 font-weight-normal"
              [ngbPopover]="layerPopContent"
              container="body"
              *ngIf="h.value"
            ></i>
          </ng-container>
        </div>
      </div>

      <ng-container
        *ngIf="dataLayer.get('type').value === 'WMS' && !collapsedLayers?.includes(dataLayer.get('id').value)"
      >
        <ng-container
          *ngIf="
            dataLayer.get('layers') &&
            (dataLayer.get('layers')['controls']
              | formGroupFilter: { field: 'selectedInModal', filter: true }) as layers
          "
        >
          <ng-container *ngIf="layers.length === 1">
            <ng-container *ngFor="let layer of layers">
              <ng-container *ngIf="getWmsLegend(layer.get('id').value) as legendData">
                <div *ngFor="let legend of legendData?.legends" class="mt-1 ml-3">
                  <img [src]="legend.url" alt="legend" class="legend-img mx-2 mb-1" />
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Loop through sublayers -->
      <ng-container *ngIf="!dataLayer.get('combineSubLayers') || !dataLayer.get('combineSubLayers').value">
        <ng-container *ngIf="!collapsedLayers?.includes(dataLayer.get('id').value)">
          <ng-container
            *ngIf="
              dataLayer.get('layers') &&
              (dataLayer.get('layers')['controls']
                | formGroupFilter: { field: 'selectedInModal', filter: true }) as layers
            "
          >
            <div *ngFor="let layer of layers" [formGroup]="layer" class="layer-element">
              <!-- Checkbox for the styleCondition -->
              <div class="d-flex ml-3 mb-1" *ngIf="layers?.length > 1">
                <div
                  class="collapse-icon clickable"
                  (click)="onToggleCollapse(layer.get('id').value)"
                  *ngIf="dataLayer.get('type').value === 'WMS' || (layer.getRawValue() | map: hasVisibleChildren)"
                >
                  <i
                    [class]="
                      collapsedLayers?.includes(layer.get('id').value)
                        ? 'remixicon-arrow-right-s-fill'
                        : 'remixicon-arrow-down-s-fill'
                    "
                  ></i>
                </div>
                <div class="custom-control custom-checkbox d-flex align-items-start justify-content-between w-100">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [id]="layer.get('id').value"
                    [value]="layer.get('id').value"
                    formControlName="selectedInMap"
                    (change)="onChangeVisibility(layer)"
                  />
                  <label class="custom-control-label" [for]="layer.get('id').value">
                    <ng-container *ngIf="dataLayer.get('type').value !== 'WMS'">
                      <ng-container
                        *ngFor="
                          let label of layer.get('title')['controls']
                            | formGroupFilter: { field: 'i18n', filter: language }
                        "
                      >
                        <div class="d-flex align-items-start">
                          <ng-container *ngIf="!(layer.getRawValue() | map: hasVisibleChildren)">
                            <maplix-legend-layer-icon
                              [geomType]="layer.get('geomType')?.value"
                              [lineType]="layer.get('lineType')?.value"
                              [style]="layer.get('styleConditions')?.getRawValue()[0].style"
                              [styleSource]="layer.get('styleSource')?.value"
                            ></maplix-legend-layer-icon>
                          </ng-container>
                          {{ label.get('value').value }}
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="dataLayer.get('type').value === 'WMS'">
                      <div class="mb-1">{{ layer.get('title').value }}</div>
                      <ng-container *ngIf="!collapsedLayers?.includes(layer.get('id').value)">
                        <ng-container *ngIf="getWmsLegend(layer.get('id').value) as legendData">
                          <ng-container *ngFor="let legend of legendData?.legends">
                            <img [src]="legend.url" alt="legend" class="legend-img mx-2 mb-2" />
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </label>
                  <ng-container
                    *ngFor="let h of layer.get('helptext')?.value | filter: { field: 'i18n', filter: language }"
                  >
                    <ng-template #sublayerPopContent>
                      <div class="ql-text-element">
                        <maplix-quill-view [content]="h.value" [sanitize]="true"></maplix-quill-view>
                      </div>
                    </ng-template>
                    <i
                      class="remixicon-information-line clickable ml-2 font-weight-normal"
                      [ngbPopover]="sublayerPopContent"
                      container="body"
                      *ngIf="h.value"
                    ></i>
                  </ng-container>
                </div>
              </div>

              <!-- Loop through styles (for rule based styling) -->
              <ng-container *ngIf="!collapsedLayers?.includes(layer.get('id').value)">
                <ng-container
                  *ngIf="layer.get('styleConditions') && layer.get('styleConditions')['controls'] as styleConditions"
                >
                  <div
                    *ngFor="let styleCondition of styleConditions"
                    [formGroup]="styleCondition"
                    class="sublayer-element"
                  >
                    <div [class.ml-3]="layers?.length > 1" *ngIf="styleConditions?.length > 1">
                      <div class="custom-control custom-checkbox ml-3 mb-1">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          [id]="styleCondition.get('id').value"
                          [value]="styleCondition.get('id').value"
                          formControlName="selectedInMap"
                          (change)="onChangeVisibility(styleCondition)"
                        />
                        <label class="custom-control-label" [for]="styleCondition.get('id').value">
                          <ng-container
                            *ngFor="
                              let label of styleCondition.get('title')['controls']
                                | formGroupFilter: { field: 'i18n', filter: language }
                            "
                          >
                            <div class="d-flex align-items-center">
                              <maplix-legend-layer-icon
                                [geomType]="layer.get('geomType')?.value"
                                [lineType]="layer.get('lineType')?.value"
                                [style]="styleCondition.getRawValue().style"
                                [styleSource]="layer.get('styleSource')?.value"
                              ></maplix-legend-layer-icon>
                              {{ label.get('value').value }}
                            </div>
                          </ng-container>
                        </label>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Loop through styles (for rule based styling) -->
      <ng-container *ngIf="!collapsedLayers?.includes(dataLayer.get('id').value)">
        <ng-container
          *ngIf="dataLayer.get('styleConditions') && dataLayer.get('styleConditions')['controls'] as styleConditions"
        >
          <ng-container
            *ngFor="let styleCondition of styleConditions"
            [formGroup]="styleCondition"
            class="sublayer-element"
          >
            <div class="ml-3" *ngIf="styleConditions.length > 1">
              <div class="custom-control custom-checkbox mb-1">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [id]="styleCondition.get('id').value"
                  [value]="styleCondition.get('id').value"
                  formControlName="selectedInMap"
                  (change)="onChangeVisibility(styleCondition)"
                />
                <label class="custom-control-label" [for]="styleCondition.get('id').value">
                  <ng-container
                    *ngFor="
                      let label of styleCondition.get('title')['controls']
                        | formGroupFilter: { field: 'i18n', filter: language }
                    "
                  >
                    <div class="d-flex align-items-center">
                      <maplix-legend-layer-icon
                        [geomType]="dataLayer.get('geomType')?.value"
                        [lineType]="dataLayer.get('lineType')?.value"
                        [style]="styleCondition.getRawValue().style"
                        [styleSource]="dataLayer.get('styleSource')?.value"
                      ></maplix-legend-layer-icon>
                      {{ label.get('value').value }}
                    </div>
                  </ng-container>
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
