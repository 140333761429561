<!-- Search -->
<div
  class="d-flex align-items-stretch mb-3"
  *ngIf="searchable && (loading || totalItems > 0 || searchControl?.value || filters?.length)"
>
  <div class="w-100">
    <div id="tickets-table_filter" class="dataTables_filter text-md-right w-100">
      <form class="search">
        <i class="remixicon-search-line fa-fw text-muted"></i>
        <input
          type="text"
          class="form-control w-100"
          [placeholder]="searchPlaceholder"
          name="searchTerm"
          [formControl]="searchControl"
        />
        <i class="remixicon-close-fill fa-fw text-muted" (click)="onClearSearch()"></i>
        <!-- <button type="submit" class="btn btn-sm btn-primary">APPLY</button> -->
      </form>
    </div>
  </div>

  <ng-select
    [items]="filters"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [searchable]="false"
    [clearable]="false"
    [formControl]="filterControl"
    class="ml-1 filter-select"
    *ngIf="filters?.length"
  >
    <ng-template ng-label-tmp let-item="item"> <i class="mdi mdi-filter mr-2"></i> {{ item.label }} </ng-template>
  </ng-select>
</div>

<ng-content *ngIf="!loading"></ng-content>

<div class="row justify-content-md-between align-items-md-center" *ngIf="!loading && totalItems > 0">
  <div class="col-sm-12 col-md-5">
    <div
      class="text-muted mb-2 small"
      id="tickets-table_info"
      role="status"
      aria-live="polite"
      *ngIf="showIndicator && paginationType === 'page'"
    >
      {{ (page - 1) * pageSize + 1 }}-{{ page * pageSize < totalItems ? page * pageSize : totalItems }}
      {{ 'generic.list.OF' | translate }}
      {{ totalItems }}
    </div>
  </div>

  <div class="col-sm-12 col-md-5">
    <div class="text-md-right float-md-right" *ngIf="totalItems > pageSize && paginationType === 'page'">
      <ngb-pagination
        [collectionSize]="totalItems"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="4"
        size="sm"
        (pageChange)="onPageChange($event)"
      >
      </ngb-pagination>
    </div>
  </div>
</div>

<ng-container *ngIf="loading">
  <div class="d-flex justify-content-center py-4 position-relative">
    <div class="spinner-grow avatar-sm text-primary m-2" role="status"></div>
  </div>
</ng-container>

<div *ngIf="paginationType === 'scroll' && !loading && loadedItems > 0 && loadedItems >= pageSize">
  <button
    class="btn btn-light w-100 d-flex align-items-center justify-content-center flex-nowrap"
    (click)="onPageChange()"
    *ngIf="!totalItems"
  >
    <i class="fe-refresh-ccw mr-2"></i>{{ loadMoreItemsLabel }}
  </button>
  <div class="text-center text-muted" *ngIf="totalItems">{{ noMoreLoadLabel }}</div>
</div>
