import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@maplix/forms';
import { THEME_COLORS, randomIdGenerator } from '@maplix/utils';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';

@Component({
  selector: 'maplix-double-slider',
  templateUrl: './double-slider.component.html',
  styleUrls: ['./double-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoubleSliderComponent implements AfterViewInit {
  @Input()
  public min: number = 0;

  @Input()
  public max: number = 100;

  @Input()
  public step: number = 1;

  @Input()
  public minControl: FormControl<number>;

  @Input()
  public maxControl: FormControl<number>;

  public sliderId = 'slider-' + randomIdGenerator();

  constructor() {}

  ngAfterViewInit(): void {
    const slider = document.getElementById(this.sliderId);
    if (!slider) {
      return;
    }

    noUiSlider.create(slider, {
      start: [this.minControl.value, this.maxControl.value],
      connect: true,
      range: {
        min: this.min,
        max: this.max,
      },
      step: this.step,
      tooltips: true,
      format: this.step >= 1 ? wNumb({ decimals: 0 }) : null,
    });

    if ((slider as any).noUiSlider) {
      (slider as any).noUiSlider.on('update', (values) => {
        this.minControl.setValue(+values[0]);
        this.maxControl.setValue(+values[1]);
        this.minControl.markAsDirty();
        this.maxControl.markAsDirty();
      });
    }

    setTimeout(() => {
      // this.setBubbles();

      const handles = slider.querySelectorAll('.noUi-handle');
      if (handles && handles.length) {
        handles.forEach((handle: HTMLElement) => {
          handle.style.background = THEME_COLORS.GENERIC.PRIMARY;
        });
      }

      const connect: HTMLElement = slider.querySelector('.noUi-connect');
      if (connect) {
        connect.style.background = THEME_COLORS.GENERIC.PRIMARY;
      }
    });
  }

  private setBubbles() {
    const minBubble = document.querySelector(`#min-bubble-${this.sliderId}`) as HTMLElement;
    if (minBubble) {
      minBubble.innerHTML = `${this.minControl.value}`;
      const pct = ((+this.minControl.value - this.min) * 100) / (this.max - this.min);
      const cssString = `calc(${pct}% + (${10 - pct * 0.2}px))`;
      minBubble.style.left = cssString;
    }

    const maxBubble = document.querySelector(`#max-bubble-${this.sliderId}`) as HTMLElement;
    if (maxBubble) {
      maxBubble.innerHTML = `${this.maxControl.value}`;
      const pct = ((+this.maxControl.value - this.min) * 100) / (this.max - this.min);
      const cssString = `calc(${pct}% + (${10 - pct * 0.2}px))`;
      maxBubble.style.left = cssString;
    }
  }
}
