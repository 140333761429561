<div class="modal-body perfect-scroll">
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    <div class="d-flex align-items-center mw-200 flex-wrap" *ngIf="athlete$ | async as athlete">
      <img [src]="athlete.profile_medium" class="avatar-image-md mr-md-2" />
      <div>
        <div class="d-none d-md-block small text-muted">{{ 'map.strava.routeSelector.SIGNEDINAS' | translate }}</div>
        <div class="d-none d-md-block text-uppercase">{{ athlete.firstname }} {{ athlete.lastname }}</div>
      </div>
    </div>
  </div>
  <ng-container *ngLet="loadingAthlete$ | async as loadingAthlete">
    <div class="d-flex flex-column align-items-center" *ngIf="loadingAthlete">
      <div class="pt-4 text-center">
        {{ 'map.strava.routeSelector.LOADINGINFO' | translate }}
      </div>
      <div class="py-4 position-relative">
        <maplix-ui-preloader [display]="loadingAthlete"></maplix-ui-preloader>
      </div>
      <button class="btn btn-light" (click)="modal.dismiss()">{{ 'map.buttons.CANCEL' | translate }}</button>
    </div>

    <div class="py-4 d-flex flex-column align-items-center" *ngIf="(authError$ | async) && !loadingAthlete">
      <i class="fab fa-strava font-80 text-light"></i>
      <h4>{{ 'map.strava.error.TITLE' | translate }}</h4>
      <p class="text-muted text-center">
        {{ authError$ | async }}
      </p>
      <div class="d-flex align-items-center mt-3">
        <button class="btn btn-light mr-1" (click)="modal.dismiss()">
          {{ 'map.buttons.CANCEL' | translate }}
        </button>
        <button class="btn btn-primary" (click)="retryStravaLogin()">
          {{ 'map.strava.error.TRYAGAIN' | translate }}
        </button>
      </div>
    </div>
    <ng-container *ngLet="listItems$ | async as listItems">
      <div class="pt-3" *ngIf="!loadingAthlete && !(authError$ | async)">
        <!-- <div class="mb-2">
          <maplix-selector [items]="selectedFilterItems" [control]="selectedFilterControl"></maplix-selector>
        </div> -->

        <maplix-searchable-list
          [page]="page"
          [pageSize]="pageSize"
          [paginationType]="'scroll'"
          [searchable]="false"
          [loading]="loadingListItems"
          [loadedItems]="listItems?.length"
          [loadMoreItemsLabel]="'generic.list.LOADMOREITEMS' | translate"
          [totalItems]="
            (selectedFilterControl.value === 'activity' && maxActivities) ||
            (selectedFilterControl.value === 'route' && maxRoutes)
              ? listItems?.length
              : null
          "
          (pageChange)="loadMoreItems()"
        >
          <div class="row no-gutters" *ngIf="!loadingListItems && listItems?.length">
            <ng-container *ngFor="let item of listItems; index as i">
              <div
                class="col-12 col-md-6 col-xl-4 pb-2"
                [class.pr-xl-2]="i === 0 || (i > 0 && i % 3 === 0)"
                [class.pl-xl-0]="i === 0 || (i > 0 && i % 3 === 0)"
                [class.px-xl-2]="i - 1 === 0 || (i - 1 > 0 && (i - 1) % 3 === 0)"
                [class.pl-xl-2]="i - 2 === 0 || (i - 2 > 0 && (i - 2) % 3 === 0)"
                [class.pr-xl-0]="i - 2 === 0 || (i - 2 > 0 && (i - 2) % 3 === 0)"
                [class.pr-md-2]="i === 0 || (i > 0 && i % 2 === 0)"
                [class.pl-md-2]="i - 1 === 0 || (i - 1 > 0 && (i - 1) % 2 === 0)"
              >
                <ng-container *ngIf="selectedFilterControl?.value === 'activity'">
                  <maplix-grid-card
                    [icon]="item.sport_type | map: mapActivityIcon"
                    [name]="item.name"
                    [badgeIcon]="item.sport_type | map: mapActivityIcon"
                    [badgeText]="item.sport_type"
                    [badgeType]="'strava'"
                    [description]="item | map: formatStats:formatDistance:formatTempo:formatTime"
                    [resizeOnMobile]="true"
                    (action)="onSelectItem(item.id)"
                  >
                    <ng-template grid-card-header-tmp>
                      <maplix-summary-map [stravaMap]="item?.map"></maplix-summary-map>
                    </ng-template>
                  </maplix-grid-card>
                </ng-container>
                <ng-container *ngIf="selectedFilterControl?.value === 'route'">
                  <maplix-grid-card
                    [icon]="'remixicon-route-fill'"
                    [name]="item.name"
                    [badgeType]="'strava'"
                    [description]="item.distance | map: formatDistance + ' - ' + item.description"
                    [resizeOnMobile]="true"
                    (action)="onSelectItem(item.id)"
                  >
                    <ng-template grid-card-header-tmp>
                      <maplix-summary-map [stravaMap]="item?.map"></maplix-summary-map>
                    </ng-template>
                  </maplix-grid-card>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="py-4 d-flex flex-column align-items-center" *ngIf="!listItems?.length">
            <i
              [class]="
                selectedFilterControl.value === 'activity'
                  ? 'mdi mdi-run font-80 text-light'
                  : 'remixicon-route-fill font-80 text-light'
              "
            ></i>
            <h4>{{ notFoundTitle }}</h4>
            <p class="text-muted text-center">
              {{ notFoundInfo }}
            </p>
            <div class="d-flex align-items-center mt-3">
              <button class="btn btn-light mr-1" (click)="modal.dismiss()">
                {{ 'map.buttons.CANCEL' | translate }}
              </button>
              <button class="btn btn-primary" (click)="onDrawRoute()">
                {{ 'map.strava.drawSelector.DRAW' | translate }}
              </button>
            </div>
          </div>
        </maplix-searchable-list>
      </div>
    </ng-container>
  </ng-container>
</div>
