import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Input } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { MapSettingsService } from '@maplix/maps';
import { transform, transformExtent } from 'ol/proj';
import { Map } from 'ol';

@Component({
  selector: 'maplix-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent {
  public input: string = null;

  @Input()
  public map: Map;

  @Input()
  public placeholder: string = 'Search location';

  @Input()
  public id: string = 'searchbar';

  @Input()
  public readonly spaceRight: boolean;

  @Input()
  public position: string = 'left';

  @Output('onSelect')
  private selectEvent: EventEmitter<number[]> = new EventEmitter<number[]>();

  public searching: boolean;

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term: string) => {
        if (term.length < 2) {
          return of([]);
        }
        return this.searchAddress(term).pipe(
          map((x: any) => {
            return x.features;
          }),
          catchError(() => {
            return of([]);
          }),
          finalize(() => {
            this.searching = false;
          })
        );
      })
    );

  constructor(@Inject('environment') private environment: any, private http: HttpClient) {}

  formatter(args: any) {
    return `${args.place_name}`;
  }

  onSelect($event) {
    if (this.map) {
      if ($event.item.bbox) {
        const extent = transformExtent($event.item.bbox, 'EPSG:4326', 'EPSG:3857');
        this.map.getView().fit(extent, { padding: [50, 50, 50, 50], duration: 250 });
      } else {
        const center = transform($event.item.center, 'EPSG:4326', 'EPSG:3857');
        this.map.getView().animate({ center: center, zoom: 18, duration: 250 });
      }
    }
    this.selectEvent.emit($event.item.center);
  }

  private searchAddress(term: string): Observable<any> {
    if (term)
      return this.http.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${term}.json?access_token=${this.environment.mapboxToken}&limit=10`
      );
    else return Observable.create();
  }
}
