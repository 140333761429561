import { Component, Input } from '@angular/core';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-range',
  templateUrl: './popup-fieldtype-range.component.html',
  styleUrls: ['./popup-fieldtype-range.component.scss'],
})
export class PopupFieldtypeRangeComponent {
  @Input()
  public answer: ISurveyAnswer;
}
