<div class="modal-body" [formGroup]="wmsLayer">
  <ul ngbNav #layerTabs="ngbNav" class="nav-tabs nav-tabs-sm">
    <li ngbNavItem="source">
      <a ngbNavLink>Source</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="form-group">
            <label class="form-label">Dataset</label>
            <div class="card border-0 bg-light py-2 px-3">
              <div class="d-flex align-items-center">
                <i class="remixicon-server-line mr-2"></i>
                <p class="mb-0">{{ wmsLayer?.value?.name }}</p>
              </div>
            </div>
          </div>
          <div
            class="form-group"
            *ngIf="
              (wmsLayer.get('layers') && wmsLayer.get('layers')['controls'].length) ||
              mapService.wmsLoading ||
              mapService.wmsError
            "
          >
            <label class="form-label">Layers</label>
            <ng-container *ngIf="mapService.wmsError">
              <div class="small text-danger">No layers were found for this WMS.</div>
            </ng-container>
            <ng-container *ngIf="mapService.wmsLoading">
              <div class="spinner-grow avatar-sm text-primary m-2 d-block" role="status"></div>
            </ng-container>
            <ng-container *ngIf="!mapService.wmsError">
              <ul *ngIf="!mapService.wmsLoading">
                <ng-container *ngFor="let group of $any(wmsLayer.get('layers')).getRawValue() | map: getLayerGroups">
                  <li *ngIf="group">
                    <div>{{ group }}</div>
                    <ng-container
                      *ngFor="
                        let layer of wmsLayer.get('layers')['controls']
                          | formGroupFilter: { field: 'parent', filter: group }
                      "
                      [formGroup]="layer"
                    >
                      <div class="custom-control custom-checkbox ml-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          formControlName="selectedInModal"
                          [id]="layer.get('id').value + 'modal'"
                          name="selectedInModal"
                        />
                        <label [for]="layer.get('id').value + 'modal'" class="custom-control-label">{{
                          layer.get('title').value ? layer.get('title').value : layer.get('name').value
                        }}</label>
                      </div>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>

          <div class="element-container mt-4">
            <div class="d-flex align-items-center mb-3">
              <i class="remixicon-information-line mr-2 font-20"></i>
              <div><strong>Tip: </strong>Here are some issues you might face while adding a WMS.</div>
            </div>
            <ul>
              <li>
                If you're not able to select any layers above, you might have entered the wrong url, or the data is not
                publicly accessible.
              </li>
              <li>
                Data layers often have zoom level restrictions set by the WMS provider. This means that you might not
                see the data on every zoom level.
              </li>
              <li>
                Sometimes, the WMS provider does not allow other applications, like Explore, to access their service.
              </li>
              <li>
                A WMS can take some time to load, depending on the size of the data, the speed of the WMS provider or
                your network connectivity.
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="style">
      <a ngbNavLink>Style</a>
      <ng-template ngbNavContent>
        <div class="px-2">
          <div class="form-group">
            <label class="form-label">Layer opacity</label>
            <maplix-slider-number
              class="w-100"
              [min]="0"
              [max]="100"
              [suffix]="'%'"
              [control]="wmsLayer?.get('opacity')"
              [fillValue]="true"
            >
            </maplix-slider-number>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="legend">
      <a ngbNavLink>Legend</a>
      <ng-template ngbNavContent>
        <div class="px-2">
          <div class="form-group">
            <label class="form-label">Title</label>
            <ng-container
              *ngFor="
                let title of wmsLayer?.get('title')['controls']
                  | formGroupFilter: { field: 'i18n', filter: mapService.language }
              "
              [formGroup]="title"
            >
              <input type="text" class="form-control" formControlName="value" />
            </ng-container>
          </div>
          <div class="form-group">
            <label class="form-label">Help text</label>
            <ng-container
              *ngFor="
                let helptext of wmsLayer.get('helptext')['controls']
                  | formGroupFilter: { field: 'i18n', filter: mapService.language }
              "
              [formGroup]="helptext"
            >
              <maplix-quill-editor [control]="helptext.get('value')" [apiUrl]="apiUrl" [placeholder]="'Help text'">
              </maplix-quill-editor>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="layerTabs"></div>
</div>
