import { I18n } from '../i18n';
import { ObjectId } from '../object-id';

export const GOAL_CATEGORIES = [
  { value: 'CARS', label: 'organisation.goals.categories.CARS', icon: 'remixicon-car-line' },
  {
    value: 'VULNERABLE_ROAD_USERS',
    label: 'organisation.goals.categories.VULNERABLE_ROAD_USERS',
    icon: 'remixicon-riding-line',
  },
  { value: 'PUBLIC_TRANSPORT', label: 'organisation.goals.categories.PUBLIC_TRANSPORT', icon: 'remixicon-bus-line' },
  {
    value: 'SHARED_MOBILITY',
    label: 'organisation.goals.categories.SHARED_MOBILITY',
    icon: 'mdi mdi-account-switch',
  },
  { value: 'PARKING', label: 'organisation.goals.categories.PARKING', icon: 'remixicon-parking-box-line' },
  {
    value: 'LIVING_ENVIRONMENT',
    label: 'organisation.goals.categories.LIVING_ENVIRONMENT',
    icon: 'mdi mdi-nature-people',
  },
  { value: 'OTHER', label: 'organisation.goals.categories.OTHER', icon: 'mdi mdi-dots-horizontal' },
];

export interface IOrganisationGoal {
  _id?: ObjectId;
  organisation: ObjectId;
  status: GoalStatus;
  color?: string;
  category: string;
  title: I18n[];
  description: I18n[];
  deadline: Date;
  attachments?: IOrganisationGoalUpload[];
  pillars?: IOrganisationGoalPillar[];
  kpis?: IOrganisationGoalKPI[];
}

export enum GoalStatus {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
}

export interface IOrganisationGoalPillar {
  id: string;
  label: I18n[];
}

export interface IOrganisationGoalUpload {
  fileName: string;
  extension: string;
  src: string;
}

export interface IOrganisationGoalKPI {
  id: string;
  label: I18n[];
  target: number;
  operator: string;
  suffix?: string;
}
