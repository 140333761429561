<div class="img-container modal-header p-0 border-0">
  <img [src]="item?.thumbnail" [alt]="item?.title" />
  <div class="img-overlay"></div>
</div>
<div class="modal-body p-3">
  <h4>{{ item?.title }}</h4>
  <div class="mb-4">{{ item?.description }}</div>
  <ng-container *ngIf="modalContentTemplate; else longDescriptionTmpl">
    <div class="text-muted">
      <ng-container [ngTemplateOutlet]="modalContentTemplate"></ng-container>
    </div>
  </ng-container>
</div>
<div class="modal-footer d-flex align-items-center justify-content-end">
  <button id="cancel-btn" class="btn btn-light" (click)="onCancel()" *ngIf="showCancelButton">{{ cancelText }}</button>
  <button id="select-btn" class="btn btn-primary" (click)="onSelect()" *ngIf="showSelectButton">
    {{ selectText }}
  </button>
</div>

<ng-template #longDescriptionTmpl>
  <div class="text-muted" [innerHTML]="item?.longDescription"></div>
</ng-template>
