import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module--fix-imports-error';
import VideoResize from 'quill-video-resize-module2';
import * as Emoji from 'quill-emoji';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ImageUploader from 'quill-image-uploader';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IUserDetails, randomIdGenerator } from '@maplix/utils';
import { FormControl } from '@maplix/forms';
import { DestroyComponent } from '@maplix/ng-utils';

import 'quill-mention';

let Quill: any = QuillNamespace;
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/videoResize', VideoResize);
Quill.register('modules/emoji', Emoji);

export interface IQuillOptions {
  textFormat?: boolean;
  emoji?: boolean;
  list?: boolean;
  indent?: boolean;
  align?: boolean;
  link?: boolean;
  image?: boolean;
  video?: boolean;
  headings?: boolean;
}

export const FULL_QUILL_OPTIONS: IQuillOptions = {
  textFormat: true,
  emoji: true,
  list: true,
  indent: true,
  align: true,
  link: true,
  image: true,
  video: true,
  headings: true,
};

export const DEFAULT_QUILL_OPTIONS: IQuillOptions = {
  textFormat: true,
  emoji: true,
  list: true,
  indent: true,
  align: true,
  link: true,
  image: true,
  video: true,
};

export const LIMITED_QUILL_OPTIONS: IQuillOptions = {
  textFormat: true,
  emoji: true,
};

export const NO_MEDIA_QUILL_OPTIONS: IQuillOptions = {
  textFormat: true,
  emoji: true,
  list: true,
  indent: true,
  align: true,
  link: true,
};

const OPTIONS_MAPPER = {
  textFormat: ['bold', 'italic', 'underline', 'strike'],
  emoji: ['emoji'],
  list: [{ list: 'ordered' }, { list: 'bullet' }],
  indent: [{ indent: '-1' }, { indent: '+1' }],
  align: [{ align: [] }],
  link: ['link'],
  image: ['image'],
  video: ['video'],
};

@Component({
  selector: 'maplix-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaplixQuillEditorComponent extends DestroyComponent implements OnInit, AfterViewInit {
  @Input()
  public control: FormControl<string>;

  @Input()
  public inputId: string = `ql-${randomIdGenerator(30)}`;

  @Input()
  /**
   * Deprecated. Use options instead.
   */
  public limited: boolean;

  @Input()
  /**
   * Deprecated. Use options instead.
   */
  public withSize: boolean;

  @Input()
  public placeholder: string = '';

  @Input()
  public height: number = 38;

  @Input()
  public maxHeight: number = 250;

  @Input()
  private apiUrl: string;

  @Input()
  private fixedHeight: boolean = false;

  @Input()
  public collapsable: boolean = true;

  @Input()
  public collapsed: boolean = true;

  @Input()
  public showGeneratorButton: boolean = false;

  @Input()
  public options: IQuillOptions = DEFAULT_QUILL_OPTIONS;

  @Input()
  public mentions: boolean = false;

  @Input()
  public mentionItems$: Observable<IUserDetails[]>;

  @Output()
  private generate: EventEmitter<void> = new EventEmitter();

  public editorModules: any;

  @ViewChild('quillEditor', { static: false })
  quillEditor: QuillEditorComponent;

  public get showToggleButton(): boolean {
    return !!Object.keys(this.options)?.length;
  }

  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
    if (this.limited) {
      this.options = LIMITED_QUILL_OPTIONS;
    }

    const container = Object.keys(this.options)
      .filter((opt) => this.options[opt])
      .map((opt) => OPTIONS_MAPPER[opt]);

    if (this.withSize) {
      container.push([{ header: [1, 2, 3, 4, 5, 6, false] }]);
    }

    this.editorModules = {
      toolbar: {
        container,
      },
      imageResize: {
        modules: ['Resize', 'DisplaySize'],
      },
      imageUploader: {
        upload: (file: File) => {
          return new Promise((resolve, reject) => {
            this.upload(file)
              .pipe(takeUntil(this.destroyed))
              .subscribe(
                (event: any) => {
                  if (event instanceof HttpResponse)
                    resolve(`${this.apiUrl}${event.body && event.body.file && event.body.file.file}`);
                },
                () => {
                  reject();
                }
              );
          });
        },
      },
      videoResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
        tagName: 'iframe',
      },
      'emoji-toolbar': true,
    };

    if (this.mentions) {
      this.editorModules['mention'] = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        showDenotationChar: false,
        isolateCharacter: true,
        allowInlineMentionChar: true,
        positioningStrategy: 'fixed',
        selectKeys: [9, 13],
        mentionContainerClass: 'ql-mention-list-container mention-list-container perfect-scroll',
        dataAttributes: ['id', 'value', 'email'],
        source: this.getMembers.bind(this),
      };
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.quillEditor.editorElem.style.minHeight = this.height + 'px';
      this.quillEditor.editorElem.classList.add('perfect-scroll');
      this.quillEditor.editorElem.style.removeProperty('position');
      if (this.fixedHeight) {
        this.quillEditor.editorElem.style.maxHeight = this.height + 'px';
      } else if (this.height > this.maxHeight) {
        this.quillEditor.editorElem.style.maxHeight = this.height + 'px';
      } else {
        this.quillEditor.editorElem.style.maxHeight = this.maxHeight + 'px';
      }

      if (this.collapsable && this.collapsed) {
        // Hide toolbar
        const toolbar = document.querySelector(`#${this.inputId} .ql-toolbar`) as HTMLElement;

        if (toolbar) {
          toolbar.style.visibility = 'hidden';
          toolbar.style.height = '0';
          toolbar.style.padding = '0';
        }

        // Round bottom edges of editor
        this.quillEditor.editorElem.style.borderBottomLeftRadius = '4px';
        this.quillEditor.editorElem.style.borderBottomRightRadius = '4px';
      }
    });
  }

  private upload(file: File): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${this.apiUrl}uploads`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public onToggleCollapse() {
    if (this.control.disabled) {
      return;
    }

    this.collapsed = !this.collapsed;

    // Show or hide toolbar
    const toolbar = document.querySelector(`#${this.inputId} .ql-toolbar`) as HTMLElement;
    toolbar.style.visibility = this.collapsed ? 'hidden' : 'visible';
    toolbar.style.height = this.collapsed ? '0' : 'auto';
    toolbar.style.padding = this.collapsed ? '0' : '8px';

    // Round or square off bottom edges of editor
    this.quillEditor.editorElem.style.borderBottomLeftRadius = this.collapsed ? '4px' : '0';
    this.quillEditor.editorElem.style.borderBottomRightRadius = this.collapsed ? '4px' : '0';
  }

  public onGenerate() {
    this.generate.emit();
  }

  public async getMembers(searchTerm, renderList) {
    const users = await this.mentionItems$.toPromise();
    const matchedPeople = users
      .filter((user) => user.name.toLowerCase().includes(searchTerm?.toLowerCase()))
      .map((user) => {
        return {
          id: user._id,
          value: user.name,
          email: user.email,
        };
      });
    renderList(matchedPeople, searchTerm);
  }

  public focusInput() {
    this.quillEditor.editorElem.scrollIntoView();
  }
}
