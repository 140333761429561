import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DestroyComponent } from '@maplix/ng-utils';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { filter, tap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'maplix-strava-redirect',
  templateUrl: './strava-redirect.component.html',
  styleUrls: ['./strava-redirect.component.scss'],
})
export class StravaRedirectComponent extends DestroyComponent implements OnInit {
  constructor(@Inject('environment') private environment: any, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        tap((qParams) => {
          if (qParams['error']) {
            localStorage.setItem('strava-token', 'error-' + moment().format('DDMMyyyyHHmmss'));
            window.close();
          }
        }),
        filter((qParams) => qParams['code'] && qParams['scope']),
        tap((qParams) => {
          localStorage.setItem('strava-token', JSON.stringify({ token: qParams['code'], scope: qParams['scope'] }));
          window.close();
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }
}
