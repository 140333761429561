<div class="d-flex justify-content-between align-items-center pb-3 pt-2">
  <h4 class="mb-0 mt-0">Dataset details</h4>
  <i class="clickable remixicon-close-fill font-18" (click)="onCloseDetail()"></i>
</div>

<div class="form-group">
  <label class="form-label">Type</label>
  <div>{{ detailSelected.type === 'UPLOAD' ? 'Upload' : detailSelected.type }}</div>
</div>
<div class="form-group">
  <label class="form-label">Name</label>
  <div>{{ detailSelected.name }}</div>
</div>
<div class="form-group" *ngIf="detailSelected.type === 'WMS'">
  <label class="form-label">WMS url</label>
  <div class="input-group">
    <input type="text" class="form-control" disabled [ngModel]="detailSelected.url" />
    <div class="input-group-append">
      <button
        class="btn btn-light form-control"
        target="_blank"
        ngbTooltip="Copy URL"
        ngbDropdownItem
        placement="left"
        (click)="onCopyToClipboard(detailSelected.url)"
      >
        <i class="remixicon-clipboard-line"></i>
      </button>
    </div>
  </div>
</div>
<div class="form-group" *ngIf="detailSelected.geomType">
  <label class="form-label">Geometry type</label>
  <div>{{ detailSelected.geomType === 'LineString' ? 'Line' : detailSelected.geomType }}</div>
</div>
<div class="form-group" *ngIf="detailSelected.creator && $any(detailSelected.creator).name">
  <label class="form-label">Created by</label>
  <div>{{ $any(detailSelected.creator).name }}</div>
</div>
<div class="form-group">
  <label class="form-label">Created on</label>
  <div>{{ detailSelected._created | date: 'MMM dd, yyyy' }}</div>
</div>

<div class="d-flex justify-content-end pt-2">
  <maplix-confirm-button
    [buttonType]="'danger'"
    buttonSize="sm"
    (confirm)="onDeleteDataset()"
    [popoverTitle]="'Delete dataset?'"
    [popoverMessage]="'Are you sure you want to delete this dataset? This action cannot be undone.'"
    [confirmText]="'Delete'"
  >
    <div class="d-flex align-items-center">
      <i class="remixicon-delete-bin-6-line mr-2 font-16"></i>
      Delete
    </div>
  </maplix-confirm-button>
</div>
