import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@maplix/forms';
import { IMapResult, IMapWMS, IMapWMSLayer, ISurvey } from '@maplix/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { groupBy } from 'lodash';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ImportLayers } from '../import-layers/import-layers';
import { MapLayerService } from '@maplix/maps';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'maplix-import-wms-layers',
  templateUrl: './import-wms-layers.component.html',
  styleUrls: ['./import-wms-layers.component.scss'],
})
export class ImportWmsLayersComponent extends ImportLayers implements OnInit {
  @Input()
  public wmsLayer: FormGroup<IMapWMS>;

  @Input()
  public new: boolean;

  @Input()
  public fromCloud: boolean;

  public disabled: boolean;

  public readonly wmsLoading$: Observable<boolean> = of(this.mapService.wmsLoading);

  public readonly wmsError$: Observable<boolean> = of(this.mapService.wmsError);

  public readonly wmsHelptext: string =
    "Paste the root url of the Web Map Service here. The root url is everything that comes before the '?'.";

  public visibleHelptexts: string[] = [];

  constructor(@Inject('environment') environment: any, public mapService: MapLayerService) {
    super(environment, mapService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.new || this.fromCloud) {
      this.mapService.getWmsLayers(this.wmsLayer.getFormControl('url').value, this.wmsLayer);
    }

    this.wmsLayer
      .getFormControl('url')
      .valueChanges.pipe(debounceTime(300), filter<string>(Boolean), takeUntil(this.destroyed))
      .subscribe((url) => {
        this.mapService.getWmsLayers(url, this.wmsLayer);
      });
  }

  public onGroupLayers(
    layers: any[],
    service: FormGroup<IMapWMS>
  ): { groupTitle: string; layers: FormArray<IMapWMSLayer> }[] {
    const resultObj = groupBy(layers, (value) => {
      return value.parent;
    });

    const result = Object.keys(resultObj).map((key) => {
      return {
        groupTitle: key,
        layers: service.get('layers') as FormArray<IMapWMSLayer>,
      };
    });
    return result;
  }

  public getLayerGroups(layers: any[]) {
    return [...new Set(layers.map((layer) => layer.parent))];
  }

  public onToggleHelptext(layer: string) {
    if (this.visibleHelptexts.includes(layer)) {
      this.visibleHelptexts = this.visibleHelptexts.filter((id) => id !== layer);
    } else {
      this.visibleHelptexts.push(layer);
    }
  }
}
