import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BASELAYERS } from '../../utils';

@Component({
  selector: 'maplix-baselayer-toggle',
  templateUrl: './baselayer-toggle.component.html',
  styleUrls: ['./baselayer-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaselayerToggleComponent implements OnInit {
  @Input()
  public readonly loading: boolean;

  @Input()
  public readonly id: string = 'base-toggle';

  @Input()
  public readonly tooltipText: string = 'Change basemap';

  @Input()
  public readonly activeLayer: string = 'mapbox-basic';

  @Input()
  public readonly maxHeight: string;

  @Input()
  public hideOnSmartphone: boolean;

  @Output()
  private activeLayerChange: EventEmitter<string> = new EventEmitter();

  @Output()
  private collapseChange: EventEmitter<boolean> = new EventEmitter();

  public collapsed: boolean = true;

  @ViewChild('baselayerTooltip', { static: false })
  private toggleTooltip: NgbTooltip;

  public readonly baseLayers = BASELAYERS;

  constructor() {}

  ngOnInit() {}

  public toggleBaseLayer() {
    if (this.toggleTooltip) {
      this.toggleTooltip.close();
    }

    this.collapsed = !this.collapsed;
    this.collapseChange.emit(this.collapsed);
  }

  public onChangeBaseLayer(layer: string) {
    this.activeLayerChange.emit(layer);
    this.toggleBaseLayer();
  }
}
