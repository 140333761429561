import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { clone } from 'lodash';

@Component({
  selector: 'maplix-icon-anchor-picker',
  templateUrl: './icon-anchor-picker.component.html',
  styleUrls: ['./icon-anchor-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAnchorPickerComponent implements OnInit {
  @Input()
  private set anchor(value: number[]) {
    if (!value) {
      this.xValue = 0.5;
      this.yValue = 0.5;
      return;
    }
    this.xValue = this.resetXValue(value[0]);
    this.yValue = value[1];
  }

  @Input()
  public twoColumns: boolean = false;

  @Output()
  private anchorChange: EventEmitter<number[]> = new EventEmitter();

  public xValue: number = 0.5;

  public yValue: number = 0.5;

  constructor() {}

  ngOnInit() {}

  onSetAnchor() {
    let x = clone(this.xValue);
    let y = clone(this.yValue);

    x = this.resetXValue(x);

    this.anchorChange.emit([x, y]);
  }

  private resetXValue(x: number): number {
    if (x > 0.5) {
      x = 0.5 - (x - 0.5);
    } else if (x < 0.5) {
      x = 0.5 + (0.5 - x);
    }

    return x;
  }
}
