import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ILayerDataset } from '@maplix/cloud';
import { NotificationsService } from '@maplix/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'maplix-create-modal-wrapper',
  templateUrl: './create-modal-wrapper.component.html',
  styleUrls: ['./create-modal-wrapper.component.scss'],
})
export class CreateModalWrapperComponent implements OnInit {
  public lightButtonText: string = 'Cancel';

  public accentButtonText: string = 'Delete';

  public primaryButtonText: string = 'Add';

  public lightButtonIcon: string = 'remixicon-indeterminate-circle-fill';

  public accentButtonIcon: string = 'remixicon-delete-bin-6-line';

  public primaryButtonIcon: string = 'remixicon-checkbox-circle-fill';

  public showLightButton: boolean = false;

  public showAccentButton: boolean = false;

  public showPrimaryButton: boolean = true;

  public primaryButtonDisabled: boolean = true;

  private selectedElement: any;

  public apiUrl: string;

  public geoserverUrl: string;

  public newDataset: string;

  public saving: boolean;

  constructor(
    public readonly modal: NgbActiveModal,
    private http: HttpClient,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {}

  public lightButtonClick() {
    this.modal.dismiss();
  }

  public accentButtonClick() {
    this.modal.close();
  }

  public onSelectElement(element: any) {
    this.selectedElement = element;
  }

  public async primaryButtonClick() {
    this.saving = true;

    if (this.selectedElement.fullSurvey) {
      delete this.selectedElement.fullSurvey;
    }

    const { _id } = await this.http
      .post<ILayerDataset>(`${this.apiUrl}layers`, this.selectedElement)
      .toPromise();
    this.selectedElement._id = _id;

    this.saving = false;
    this.modal.close(this.selectedElement);

    this.notificationsService.startPollingNotifications(1000);
  }

  public onSetFormValidity(valid: boolean) {
    this.primaryButtonDisabled = !valid;
  }
}
