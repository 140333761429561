<div class="modal-body position-relative" [formGroup]="uploadLayer">
  <ul ngbNav #layerTabs="ngbNav" class="nav-tabs nav-tabs-sm">
    <li ngbNavItem="source">
      <a ngbNavLink>Source</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="form-group">
            <label class="form-label">Dataset</label>
            <div class="card border-0 bg-light py-2 px-3">
              <div class="d-flex align-items-center">
                <i [class]="icon"></i>
                <p class="mb-0">{{ uploadLayer?.value?.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="style" [disabled]="!uploadLayer.get('typeName')?.value">
      <a ngbNavLink>Style</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <ng-container [formGroup]="uploadLayer">
            <!-- Ask style source -->
            <div class="form-group" *ngIf="styleSources?.length > 1">
              <div class="font-13 text-muted text-uppercase mb-3">Layer style</div>
              <label class="form-label">Style type</label>
              <ng-select
                [items]="styleSources"
                formControlName="styleSource"
                bindValue="value"
                [clearable]="false"
                [searchable]="false"
              >
                <ng-template ng-label-tmp let-item="item">
                  <i [class]="item.icon"></i>
                  <span class="ml-2">{{ item.label }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <i [class]="item.icon"></i>
                  <span class="ml-2">{{ item.label }}</span>
                </ng-template>
              </ng-select>
            </div>

            <ng-container *ngFor="let styleCondition of uploadLayer?.get('styleConditions')['controls']">
              <maplix-vector-layer-style
                [dataLayer]="uploadLayer"
                [styleCondition]="styleCondition"
                [mapType]="mapType"
                [apiUrl]="apiUrl"
                [language]="mapService.language"
                [isUpload]="true"
              >
              </maplix-vector-layer-style>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="labels" [disabled]="!uploadLayer.get('typeName')?.value">
      <a ngbNavLink>Labels</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <ng-container *ngFor="let styleCondition of uploadLayer?.get('styleConditions')['controls']">
            <maplix-vector-layer-labels
              [geometryType]="uploadLayer.get('geomType')?.value"
              [labelControl]="styleCondition.get('label')"
              [uploadId]="uploadLayer.get('_id')?.value"
              [styleSource]="uploadLayer.get('styleSource')?.value"
              [themeColors]="themeColors"
              [style]="styleCondition.get('style')?.value"
            ></maplix-vector-layer-labels>
          </ng-container>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="legend" [disabled]="!uploadLayer.get('typeName')?.value">
      <a ngbNavLink>Legend</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="form-group">
            <label class="form-label">Title</label>
            <ng-container
              *ngFor="
                let title of uploadLayer?.get('title')['controls']
                  | formGroupFilter: { field: 'i18n', filter: mapService.language }
              "
              [formGroup]="title"
            >
              <input type="text" class="form-control" formControlName="value" />
            </ng-container>
          </div>
          <div class="form-group">
            <label class="form-label">Help text</label>
            <ng-container
              *ngFor="
                let helptext of uploadLayer.get('helptext')['controls']
                  | formGroupFilter: { field: 'i18n', filter: mapService.language }
              "
              [formGroup]="helptext"
            >
              <maplix-quill-editor [control]="helptext.get('value')" [apiUrl]="apiUrl" [placeholder]="'Help text'">
              </maplix-quill-editor>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="options" [disabled]="!uploadLayer.get('typeName')?.value">
      <a ngbNavLink>Options</a>
      <ng-template ngbNavContent>
        <div class="px-2 pb-3 perfect-scroll">
          <div class="form-group mb-4">
            <label class="form-label">
              Zoom extent
              <i
                class="remixicon-question-line text-muted ml-2 clickable"
                [ngbPopover]="'The layer will not be visible beyond these zoom levels.'"
                placement="bottom"
                container="body"
              ></i>
            </label>
            <div class="position-relative">
              <maplix-double-slider
                [min]="0"
                [max]="22"
                [minControl]="uploadLayer?.get('minZoom')"
                [maxControl]="uploadLayer?.get('maxZoom')"
                [themeColors]="themeColors"
              ></maplix-double-slider>
              <maplix-map-zoom-indicator [map]="mapService.map"></maplix-map-zoom-indicator>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Visibility in legend</label>
            <ng-select
              [items]="visibilityItems"
              bindLabel="label"
              bindValue="value"
              formControlName="showOnLegend"
              [clearable]="false"
              [searchable]="false"
              appendTo=".layer-editor"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="form-group">
            <label class="form-label">
              Element detail popup
              <i
                class="remixicon-question-line text-muted ml-2 clickable"
                [ngbPopover]="'Not applicable for layers with the \'Clustered\' style type.'"
                placement="bottom"
                container="body"
              ></i>
            </label>
            <ng-select
              [items]="popupItems"
              bindLabel="label"
              bindValue="value"
              formControlName="isSelectable"
              [clearable]="false"
              [searchable]="false"
              appendTo=".layer-editor"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <i [class]="item.icon + ' mr-2'"></i>
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="layerTabs"></div>
</div>
