import { Component, Input, OnInit } from '@angular/core';
import { IStyle, LineType, MapStyleSource } from '@maplix/utils';
import { Type } from 'ol/geom/Geometry';

@Component({
  selector: 'maplix-legend-layer-icon',
  templateUrl: './legend-layer-icon.component.html',
  styleUrls: ['./legend-layer-icon.component.scss'],
})
export class LegendLayerIconComponent implements OnInit {
  @Input()
  public geomType: Type;

  @Input()
  public lineType: LineType;

  @Input()
  public style: IStyle;

  @Input()
  public styleSource: MapStyleSource;

  constructor() {}

  ngOnInit() {}
}
