<button
  [class]="
    isDropdownItem
      ? 'maplix-dropdown-item'
      : buttonSizeClass + buttonTypeClass + (customButtonClass ? customButtonClass : '')
  "
  [disabled]="disabled"
  (click)="onFireSwal()"
>
  <ng-content></ng-content>
</button>

<swal
  #mySwal
  [title]="popoverTitle"
  class="maplix-confirm-swall"
  [text]="popoverMessage"
  [icon]="alertIcon"
  [showCancelButton]="!hideCancelButton"
  [focusCancel]="false"
  [showCloseButton]="false"
  [customClass]="{ confirmButton: 'btn mx-1 ' + confirmButtonTypeClass, cancelButton: 'btn btn-light mx-1' }"
  [confirmButtonText]="confirmText"
  [cancelButtonText]="cancelText"
  [buttonsStyling]="false"
>
</swal>
