import { Component, Input } from '@angular/core';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-budget',
  templateUrl: './popup-fieldtype-budget.component.html',
  styleUrls: ['./popup-fieldtype-budget.component.scss'],
})
export class PopupFieldtypeBudgetComponent {
  @Input()
  public answer: ISurveyAnswer;

  public getPercentage(value: number, scaleMax: number): string {
    const pct = (+value * 100) / +scaleMax;
    return `${pct}%`;
  }
}
