export enum MaplixApp {
  ACCOUNT = 'ACCOUNT',
  ENGAGE = 'ENGAGE',
  EXPLORE = 'EXPLORE',
}

export enum MaplixEntity {
  SURVEY = 'SURVEY',
  MAP = 'MAP',
}
