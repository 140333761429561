import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
})
export class RealmModule {
  static forRoot(environment: any) {
    return {
      ngModule: RealmModule,
      providers: [{ provide: 'environment', useValue: environment }],
    };
  }
}
