<!-- <div class="input-group flex-nowrap border-none">
  <div class="input-group-prepend w-100">
    <input
      type="range"
      class="custom-range form-control pr-2"
      [(ngModel)]="model"
      (ngModelChange)="onChange()"
      [min]="min"
      [max]="max"
      [step]="step"
    />
  </div>
  <div class="input-group-append">
    <span class="input-group-text bg-white">{{ model }}{{ suffix ? suffix : '' }}</span>
  </div>
</div> -->

<div class="w-100 mt-2 position-relative">
  <div class="position-relative">
    <input type="range" class="custom-range" [formControl]="control" [min]="min" [max]="max" [step]="step" />
    <div
      [class.slider-progress]="!isFirefox"
      [class.slider-progress-moz]="isFirefox"
      class="bg-primary"
      [id]="'slider-progress-' + sliderId"
      [hidden]="!control.value"
    ></div>
  </div>
  <output class="bubble card mb-0" [id]="'bubble-' + sliderId"></output>
</div>
