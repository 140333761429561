<ng-container
  [formGroup]="labelControl"
  *ngIf="styleSource === 'regular' && geometryType !== 'LineString'; else wrongStyleSourceTmpl"
>
  <div class="font-13 text-muted text-uppercase mb-3">Label content</div>
  <div class="form-group">
    <label class="form-label">Data attribute</label>
    <ng-select
      *ngIf="labelFields?.length"
      [items]="labelFields"
      bindLabel="label"
      bindValue="value"
      formControlName="field"
      [clearable]="false"
      appendTo=".layer-editor"
    >
      <ng-template ng-label-tmp let-item="item">
        <i [class]="item.icon"></i>
        <span class="ml-2">{{ item.label }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <i [class]="item.icon"></i>
        <span class="ml-2">{{ item.label }}</span>
      </ng-template>
    </ng-select>
    <p class="text-muted mb-0" *ngIf="!labelFields?.length">
      The attributes are unknown for this layer. Upload your layer again to access the data attributes.
    </p>
  </div>
  <ng-container *ngIf="labelControl.get('field')?.value && labelControl.get('field')?.value !== 'NO_LABELS'">
    <div class="font-13 text-muted text-uppercase mb-3 mt-4">Label style</div>

    <div class="form-group">
      <label class="form-label">Size</label>
      <div class="input-group">
        <input type="number" class="form-control" formControlName="fontSize" />
        <div class="input-group-append">
          <span class="input-group-text bg-white">pixels</span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Text color</label>
      <maplix-color-picker
        [color]="labelControl.get('textColor')?.value"
        [hideOpacity]="true"
        (colorChange)="onChangeLabelColor(labelControl.get('textColor'), $event)"
      >
      </maplix-color-picker>
    </div>

    <div class="form-group">
      <label class="form-label">Halo around text</label>
      <ng-select
        [items]="haloOptions"
        bindLabel="label"
        bindValue="value"
        formControlName="showHalo"
        [searchable]="false"
        [clearable]="false"
        appendTo=".layer-editor"
      >
        <ng-template ng-label-tmp let-item="item">
          <i [class]="item.icon"></i>
          <span class="ml-2">{{ item.label }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <i [class]="item.icon"></i>
          <span class="ml-2">{{ item.label }}</span>
        </ng-template>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="labelControl.get('showHalo')?.value">
      <label class="form-label">Halo color</label>
      <maplix-color-picker
        [color]="labelControl.get('haloColor')?.value"
        (colorChange)="onChangeLabelColor(labelControl.get('haloColor'), $event)"
      >
      </maplix-color-picker>
    </div>

    <div class="font-13 text-muted text-uppercase mb-3 mt-4" *ngIf="geometryType === 'Point'">Label positioning</div>

    <ng-container *ngIf="geometryType === 'Point'">
      <div class="form-group">
        <label class="form-label">
          Horizontal offset
          <i
            class="remixicon-question-line text-muted ml-2 clickable"
            [ngbPopover]="'Move the label to the right (positive value) or left (negative value) of the point symbol.'"
            placement="bottom"
            container="body"
          ></i>
        </label>
        <div class="input-group">
          <input type="number" class="form-control" formControlName="offsetX" />
          <div class="input-group-append">
            <span class="input-group-text bg-white">pixels</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">
          Vertical offset
          <i
            class="remixicon-question-line text-muted ml-2 clickable"
            [ngbPopover]="'Move the label to the bottom (positive value) or top (negative value) of the point symbol.'"
            placement="bottom"
            container="body"
          ></i>
        </label>
        <div class="input-group">
          <input type="number" class="form-control" formControlName="offsetY" />
          <div class="input-group-append">
            <span class="input-group-text bg-white">pixels</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="font-13 text-muted text-uppercase mb-3 mt-4">Advanced options</div>

    <div class="form-group mb-4">
      <label class="form-label">
        Zoom extent
        <i
          class="remixicon-question-line text-muted ml-2 clickable"
          [ngbPopover]="'The labels will not be displayed beyond these zoom levels.'"
          placement="bottom"
          container="body"
        ></i>
      </label>
      <div class="position-relative">
        <maplix-double-slider
          [min]="0"
          [max]="22"
          [minControl]="labelControl.get('minZoom')"
          [maxControl]="labelControl.get('maxZoom')"
          [themeColors]="themeColors"
        ></maplix-double-slider>
        <maplix-map-zoom-indicator [map]="mapService.map"></maplix-map-zoom-indicator>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #wrongStyleSourceTmpl>
  <div class="card border-0 bg-light py-2 px-3">
    <div class="d-flex align-items-center">
      <i class="remixicon-information-line font-20 mr-2"></i>
      <p class="mb-0">Labels are not supported for this style type or geometry type.</p>
    </div>
  </div>
</ng-template>
