<div
  [class.searchbar]="!!map"
  [id]="id"
  [class.space-right]="spaceRight"
  [class.searchbar-right]="position === 'right'"
>
  <div class="loader-input">
    <input
      type="text"
      class="form-control d-inline-block"
      [(ngModel)]="input"
      [placeholder]="placeholder"
      [ngbTypeahead]="search"
      [resultTemplate]="address"
      [inputFormatter]="formatter"
      placement="bottom-left"
      (selectItem)="onSelect($event)"
      [editable]="false"
    />
    <i class="fas fa-circle-notch fa-spin text-muted" *ngIf="searching"></i>
  </div>

  <ng-template #address let-r="result" let-t="term">
    {{ r.place_name }}
  </ng-template>
</div>
