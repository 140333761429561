import { AbstractControlOptions, AsyncValidatorFn, FormGroup as SafeFormGroup, ValidatorFn } from 'ngx-typesafe-forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractControl } from './abstract-control';
import { FormArray } from './form-array';
import { FormControl } from './form-control';
import { StringKeys } from './interfaces';

export class FormGroup<T> extends SafeFormGroup<T> implements AbstractControl<T> {
  private validators: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public validators$: Observable<string[]> = this.validators.asObservable();

  private asyncValidators: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public asyncValidators$: Observable<string[]> = this.asyncValidators.asObservable();

  public readonly controls: {
    [K in keyof T]: AbstractControl<T[K]>;
  };

  /**
   * Creates a new `FormGroup` instance.
   *
   * @param controls A collection of child controls. The key for each child is the name
   * under which it is registered.
   *
   * @param validatorOrOpts A synchronous validator function, or an array of
   * such functions, or an `AbstractControlOptions` object that contains validation functions
   * and a validation trigger.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   *
   */
  constructor(
    controls: {
      [K in keyof T]?: AbstractControl<T[K]>;
    },
    validatorOrOpts?: ValidatorFn<T> | ValidatorFn<T>[] | AbstractControlOptions<T> | null,
    asyncValidator?: AsyncValidatorFn<T> | AsyncValidatorFn<T>[] | null
  ) {
    super(controls as { [K in keyof T]: AbstractControl<T[K]> });

    if (validatorOrOpts) {
      if (this.isAbstractControlOption(validatorOrOpts)) {
        if (Array.isArray((validatorOrOpts as AbstractControlOptions<T>).validators)) {
          this.validators.next([
            ...((validatorOrOpts as AbstractControlOptions<T>).validators as ValidatorFn<T>[]).map((v) => v.name),
          ]);
        } else {
          this.validators.next([((validatorOrOpts as AbstractControlOptions<T>).validators as ValidatorFn<T>).name]);
        }
        this.setValidators((validatorOrOpts as AbstractControlOptions<T>).validators);

        if (Array.isArray((validatorOrOpts as AbstractControlOptions<T>).asyncValidators)) {
          this.asyncValidators.next([
            ...((validatorOrOpts as AbstractControlOptions<T>).asyncValidators as AsyncValidatorFn<T>[]).map(
              (v) => v.name
            ),
          ]);
        } else {
          this.asyncValidators.next([
            ((validatorOrOpts as AbstractControlOptions<T>).asyncValidators as AsyncValidatorFn<T>).name,
          ]);
        }
        this.setAsyncValidators((validatorOrOpts as AbstractControlOptions<T>).asyncValidators);
      } else if (Array.isArray(validatorOrOpts)) {
        this.validators.next(validatorOrOpts.map((v) => v.name));
        this.setValidators(validatorOrOpts);
      } else {
        this.validators.next([(validatorOrOpts as ValidatorFn<T>).name]);
        this.setValidators(validatorOrOpts as ValidatorFn<T>);
      }
    }

    if (asyncValidator) {
      this.asyncValidators.next(Object.keys(asyncValidator));
      this.setAsyncValidators(Object.values(asyncValidator));
    }
  }

  private isAbstractControlOption(
    validatorOrOpts: ValidatorFn<T> | ValidatorFn<T>[] | AbstractControlOptions<T>
  ): boolean {
    return 'validators' in validatorOrOpts;
  }

  /**
   * Returns an array of the names of all the validators on the control
   */
  public getValidators(): string[] {
    return this.validators.value;
  }

  /**
   * Returns an array of the names of all the async validators on the control
   */
  public getAsyncValidators(): string[] {
    return this.validators.value;
  }

  /**
   * Returns a boolean whether or not the control contains a validator
   *
   * @param key The name of the validator function
   */
  public hasValidatorByKey(key: string): boolean {
    return this.validators.value && this.validators.value.includes(key);
  }

  /**
   * Returns a boolean whether or not the control contains an async validator
   *
   * @param key The name of the validator function
   */
  public hasAsyncValidatorByKey(key: string): boolean {
    return this.asyncValidators.value && this.asyncValidators.value.includes(key);
  }

  /**
   * Sets the synchronous validators that are active on this control.  Calling
   * this overwrites any existing sync validators.
   *
   * When you add or remove a validator at run time, you must call
   * `updateValueAndValidity()` for the new validation to take effect.
   *
   */
  public setValidators(newValidator: ValidatorFn<T> | ValidatorFn<T>[] | null): void {
    if (!newValidator) {
      this.validators.next([]);
      super.setValidators([]);
      return;
    }

    if (Array.isArray(newValidator)) {
      this.validators.next([...newValidator.map((v) => v.name)]);
      super.setValidators(newValidator);
      return;
    }

    this.validators.next([newValidator.name]);
    super.setValidators(newValidator);
  }

  /**
   * Sets the async validators that are active on this control. Calling this
   * overwrites any existing async validators.
   *
   * When you add or remove a validator at run time, you must call
   * `updateValueAndValidity()` for the new validation to take effect.
   *
   */
  setAsyncValidators(newValidator: AsyncValidatorFn<T> | AsyncValidatorFn<T>[] | null): void {
    if (!newValidator) {
      this.asyncValidators.next([]);
      return;
    }

    if (Array.isArray(newValidator)) {
      this.asyncValidators.next([...newValidator.map((v) => v.name)]);
      return;
    }

    this.asyncValidators.next([newValidator.name]);
  }

  /**
   * Returns a child control as a FormControl
   *
   * @param controlName The name of the child control
   */
  public getControl<K extends StringKeys<T>>(controlName: K): AbstractControl<T[K]> {
    return super.getControl(controlName) as AbstractControl<T[K]>;
  }

  /**
   * Returns a child control as a FormControl
   *
   * @param controlName The name of the child control
   */
  public getFormControl<K extends StringKeys<T>>(controlName: K): FormControl<T[K]> {
    return this.get(controlName) as unknown as FormControl<T[K]>;
  }

  /**
   * Returns a child control as a FormGroup
   *
   * @param controlName The name of the child control
   */
  public getFormGroup<K extends StringKeys<T>>(controlName: K): FormGroup<T[K]> {
    return this.get(controlName) as unknown as FormGroup<T[K]>;
  }

  /**
   * Returns a child control as a FormArray
   *
   * @param controlName The name of the child control
   */
  public getFormArray<K extends StringKeys<T>>(controlName: K): FormArray<T[K]> {
    return this.get(controlName) as unknown as FormArray<T[K]>;
  }
}
