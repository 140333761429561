import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPopupInfo, ISurveyAnswer } from '@maplix/utils';
import { uniqBy } from 'lodash';

@Component({
  selector: 'maplix-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
})
export class MapPopupComponent implements OnChanges {
  @Input()
  public readonly popupInfo: IPopupInfo;

  @Input()
  public readonly mapId: string;

  public loadedImages = [];

  public language: string;

  constructor(private translate: TranslateService) {}

  ngOnChanges() {
    if (!this.popupInfo) {
      return;
    }
    !this.popupInfo.features ||
      this.popupInfo.features.forEach((feature) => {
        if (!feature.answers && feature.properties) {
          Object.keys(feature.properties).forEach((prop) => {
            if (
              this.isLink(feature.properties[prop]) &&
              !this.loadedImages.includes(feature.properties[prop])
            ) {
              this.testImage(feature.properties[prop]);
            }
          });
          return;
        }

        Object.keys(feature.answers).forEach((question) => {
          feature.answers[question].forEach((answer) => {
            if (
              answer.type == 6 &&
              !this.loadedImages.includes(answer.value_label)
            ) {
              this.testImage(answer.value_label);
            }
          });
        });
      });

      this.language = this.translate.currentLang;
  }

  public isLink(property: string) {
    return (
      typeof property === 'string' &&
      (property.includes('http://') || property.includes('https://'))
    );
  }

  public translateTransportMode(mode: string) {
    return this.translate.instant('map.transportmodi.' + mode);
  }

  public getKeys(obj: any): string[] {
    return Object.keys(obj).filter((key) => key !== 'geometry');
  }

  public testImage(url: string) {
    var tester = new Image();
    tester.onload = () => {
      this.loadedImages.push(url);
    };
    tester.src = url;
  }

  public onOpenImage(url: string) {
    window.open(url, '_blank');
  }

  public getSubquestions(
    answers: ISurveyAnswer[]
  ): { id: string; label: string }[] {
    const subquestions = answers
      .filter((a) => a.subquestion)
      .map((a) => {
        return {
          id: a.subquestion,
          label: a.subquestion_label,
        };
      });

    return uniqBy(subquestions, 'id');
  }
}
