import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@maplix/forms';
import { ILegendUrl } from '@maplix/maps';
import { IMapDataLayer, IMapResult, ISurveyImage, ISurveyMap, MapType } from '@maplix/utils';

@Component({
  selector: 'maplix-map-sidebar',
  templateUrl: './map-sidebar.component.html',
  styleUrls: ['./map-sidebar.component.scss']
})
export class MapSidebarComponent implements OnInit {

  @Input()
  public mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage>;

  @Input()
  public readonly mapType: MapType;

  @Input()
  public readonly wmsLegends: ILegendUrl[];

  @Input()
  public readonly language: string;

  @Input()
  public readonly editable: boolean;

  @Input()
  public readonly legendText: string = 'Layers';

  @Input()
  public readonly helptext: string;
  
  @Output()
  private changeLayerOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  private toggleVisibility: EventEmitter<
    FormGroup<IMapDataLayer>
  > = new EventEmitter();

  @Output()
  private onDelete: EventEmitter<any> = new EventEmitter();

  @Output()
  private onZoom: EventEmitter<any> = new EventEmitter();

  @Output()
  private open: EventEmitter<{
    type: string;
    layer?: FormGroup<IMapDataLayer>;
  }> = new EventEmitter();

  @Output()
  private openCloud: EventEmitter<void> = new EventEmitter();

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  @Output()
  private toggleHidden: EventEmitter<{
    showOnLegend: boolean;
    layer?: FormGroup<IMapDataLayer>;
  }> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onCloseSidebar() {
    this.close.emit();
  }

  public onChangeVisibility(layer: FormGroup<IMapDataLayer>) {
    this.toggleVisibility.emit(layer);
  }

  public onZoomToLayer(layer) {
    this.onZoom.emit(layer);
  }

  public onOpenLayerModal($event) {
    this.open.emit($event);
  }

  public onOpenCloudModal() {
    this.openCloud.emit();
  }

  public onToggleHidden($event) {
    this.toggleHidden.emit($event);
  }

  public onChangeLayerOrder() {
    this.changeLayerOrder.emit();
  }

  public onDeleteLayer(layer: IMapDataLayer) {
    this.onDelete.emit(layer);
  }

}
