import { I18n } from '../i18n';
import { ObjectId } from '../object-id';
import { IUserDetails } from './users';

export interface IProject {
  _id?: ObjectId;
  organisation: ObjectId;
  general: IProjectDetails;
  management?: IProjectManagement;
  _archived?: Date;
}

export interface IProjectDetails {
  uri: string;
  title: I18n[];
  description: I18n[];
  thumbnail: string;
  duration: [Date, Date];
  externalUrl: I18n[];
}
export interface IProjectManagement {
  budget: IProjectBudget;
  team: IProjectTeamMember[];
}

export interface IProjectBudget {
  total: number;
  financing: IProjectBudgetFinancing[];
}

export interface IProjectBudgetFinancing {
  id: string;
  label: I18n[];
  amount: number;
}

export interface IProjectTeamMember {
  user: ObjectId | IUserDetails; //refs userid
  role: TeamMemberRole;
  backup?: boolean;
}

export enum TeamMemberRole {
  LEAD = 'LEAD',
  CORE = 'CORE',
  MEMBER = 'MEMBER',
}
