import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@maplix/forms';
import { IMapDataLayer, IMapSurveyLayer, IMapSurveyVector, IMapVector, IStyle, MapStyleSource } from '@maplix/utils';
import { ILegendUrl } from '@maplix/maps';
import { first } from 'lodash';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'maplix-map-layers',
  templateUrl: './map-layers.component.html',
  styleUrls: ['./map-layers.component.scss'],
})
export class MapLayersComponent implements OnInit {
  public collapsedLayers: string[] = [];

  @ViewChild('deleteSwal', { static: false })
  private deleteSwal: SwalComponent;

  @Input()
  public dataLayers: FormArray<IMapDataLayer>;

  @Input()
  public dataLayerValues: IMapDataLayer[];

  @Input()
  private wmsLegends: ILegendUrl[];

  @Input()
  public readonly language: string;

  @Input()
  public readonly legendText: string = 'Layers';

  @Output()
  private changeLayerOrder: EventEmitter<void> = new EventEmitter();

  @Output()
  private toggleVisibility: EventEmitter<FormGroup<IMapDataLayer>> = new EventEmitter();

  @Output()
  private onDelete: EventEmitter<any> = new EventEmitter();

  @Output()
  private onZoom: EventEmitter<any> = new EventEmitter();

  @Output()
  private open: EventEmitter<{
    type: string;
    layer: FormGroup<IMapDataLayer>;
    index: number;
  }> = new EventEmitter();

  @Output()
  private openCloud: EventEmitter<void> = new EventEmitter();

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  @Output()
  private toggleHidden: EventEmitter<{
    showOnLegend: boolean;
    layer?: FormGroup<IMapDataLayer>;
  }> = new EventEmitter();

  ngOnInit() {
    if (!this.dataLayers) {
      return;
    }

    this.dataLayers.controls.forEach((dataLayer: FormGroup<IMapDataLayer>) => {
      if (!dataLayer.getFormControl('selectedInMap').value) {
        this.collapsedLayers.push(dataLayer.getFormControl('id').value);
      }
    });
  }

  public onChangeVisibility(layer: FormGroup<IMapDataLayer>) {
    this.toggleVisibility.emit(layer);
  }

  public onZoomToLayer(layer) {
    this.onZoom.emit(layer);
  }

  public onOpenLayerModal(type: string, layer: FormGroup<IMapDataLayer>, index: number) {
    this.open.emit({ type, layer, index });
  }

  public onOpenCloudModal() {
    this.openCloud.emit();
  }

  public onEditLayer(layer: FormGroup<IMapDataLayer>, index: number) {
    this.onOpenLayerModal(layer.getControl('type').value, layer, index);
  }

  public async onDeleteLayer(layer: FormGroup<IMapDataLayer>) {
    const result = await this.deleteSwal.fire();

    if (result && result.value) {
      this.onDelete.emit(layer.getRawValue());
    }
  }

  public onToggleHidden(layer: FormGroup<IMapDataLayer>) {
    this.toggleHidden.emit({
      showOnLegend: !layer.getFormControl('showOnLegend').value,
      layer,
    });
  }

  public onChangeLayerOrder() {
    this.changeLayerOrder.emit();
  }

  public getWmsLegend(id: string) {
    return this.wmsLegends && this.wmsLegends.find((legend) => legend.id === id);
  }

  public hasVisibleChildren(dataLayer: IMapSurveyVector | IMapSurveyLayer): boolean {
    if ('combineSubLayers' in dataLayer && !dataLayer.combineSubLayers) {
      return (
        (!dataLayer.combineSubLayers && dataLayer.layers.filter((l) => l.selectedInModal).length > 1) ||
        (dataLayer.layers.filter((l) => l.selectedInModal).length <= 1 &&
          !!dataLayer.layers.filter((l) => l.selectedInModal).find((l) => l.styleConditions.length > 1))
      );
    }

    return dataLayer.styleConditions.length > 1;
  }

  public getStyleForLegend(dataLayer: IMapVector | IMapSurveyVector): IStyle {
    if ((dataLayer as IMapSurveyVector).layers) {
      const selectedLayers = (dataLayer as IMapSurveyVector).layers.filter((l) => l.selectedInModal);
      if (
        !(((dataLayer as IMapSurveyVector).combineSubLayers && selectedLayers.length > 1) || !selectedLayers.length)
      ) {
        return first(first(selectedLayers).styleConditions).style;
      }
    }

    return first(dataLayer.styleConditions).style;
  }

  public getStyleSourceForLegend(dataLayer: IMapVector | IMapSurveyVector): MapStyleSource {
    if ((dataLayer as IMapSurveyVector).layers) {
      const selectedLayers = (dataLayer as IMapSurveyVector).layers.filter((l) => l.selectedInModal);
      if (
        !(((dataLayer as IMapSurveyVector).combineSubLayers && selectedLayers.length > 1) || !selectedLayers.length)
      ) {
        return first(selectedLayers).styleSource;
      }
    }

    return dataLayer.styleSource;
  }

  public onToggleCollapse(layer: string) {
    if (this.collapsedLayers.includes(layer)) {
      this.collapsedLayers = this.collapsedLayers.filter((l) => l !== layer);
      return;
    }

    this.collapsedLayers.push(layer);
  }
}
