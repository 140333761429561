<div
  class="quill-wrapper"
  [class.show-collapse-toggle]="collapsable"
  [class.show-generator-button]="showGeneratorButton"
>
  <quill-editor
    [id]="inputId"
    [modules]="editorModules"
    [formControl]="control"
    [placeholder]="placeholder"
    class="w-100"
    #quillEditor
  ></quill-editor>

  <div
    *ngIf="showGeneratorButton"
    class="generator-toggle clickable"
    [ngbTooltip]="'Load from template'"
    placement="left"
    (click)="onGenerate()"
  >
    <i class="fa fa-magic font-16 text-dark"></i>
  </div>
  <div
    *ngIf="collapsable && showToggleButton"
    class="collapse-toggle"
    [class.clickable]="!control.disabled"
    [class.border]="!collapsed"
    [class.border-primary]="!collapsed"
    [ngbTooltip]="control.disabled ? '' : collapsed ? 'Show format options' : 'Hide format options'"
    [disabled]="control.disabled"
    placement="left"
    (click)="onToggleCollapse()"
  >
    <i
      class="mdi mdi-format-letter-case-upper font-20"
      [class.text-primary]="!collapsed"
      [class.text-dark]="collapsed"
    ></i>
  </div>
</div>
