import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@maplix/forms';
import { ImageCls, ImageClsAnalytics, MapCls, MapClsAnalytics } from '../class';
import {
  ArrowStyle,
  ConditionType,
  DashStyle,
  getMapStyle,
  GroupOperator,
  I18n,
  ICapabilityLayer,
  IconBackgroundType,
  ICQLFilter,
  IIconBackground,
  ILabelOptions,
  ILanguage,
  IMapDataLayer,
  IMapInteraction,
  IMapOptions,
  IMapResult,
  IMapSurveyLayer,
  IMapSurveyVector,
  IMapUploadVector,
  IMapVector,
  IMapWMS,
  IMapWMSLayer,
  IRelation,
  IStyle,
  IStyleCondition,
  ISurvey,
  ISurveyImage,
  ISurveyMap,
  ITheme,
  IThemeColors,
  IThemeMapAddressSearch,
  IThemeMapBaseLayerToggle,
  IThemeMapLegend,
  IThemeMapOptions,
  IThemeMapTitleBar,
  IThemeOptions,
  IWMSCapabilities,
  LegendPosition,
  LegendStyle,
  LineStyle,
  LineType,
  MapStyleSource,
  MapType,
  ObjectId,
  PointType,
  randomIdGenerator,
  resetLayerCache,
  THEME_COLORS,
  TitleBarPosition,
  ToastrNotification,
} from '@maplix/utils';
import BaseLayer from 'ol/layer/Base';
import ImageLayer from 'ol/layer/Image';
import VectorImageLayer from 'ol/layer/VectorImage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, first, take, takeUntil, tap } from 'rxjs/operators';
import WmsCapabilites from 'ol/format/WMSCapabilities';
import { ApiService } from '@maplix/api';
import { ToastrService } from 'ngx-toastr';
import TileLayer from 'ol/layer/Tile';
import { Cluster, TileWMS } from 'ol/source';
import { ILayerDataset } from '@maplix/cloud';
import { Heatmap } from 'ol/layer';
import { Extent } from 'ol/extent';
import { WorkspaceService } from '@maplix/services';
import { Coordinate } from 'ol/coordinate';
import * as moment from 'moment';
import VectorSource from 'ol/source/Vector';
import Static from 'ol/source/ImageStatic';
import * as Color from 'color';
import { Type } from 'ol/geom/Geometry';

@Injectable({
  providedIn: 'root',
})
export class MapLayerService {
  protected destroyed: Subject<void> = new Subject();

  public wmsLoading: boolean;

  public wmsError: boolean;

  private dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dataLoaded$: Observable<boolean> = this.dataLoaded.asObservable();

  private mapDestroyed: Subject<void> = new Subject();

  protected mapLanguages: string[] = [];

  protected selectedLanguage: string;

  public get language(): string {
    return this.selectedLanguage;
  }

  public get languages(): string[] {
    return this.mapLanguages;
  }

  public notification: ToastrNotification;

  private activeMap: IMapResult;

  public map: MapClsAnalytics | ImageClsAnalytics;

  public mapForm: FormGroup<IMapResult>;

  private mapType: MapType;

  private image: string;

  private imageExtent: Extent;

  private surveyForImage: ISurvey;

  private surveyCache: ISurvey[] = [];

  public generatingQrCode: boolean;

  constructor(
    @Inject('environment') private environment: any,
    private toastr: ToastrService,
    private api: ApiService,
    private workspaceService: WorkspaceService
  ) {
    this.notification = new ToastrNotification(toastr);
  }

  public ngOnDestroyComponent() {
    this.destroyed.next();
    resetLayerCache();

    this.map = null;
    this.mapForm = null;
    this.setLanguages();
    this.setSelectedLanguage();
    this.setActiveMap(null);
  }

  public setLanguages(languages?: string[]) {
    if (!languages) {
      languages = [];
    }
    this.mapLanguages = languages;
  }

  public setSelectedLanguage(language: string = null) {
    this.selectedLanguage = language;
  }

  private async getSurveyFromCache(surveyId: string): Promise<ISurvey> {
    if (this.surveyCache && this.surveyCache.map((survey) => survey._id).includes(surveyId)) {
      return this.surveyCache.find((survey) => survey._id === surveyId);
    }

    return this.api.surveys
      .getSurveyById(surveyId)
      .pipe(
        tap((survey) => {
          if (this.surveyCache) {
            this.surveyCache.push(survey);
            return;
          }

          this.surveyCache = [survey];
        })
      )
      .toPromise();
  }

  public async patchLayers(
    result: FormGroup<IMapResult | ISurveyMap | ISurveyImage>,
    activeMap: IMapResult | ISurveyMap | ISurveyImage,
    mapType?: MapType,
    viewer?: boolean
  ) {
    // Patch the form arrays individually
    let loadedLayers = 0;
    if (!activeMap.dataLayers || !activeMap.dataLayers.length) {
      this.dataLoaded.next(true);
      return;
    }

    let layerIndex = 0;
    for (let dataLayer of activeMap.dataLayers) {
      let layerFg;
      switch (dataLayer.type) {
        case 'SURVEY':
          let surveyToUse;

          if (!viewer) {
            surveyToUse = await this.getSurveyFromCache((dataLayer as IMapSurveyVector).survey);
          }

          layerFg = this.createSurveyLayer(mapType, surveyToUse, dataLayer as IMapSurveyVector);
          // layerFg.patchValue(dataLayer);
          result.getFormArray('dataLayers').insert(layerIndex, layerFg);
          loadedLayers++;
          if (loadedLayers === activeMap.dataLayers.length) {
            this.dataLoaded.next(true);
          }
          break;
        case 'UPLOAD':
          layerFg = this.createUploadLayer(dataLayer as IMapUploadVector);
          // layerFg.patchValue(dataLayer);
          result.getFormArray('dataLayers').insert(layerIndex, layerFg);
          loadedLayers++;
          if (loadedLayers === activeMap.dataLayers.length) {
            this.dataLoaded.next(true);
          }
          break;
        case 'WMS':
          layerFg = this.createWMSLayer(dataLayer as IMapWMS);
          // layerFg.patchValue(dataLayer);

          if (dataLayer && (dataLayer as IMapWMS).layers) {
            result.getFormArray('dataLayers').insert(layerIndex, layerFg);
            loadedLayers++;
            if (loadedLayers === activeMap.dataLayers.length) {
              this.dataLoaded.next(true);
            }
            break;
          }

          await layerFg
            .getControl('url')
            .value$.pipe(
              first(),
              tap(async (url: string) => {
                if (url) {
                  await this.getWmsLayers(url, layerFg, dataLayer as IMapWMS);
                  result.getFormArray('dataLayers').insert(layerIndex, layerFg);
                  loadedLayers++;
                  if (loadedLayers === activeMap.dataLayers.length) {
                    this.dataLoaded.next(true);
                  }
                }
              }),
              takeUntil(this.destroyed)
            )
            .toPromise();
          break;
      }

      layerIndex++;
    }
  }

  public onLoadLayers(map: MapCls | ImageCls, mapFormValue: IMapResult | ISurveyMap | ISurveyImage, mapType: MapType) {
    if (!map || !map.map || !mapFormValue) {
      return;
    }

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    let zIndex = 30000;

    // Remove maplix dataLayers (not draw layers) first
    mapLayers
      .filter((layer) => layer.get('maplixId'))
      .forEach((layer) => {
        map.map.removeLayer(layer);
      });

    mapFormValue.dataLayers.forEach((layerValue: IMapDataLayer) => {
      // Create the data layer
      let layerToAdd: BaseLayer;
      const crs = mapType === 'IMAGE' ? 404000 : 3857;
      if (layerValue.type === 'SURVEY') {
        zIndex = this.addSurveyLayer(layerValue as IMapSurveyVector, zIndex, map, crs);
      } else if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
        layerToAdd = map.addWFS(
          layerValue as IMapVector,
          (layerValue as IMapVector).styleSource,
          layerValue as IMapVector,
          null,
          null,
          zIndex,
          crs
        );
        map.map.addLayer(layerToAdd);
      } else if (layerValue.type === 'WMS') {
        const wmsLayers = (layerValue as IMapWMS).layers;
        if (wmsLayers) {
          wmsLayers
            .filter((wmsLayer) => wmsLayer.selectedInModal)
            .reverse()
            .forEach((wmsLayer) => {
              layerToAdd = (map as MapCls).addWMS(layerValue as IMapWMS, zIndex, wmsLayer);

              (layerToAdd as ImageLayer<Static>).getSource().once('imageloaderror', () => {
                this.notification.warning(
                  'WMS data is not available. Please make sure the WMS is publicly accessible.',
                  'WMS error',
                  { timeOut: 7500 }
                );
              });
              map.map.addLayer(layerToAdd);
            });
        }
      }

      zIndex--;
    });
  }

  public onRefreshLayersOrder(map: MapCls | ImageCls, mapFormValue: IMapResult | ISurveyMap | ISurveyImage) {
    if (!map || !map.map || !mapFormValue) {
      return;
    }

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    let zIndex = 30000;

    mapFormValue.dataLayers.forEach((layerValue) => {
      if (layerValue.type === 'SURVEY') {
        let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
          (l) => l.get('maplixId') === (layerValue as IMapSurveyVector).id
        ) as VectorImageLayer<VectorSource>;
        if (mapLayer) {
          mapLayer.setZIndex(zIndex);
        } else {
          (layerValue as IMapSurveyVector).layers
            .filter((surveyLayer) => surveyLayer.selectedInModal)
            .forEach((surveyLayer) => {
              mapLayer = mapLayers.find(
                (l) => l.get('maplixId') === (surveyLayer as IMapSurveyLayer).id
              ) as VectorImageLayer<VectorSource>;
              if (!mapLayer) {
                surveyLayer.styleConditions.forEach((styleCondition) => {
                  mapLayer = mapLayers.find(
                    (l) => l.get('maplixId') === styleCondition.id
                  ) as VectorImageLayer<VectorSource>;
                });
              }
              if (mapLayer) {
                mapLayer.setZIndex(zIndex);
              }
            });
        }
      } else if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
        let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
          (l) => l.get('maplixId') === (layerValue as IMapVector).id
        ) as VectorImageLayer<VectorSource>;
        if (!mapLayer) {
          (layerValue as IMapVector).styleConditions.forEach((styleCondition) => {
            mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;
          });
        }
        if (mapLayer) {
          mapLayer.setZIndex(zIndex);
        }
      } else if (layerValue.type === 'WMS') {
        let mapLayer: TileLayer<TileWMS> = mapLayers.find(
          (l) => l.get('maplixId') === (layerValue as IMapWMS).id
        ) as TileLayer<TileWMS>;
        if (mapLayer) {
          mapLayer.setZIndex(zIndex);
        } else {
          (layerValue as IMapWMS).layers
            .filter((wmsLayer) => wmsLayer.selectedInModal)
            .forEach((wmsLayer) => {
              mapLayer = mapLayers.find(
                (l) => l.get('maplixId') === (wmsLayer as IMapWMSLayer).id
              ) as TileLayer<TileWMS>;
              if (mapLayer) {
                mapLayer.setZIndex(zIndex);
              }
            });
        }
      }

      zIndex--;
    });
  }

  public onRefreshLayerZoomLevels(map: MapCls | ImageCls, layerValue: IMapVector) {
    if (!map || !map.map || !layerValue) {
      return;
    }

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    if (layerValue.type === 'SURVEY') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === layerValue.id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        mapLayer.setMinZoom(layerValue.minZoom);
        mapLayer.setMaxZoom(layerValue.maxZoom);
        return;
      }

      if ((layerValue as IMapSurveyVector).combineSubLayers) {
        (layerValue as IMapSurveyVector).styleConditions.forEach((styleCondition) => {
          mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            mapLayer.setMinZoom(layerValue.minZoom);
            mapLayer.setMaxZoom(layerValue.maxZoom);
          }
        });

        return;
      }

      (layerValue as IMapSurveyVector).layers
        .filter((surveyLayer) => surveyLayer.selectedInModal)
        .forEach((surveyLayer) => {
          mapLayer = mapLayers.find(
            (l) => l.get('maplixId') === (surveyLayer as IMapSurveyLayer).id
          ) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            mapLayer.setMinZoom(layerValue.minZoom);
            mapLayer.setMaxZoom(layerValue.maxZoom);
            return;
          }

          surveyLayer.styleConditions.forEach((styleCondition) => {
            mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

            if (mapLayer) {
              mapLayer.setMinZoom(layerValue.minZoom);
              mapLayer.setMaxZoom(layerValue.maxZoom);
            }
          });
        });

      return;
    }

    if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapVector).id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        mapLayer.setMinZoom(layerValue.minZoom);
        mapLayer.setMaxZoom(layerValue.maxZoom);
        return;
      }

      (layerValue as IMapVector).styleConditions.forEach((styleCondition) => {
        mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

        if (mapLayer) {
          mapLayer.setMinZoom(layerValue.minZoom);
          mapLayer.setMaxZoom(layerValue.maxZoom);
          return;
        }
      });
    }
  }

  public setLayerSelectable(map: MapCls | ImageCls, layerValue: IMapVector) {
    if (!map || !map.map || !layerValue) {
      return;
    }

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    if (layerValue.type === 'SURVEY') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === layerValue.id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        mapLayer.set('selectable', layerValue.isSelectable);
        return;
      }

      if ((layerValue as IMapSurveyVector).combineSubLayers) {
        (layerValue as IMapSurveyVector).styleConditions.forEach((styleCondition) => {
          mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            mapLayer.set('selectable', layerValue.isSelectable);
          }
        });

        return;
      }

      (layerValue as IMapSurveyVector).layers
        .filter((surveyLayer) => surveyLayer.selectedInModal)
        .forEach((surveyLayer) => {
          mapLayer = mapLayers.find(
            (l) => l.get('maplixId') === (surveyLayer as IMapSurveyLayer).id
          ) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            mapLayer.set('selectable', layerValue.isSelectable);
            return;
          }

          surveyLayer.styleConditions.forEach((styleCondition) => {
            mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

            if (mapLayer) {
              mapLayer.set('selectable', layerValue.isSelectable);
            }
          });
        });

      return;
    }

    if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapVector).id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        mapLayer.set('selectable', layerValue.isSelectable);
        return;
      }

      (layerValue as IMapVector).styleConditions.forEach((styleCondition) => {
        mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

        if (mapLayer) {
          mapLayer.set('selectable', layerValue.isSelectable);
          return;
        }
      });
    }
  }

  public onRefreshLayerStyle(map: MapCls | ImageCls, layerValue: IMapDataLayer) {
    if (!map || !map.map || !layerValue) {
      return;
    }

    resetLayerCache();

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    if (layerValue.type === 'SURVEY') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapSurveyVector).id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        if (mapLayer instanceof Heatmap) {
          (mapLayer as Heatmap).setBlur((layerValue as IMapSurveyVector).styleConditions[0].style.heatmapBlurSize);
          (mapLayer as Heatmap).setRadius((layerValue as IMapSurveyVector).styleConditions[0].style.heatmapBlurRadius);
          return;
        }

        const source = mapLayer.getSource();
        if (source instanceof Cluster) {
          source.setDistance((layerValue as IMapSurveyVector).styleConditions[0].style.clusterDistance);
        }

        mapLayer.setStyle(
          getMapStyle(
            (layerValue as IMapSurveyVector).geomType === 'LineString' &&
              (layerValue as IMapSurveyVector).lineType === 'Route'
              ? 'Route'
              : (layerValue as IMapSurveyVector).geomType,
            (layerValue as IMapSurveyVector).styleConditions,
            (layerValue as IMapSurveyVector).styleSource,
            this.map.map
          )
        );
        return;
      }

      if ((layerValue as IMapSurveyVector).combineSubLayers) {
        (layerValue as IMapSurveyVector).styleConditions.forEach((styleCondition) => {
          mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            mapLayer.setStyle(
              getMapStyle(
                (layerValue as IMapSurveyVector).geomType === 'LineString' &&
                  (layerValue as IMapSurveyVector).lineType === 'Route'
                  ? 'Route'
                  : (layerValue as IMapSurveyVector).geomType,
                [styleCondition],
                (layerValue as IMapSurveyVector).styleSource,
                this.map.map
              )
            );
          }
        });

        return;
      }

      (layerValue as IMapSurveyVector).layers
        .filter((surveyLayer) => surveyLayer.selectedInModal)
        .forEach((surveyLayer) => {
          mapLayer = mapLayers.find(
            (l) => l.get('maplixId') === (surveyLayer as IMapSurveyLayer).id
          ) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            if (mapLayer instanceof Heatmap) {
              (mapLayer as Heatmap).setBlur((surveyLayer as IMapSurveyLayer).styleConditions[0].style.heatmapBlurSize);
              (mapLayer as Heatmap).setRadius(
                (surveyLayer as IMapSurveyLayer).styleConditions[0].style.heatmapBlurRadius
              );
              return;
            }

            const source = mapLayer.getSource();
            if (source instanceof Cluster) {
              source.setDistance((surveyLayer as IMapSurveyLayer).styleConditions[0].style.clusterDistance);
            }

            mapLayer.setStyle(
              getMapStyle(
                (layerValue as IMapSurveyVector).geomType === 'LineString' &&
                  (layerValue as IMapSurveyVector).lineType === 'Route'
                  ? 'Route'
                  : (layerValue as IMapSurveyVector).geomType,
                (surveyLayer as IMapSurveyLayer).styleConditions,
                (surveyLayer as IMapSurveyLayer).styleSource,
                this.map.map
              )
            );
            return;
          }

          surveyLayer.styleConditions.forEach((styleCondition) => {
            mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

            if (mapLayer) {
              mapLayer.setStyle(
                getMapStyle(
                  (layerValue as IMapSurveyVector).geomType === 'LineString' &&
                    (layerValue as IMapSurveyVector).lineType === 'Route'
                    ? 'Route'
                    : (layerValue as IMapSurveyVector).geomType,
                  [styleCondition],
                  (surveyLayer as IMapSurveyLayer).styleSource,
                  this.map.map
                )
              );
            }
          });
        });

      return;
    }

    if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapVector).id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        if (mapLayer instanceof Heatmap) {
          (mapLayer as Heatmap).setBlur((layerValue as IMapVector).styleConditions[0].style.heatmapBlurSize);
          (mapLayer as Heatmap).setRadius((layerValue as IMapVector).styleConditions[0].style.heatmapBlurRadius);
          return;
        }

        const source = mapLayer.getSource();
        if (source instanceof Cluster) {
          source.setDistance((layerValue as IMapVector).styleConditions[0].style.clusterDistance);
        }

        mapLayer.setStyle(
          getMapStyle(
            (layerValue as IMapVector).geomType,
            (layerValue as IMapVector).styleConditions,
            (layerValue as IMapVector).styleSource,
            this.map.map
          )
        );
        return;
      }

      (layerValue as IMapVector).styleConditions.forEach((styleCondition) => {
        mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

        if (mapLayer) {
          mapLayer.setStyle(
            getMapStyle(
              (layerValue as IMapVector).geomType,
              [styleCondition],
              (layerValue as IMapVector).styleSource,
              this.map.map
            )
          );
          return;
        }
      });
    }

    if (layerValue.type === 'WMS') {
      let mapLayer: TileLayer<TileWMS> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapWMS).id
      ) as TileLayer<TileWMS>;

      if (mapLayer) {
        mapLayer.setOpacity((layerValue as IMapWMS).opacity / 100);
        return;
      }

      (layerValue as IMapWMS).layers
        .filter((wmsLayer) => wmsLayer.selectedInModal)
        .forEach((wmsLayer) => {
          let mapLayer: TileLayer<TileWMS> = mapLayers.find(
            (l) => l.get('maplixId') === wmsLayer.id
          ) as TileLayer<TileWMS>;

          if (mapLayer) {
            mapLayer.setOpacity((layerValue as IMapWMS).opacity / 100);
            return;
          }
        });
    }
  }

  public onRefreshLayerData(map: MapCls | ImageCls, layerValue: IMapDataLayer) {
    if (!map || !map.map || !layerValue) {
      return;
    }

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    if (layerValue.type === 'SURVEY') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapSurveyVector).id
      ) as VectorImageLayer<VectorSource>;
      if (mapLayer) {
        mapLayer.getSource().refresh();
      } else {
        (layerValue as IMapSurveyVector).layers
          .filter((surveyLayer) => surveyLayer.selectedInModal)
          .forEach((surveyLayer) => {
            mapLayer = mapLayers.find(
              (l) => l.get('maplixId') === (surveyLayer as IMapSurveyLayer).id
            ) as VectorImageLayer<VectorSource>;
            if (!mapLayer) {
              surveyLayer.styleConditions.forEach((styleCondition) => {
                mapLayer = mapLayers.find(
                  (l) => l.get('maplixId') === styleCondition.id
                ) as VectorImageLayer<VectorSource>;

                if (mapLayer) {
                  mapLayer.getSource().refresh();
                }
              });
            }

            if (mapLayer) {
              mapLayer.getSource().refresh();
            }
          });
      }
    } else if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapVector).id
      ) as VectorImageLayer<VectorSource>;
      if (!mapLayer) {
        (layerValue as IMapVector).styleConditions.forEach((styleCondition) => {
          mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            mapLayer.getSource().refresh();
          }
        });
      }
      if (mapLayer) {
        mapLayer.getSource().refresh();
      }
    } else if (layerValue.type === 'WMS') {
      const existingMapLayers = mapLayers.filter((l) =>
        (layerValue as IMapWMS).layers.map((layer) => layer.id).includes(l.get('maplixId'))
      );
      const zIndex = existingMapLayers[0].getZIndex();

      const selectedWmsLayers = (layerValue as IMapWMS).layers.filter((wmsLayer) => wmsLayer.selectedInModal);

      // Remove existing layer if it is not selected anymore
      existingMapLayers.forEach((mapLayer) => {
        map.map.removeLayer(mapLayer);
      });

      // Add newly selected layers
      selectedWmsLayers.reverse().forEach((wmsLayer) => {
        const layerToAdd = (map as MapCls).addWMS(layerValue as IMapWMS, zIndex, wmsLayer);

        ((layerToAdd as TileLayer<TileWMS>).getSource() as TileWMS).once('tileloaderror', () => {
          this.notification.danger('Please make sure the WMS is publicly accessible.', 'Failed to load WMS', {
            timeOut: 7500,
          });
        });
        map.map.addLayer(layerToAdd);
      });
    }
  }

  /*
   Remove the layer and return the zIndex of that layer
  */
  public onRemoveLayer(map: MapCls | ImageCls, layerValue: IMapDataLayer): number {
    if (!map || !map.map || !layerValue) {
      return;
    }

    let zIndex: number;

    let mapLayers = map.map
      .getLayers()
      .getArray()
      .filter((layer) => !layer.get('base'));

    if (layerValue.type === 'SURVEY') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapSurveyVector).id
      ) as VectorImageLayer<VectorSource>;

      if (mapLayer) {
        map.map.removeLayer(mapLayer);
        zIndex = mapLayer.getZIndex();
        resetLayerCache();
        return zIndex;
      }

      (layerValue as IMapSurveyVector).styleConditions.forEach((styleCondition) => {
        mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

        if (mapLayer) {
          map.map.removeLayer(mapLayer);
          zIndex = mapLayer.getZIndex();
        }
      });

      (layerValue as IMapSurveyVector).layers.forEach((surveyLayer) => {
        mapLayer = mapLayers.find(
          (l) => l.get('maplixId') === (surveyLayer as IMapSurveyLayer).id
        ) as VectorImageLayer<VectorSource>;
        if (!mapLayer) {
          surveyLayer.styleConditions.forEach((styleCondition) => {
            mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

            if (mapLayer) {
              map.map.removeLayer(mapLayer);
              zIndex = mapLayer.getZIndex();
            }
          });
          return;
        }

        map.map.removeLayer(mapLayer);
        zIndex = mapLayer.getZIndex();
      });
    } else if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
      let mapLayer: VectorImageLayer<VectorSource> = mapLayers.find(
        (l) => l.get('maplixId') === (layerValue as IMapVector).id
      ) as VectorImageLayer<VectorSource>;

      if (!mapLayer) {
        (layerValue as IMapVector).styleConditions.forEach((styleCondition) => {
          mapLayer = mapLayers.find((l) => l.get('maplixId') === styleCondition.id) as VectorImageLayer<VectorSource>;

          if (mapLayer) {
            map.map.removeLayer(mapLayer);
            zIndex = mapLayer.getZIndex();
          }
        });
        resetLayerCache();
        return zIndex;
      }

      map.map.removeLayer(mapLayer);
      zIndex = mapLayer.getZIndex();
    } else if (layerValue.type === 'WMS') {
      (layerValue as IMapWMS).layers.forEach((wmsLayer) => {
        let mapLayer = mapLayers.find((l) => l.get('maplixId') === wmsLayer.id) as TileLayer<TileWMS>;

        if (mapLayer) {
          map.map.removeLayer(mapLayer);
          zIndex = mapLayer.getZIndex();
        }
      });
    }

    resetLayerCache();
    return zIndex;
  }

  public onAddLayer(map: MapCls | ImageCls, layerValue: IMapDataLayer, mapType: MapType, zIndex?: number) {
    if (!map || !map.map || !layerValue) {
      return;
    }

    if (!zIndex) {
      const zIndeces = map.map
        .getLayers()
        .getArray()
        .filter((layer) => !layer.get('base'))
        .map((layer) => layer.getZIndex());

      zIndex = Math.min(...zIndeces) - 1;
    }

    // Create the data layer
    let layerToAdd: BaseLayer;
    const crs = mapType === 'IMAGE' ? 404000 : 3857;
    if (layerValue.type === 'SURVEY') {
      this.addSurveyLayer(layerValue as IMapSurveyVector, zIndex, map, crs);
    } else if (layerValue.type === 'WFS' || layerValue.type === 'UPLOAD') {
      layerToAdd = map.addWFS(
        layerValue as IMapVector,
        (layerValue as IMapVector).styleSource,
        layerValue as IMapVector,
        null,
        null,
        zIndex,
        crs
      );
      map.map.addLayer(layerToAdd);
    } else if (layerValue.type === 'WMS') {
      const wmsLayers = (layerValue as IMapWMS).layers;
      if (wmsLayers) {
        wmsLayers
          .filter((wmsLayer) => wmsLayer.selectedInModal)
          .reverse()
          .forEach((wmsLayer) => {
            layerToAdd = (map as MapCls).addWMS(layerValue as IMapWMS, zIndex, wmsLayer);

            (layerToAdd as TileLayer<TileWMS>).getSource().once('tileloaderror', () => {
              this.notification.warning(
                'WMS data is not available. Please make sure the WMS is publicly accessible.',
                'WMS error',
                { timeOut: 7500 }
              );
            });
            map.map.addLayer(layerToAdd);
          });
      }
    }
  }

  private addSurveyLayer(config: IMapSurveyVector, zIndex: number, map: MapCls | ImageCls, crs: number = 3857): number {
    const selectedLayers = config.layers.filter((layer) => layer.selectedInModal);
    if (config.combineSubLayers && selectedLayers.length > 1) {
      const categories = [];
      selectedLayers.forEach((layer) => {
        categories.push(`'${layer.interaction}'`);
      });

      const cqlFilters: ICQLFilter[] = [
        { property: 'survey_id', operator: '=', value: `'${config.survey}'` },
        {
          property: 'category_value',
          operator: ' IN ',
          value: `(${categories.join(',')})`,
        },
      ];

      if (config.styleSource === 'rule-based') {
        config.styleConditions.forEach((condition) => {
          const layerToAdd = map.addWFS(config, config.styleSource, config, condition, cqlFilters, zIndex, crs);
          map.map.addLayer(layerToAdd);
          zIndex--;
        });
      } else {
        const layerToAdd = map.addWFS(config, config.styleSource, config, null, cqlFilters, zIndex, crs);
        map.map.addLayer(layerToAdd);
        zIndex--;
      }
    } else {
      selectedLayers.forEach((layer) => {
        const cqlFilters: ICQLFilter[] = [
          { property: 'survey_id', operator: '=', value: `'${config.survey}'` },
          {
            property: 'category_value',
            operator: '=',
            value: `'${layer.interaction}'`,
          },
        ];

        if (layer.styleSource === 'rule-based') {
          layer.styleConditions.forEach((condition) => {
            const layerToAdd = map.addWFS(config, layer.styleSource, layer, condition, cqlFilters, zIndex, crs);
            map.map.addLayer(layerToAdd);
            zIndex--;
          });
        } else {
          const layerToAdd = map.addWFS(config, layer.styleSource, layer, null, cqlFilters, zIndex, crs);
          map.map.addLayer(layerToAdd);
          zIndex--;
        }
      });
    }

    return zIndex;
  }

  public createUploadLayer(dataLayer: IMapUploadVector | ILayerDataset): FormGroup<IMapUploadVector> {
    const uploadLayer = new FormGroup<IMapUploadVector>({
      _id: new FormControl(dataLayer._id),
      id: new FormControl(
        dataLayer && (dataLayer as IMapUploadVector).id
          ? (dataLayer as IMapUploadVector).id
          : 'UPLOADLAYER' + randomIdGenerator(10)
      ),
      name: new FormControl<string>(dataLayer?.name),
      title: new FormArray(
        (dataLayer as IMapUploadVector).title
          ? (dataLayer as IMapUploadVector).title.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl(dataLayer.name),
              });
            })
      ),
      helptext: new FormArray(
        (dataLayer as IMapUploadVector).helptext
          ? (dataLayer as IMapUploadVector).helptext.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl<string>(),
              });
            })
      ),
      selectedInMap: new FormControl((dataLayer as IMapUploadVector).selectedInMap ?? true),
      minZoom: new FormControl((dataLayer as IMapUploadVector).minZoom ?? 0),
      maxZoom: new FormControl((dataLayer as IMapUploadVector).maxZoom ?? 22),
      showOnLegend: new FormControl((dataLayer as IMapUploadVector).showOnLegend ?? true),
      isSelectable: new FormControl((dataLayer as IMapUploadVector).isSelectable ?? true),
      type: new FormControl('UPLOAD'),
      crs: new FormControl<number>((dataLayer as IMapUploadVector).crs),
      geomType: new FormControl<Type>((dataLayer as IMapUploadVector).geomType),
      url: new FormControl((dataLayer as IMapUploadVector).url ?? `${this.environment.geoserver}wfs`),
      typeName: new FormControl<string>((dataLayer as IMapUploadVector).typeName),
      styleSource: new FormControl<MapStyleSource>((dataLayer as IMapUploadVector).styleSource ?? 'regular'),
      styleConditions: new FormArray<IStyleCondition>(
        (dataLayer as IMapUploadVector).styleConditions && (dataLayer as IMapUploadVector).styleConditions.length
          ? (dataLayer as IMapUploadVector).styleConditions.map((sc) => {
              const fg = this.getDefaultStyling();
              fg.patchValue(sc);
              const rules = fg.getControl('rules') as FormArray<IRelation>;
              sc.rules.forEach((rule) => {
                const fgRule = new FormGroup<IRelation>({
                  type: new FormControl<ConditionType>(rule.type),
                  order: new FormControl(rule.order),
                  questionid: new FormControl<string>(rule.questionid),
                  subquestionid: new FormControl<string>(rule.subquestionid),
                  transportMode: new FormControl<string>(rule.transportMode),
                  value: new FormControl(rule.value),
                  operator: new FormControl<string>(rule.operator),
                  groupOperator: new FormControl<GroupOperator>(rule.groupOperator),
                });

                rules.push(fgRule);
              });
              return fg;
            })
          : [this.getDefaultStyling()]
      ),
    });

    // if (dataLayer) {
    //   uploadLayer.patchValue(dataLayer, { emitEvent: false });
    // }

    return uploadLayer;
  }

  public createSurveyLayer(
    mapType: MapType,
    survey?: ISurvey,
    dataLayer?: IMapSurveyVector
  ): FormGroup<IMapSurveyVector> {
    // Declare the new layer formgroup
    const surveyLayer = new FormGroup<IMapSurveyVector>({
      id: new FormControl(dataLayer ? dataLayer.id : 'SURVEYLAYER' + randomIdGenerator(10)),
      title: new FormArray(
        dataLayer
          ? dataLayer.title.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl('New data layer'),
              });
            })
      ),
      helptext: new FormArray(
        dataLayer && dataLayer.helptext
          ? dataLayer.helptext.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl<string>(),
              });
            })
      ),
      selectedInMap: new FormControl(dataLayer ? dataLayer.selectedInMap : true),
      minZoom: new FormControl(dataLayer ? dataLayer.minZoom : 0),
      maxZoom: new FormControl(dataLayer ? dataLayer.maxZoom : 22),
      showOnLegend: new FormControl(dataLayer ? dataLayer.showOnLegend : true),
      isSelectable: new FormControl(dataLayer ? dataLayer.isSelectable : true),
      type: new FormControl('SURVEY'),
      geomType: new FormControl<Type>(dataLayer ? dataLayer.geomType : 'Point'),
      lineType: new FormControl<LineType>(dataLayer ? dataLayer.lineType : 'regular'),
      survey: new FormControl<ObjectId>(survey ? survey._id : dataLayer ? dataLayer.survey : null),
      url: new FormControl(dataLayer ? dataLayer.url : `${this.environment.geoserver}wfs`),
      typeName: new FormControl(
        dataLayer ? dataLayer.typeName : `${this.environment.geoserverDb}:${mapType.toLowerCase()}_interactions_vw`
      ),
      layers: new FormArray<IMapSurveyLayer>([]),
      combineSubLayers: new FormControl(dataLayer ? dataLayer.combineSubLayers : false),
      styleSource: new FormControl<MapStyleSource>(dataLayer ? dataLayer.styleSource : 'regular'),
      styleConditions: new FormArray<IStyleCondition>(
        dataLayer && dataLayer.styleConditions && dataLayer.styleConditions.length
          ? dataLayer.styleConditions.map((sc) => {
              const fg = this.getDefaultStyling();
              fg.patchValue(sc);
              const rules = fg.getControl('rules') as FormArray<IRelation>;
              sc.rules.forEach((rule) => {
                const fgRule = new FormGroup<IRelation>({
                  type: new FormControl<ConditionType>(rule.type),
                  order: new FormControl(rule.order),
                  questionid: new FormControl<string>(rule.questionid),
                  subquestionid: new FormControl<string>(rule.subquestionid),
                  transportMode: new FormControl<string>(rule.transportMode),
                  value: new FormControl(rule.value),
                  operator: new FormControl<string>(rule.operator),
                  groupOperator: new FormControl<GroupOperator>(rule.groupOperator),
                });

                rules.push(fgRule);
              });
              return fg;
            })
          : [this.getDefaultStyling()]
      ),
    });

    this.setLayerInteractions(surveyLayer, mapType, survey, dataLayer);

    return surveyLayer;
  }

  public setLayerInteractions(
    surveyLayer: FormGroup<IMapSurveyVector>,
    mapType: MapType,
    survey?: ISurvey,
    dataLayer?: IMapSurveyVector
  ) {
    surveyLayer.controls.layers = new FormArray<IMapSurveyLayer>([]);

    if (dataLayer && dataLayer.layers) {
      dataLayer.layers.forEach((layer) => {
        const fgLayer = new FormGroup<IMapSurveyLayer>({
          id: new FormControl<string>(layer.id),
          interaction: new FormControl<string>(layer.interaction),
          parent: new FormControl<number>(layer.parent),
          geomType: new FormControl<Type>(layer.geomType),
          lineType: new FormControl<LineType>(layer.lineType),
          title: new FormArray<I18n>(
            layer.title
              ? layer.title.map((language) => {
                  return new FormGroup<I18n>({
                    i18n: new FormControl(language.i18n),
                    value: new FormControl(language.value),
                  });
                })
              : this.languages.map((language) => {
                  return new FormGroup<I18n>({
                    i18n: new FormControl(language),
                    value: new FormControl(''),
                  });
                })
          ),
          helptext: new FormArray<I18n>(
            layer.helptext
              ? layer.helptext.map((language) => {
                  return new FormGroup<I18n>({
                    i18n: new FormControl(language.i18n),
                    value: new FormControl(language.value),
                  });
                })
              : this.languages.map((language) => {
                  return new FormGroup<I18n>({
                    i18n: new FormControl(language),
                    value: new FormControl(''),
                  });
                })
          ),
          selectedInMap: new FormControl(layer.selectedInMap),
          selectedInModal: new FormControl(layer.selectedInModal),
          styleSource: new FormControl<MapStyleSource>(layer.styleSource),
          styleConditions: new FormArray<IStyleCondition>(
            layer.styleConditions.map((sc) => {
              const fg = this.getDefaultStyling();
              fg.patchValue(sc);
              const rules = fg.getControl('rules') as FormArray<IRelation>;
              sc.rules.forEach((rule) => {
                const fgRule = new FormGroup<IRelation>({
                  type: new FormControl<ConditionType>(rule.type),
                  order: new FormControl(rule.order),
                  questionid: new FormControl<string>(rule.questionid),
                  subquestionid: new FormControl<string>(rule.subquestionid),
                  transportMode: new FormControl<string>(rule.transportMode),
                  value: new FormControl(rule.value),
                  operator: new FormControl<string>(rule.operator),
                  groupOperator: new FormControl<GroupOperator>(rule.groupOperator),
                });

                rules.push(fgRule);
              });
              return fg;
            })
          ),
        });

        fgLayer.setParent(surveyLayer.get('layers') as FormArray<IMapSurveyLayer>);
        surveyLayer.get('layers').setParent(surveyLayer);

        (surveyLayer.get('layers') as FormArray<IMapSurveyLayer>).controls.push(fgLayer);
      });

      return;
    }

    // Add all interactions of the survey to the formGroup
    const pages: ISurveyMap[] | ISurveyImage[] =
      mapType === 'MAP' ? survey.questions.mapInteractions : survey.questions.imageInteractions;
    pages.forEach((mapPage: ISurveyMap | ISurveyImage) => {
      mapPage.categories
        .filter((c) => !c._hidden)
        .forEach((interaction) => {
          const fgLayer = new FormGroup<IMapSurveyLayer>({
            id: new FormControl<string>('LAYER' + randomIdGenerator(10)),
            interaction: new FormControl<string>(interaction.value),
            parent: new FormControl<number>(mapPage.id),
            geomType: new FormControl<Type>(interaction.drawOptions.geomType),
            lineType: new FormControl<LineType>(interaction.drawOptions.lineType),
            title: new FormArray<I18n>(
              this.languages.map((language) => {
                let interactionLabel = interaction.label.find((l) => l.i18n === language);
                if (!interactionLabel || !interactionLabel.value) {
                  interactionLabel = interaction.label.find((l) => Boolean(l.value));
                }
                return new FormGroup<I18n>({
                  i18n: new FormControl<string>(language),
                  value: new FormControl<string>(interactionLabel ? interactionLabel.value : ''),
                });
              })
            ),
            helptext: new FormArray<I18n>(
              this.languages.map((language) => {
                return new FormGroup<I18n>({
                  i18n: new FormControl<string>(language),
                  value: new FormControl<string>(),
                });
              })
            ),
            selectedInMap: new FormControl(true),
            selectedInModal: new FormControl(false),
            styleSource: new FormControl<MapStyleSource>('regular'),
            styleConditions: new FormArray<IStyleCondition>(
              dataLayer && dataLayer.layers && dataLayer.layers.find((layer) => layer.interaction === interaction.value)
                ? dataLayer.layers
                    .find((layer) => layer.interaction === interaction.value)
                    .styleConditions.map((sc) => {
                      const fg = this.getDefaultStyling(interaction);
                      fg.patchValue(sc);
                      const rules = fg.getControl('rules') as FormArray<IRelation>;
                      sc.rules.forEach((rule) => {
                        const fgRule = new FormGroup<IRelation>({
                          type: new FormControl<ConditionType>(rule.type),
                          order: new FormControl(rule.order),
                          questionid: new FormControl<string>(rule.questionid),
                          subquestionid: new FormControl<string>(rule.subquestionid),
                          transportMode: new FormControl<string>(rule.transportMode),
                          value: new FormControl(rule.value),
                          operator: new FormControl<string>(rule.operator),
                          groupOperator: new FormControl<GroupOperator>(rule.groupOperator),
                        });

                        rules.push(fgRule);
                      });
                      return fg;
                    })
                : [this.getDefaultStyling(interaction)]
            ),
            _archived: new FormControl(Boolean(mapPage._archived || interaction._archived)),
          });

          fgLayer.setParent(surveyLayer.get('layers') as FormArray<IMapSurveyLayer>);
          surveyLayer.get('layers').setParent(surveyLayer);

          (surveyLayer.get('layers') as FormArray<IMapSurveyLayer>).controls.push(fgLayer);
        });
    });
  }

  public getDefaultStyling(interaction?: IMapInteraction): FormGroup<IStyleCondition> {
    return new FormGroup<IStyleCondition>({
      id: new FormControl('STYLECONDITION' + randomIdGenerator(10)),
      selectedInMap: new FormControl(true),
      title: new FormArray(
        this.languages.map((language) => {
          return new FormGroup<I18n>({
            i18n: new FormControl(language),
            value: new FormControl(
              interaction
                ? interaction.label.find((label) => label.i18n === language) &&
                  interaction.label.find((label) => label.i18n === language).value
                : 'New rule'
            ),
          });
        })
      ),
      rules: new FormArray<IRelation>([]),
      style: new FormGroup<IStyle>({
        pointStyle: new FormControl<PointType>(
          interaction && interaction.hiddenType !== 'destination' ? interaction.style.pointStyle : 'regular'
        ),
        icon: new FormControl<string>(
          interaction && interaction.hiddenType !== 'destination' ? interaction.style.icon : null
        ),
        iconScale: new FormControl(interaction ? interaction.style.iconScale : 1),
        iconAnchor: new FormControl(interaction ? interaction.style.iconAnchor : [0.5, 0.5]),
        iconBackground: new FormGroup<IIconBackground>({
          type: new FormControl<IconBackgroundType>(
            interaction &&
            interaction.style &&
            interaction.style.iconBackground &&
            interaction.hiddenType !== 'destination'
              ? interaction.style.iconBackground.type
              : null
          ),
          color: new FormControl(
            interaction && interaction.style && interaction.style.iconBackground
              ? interaction.style.iconBackground.color
              : THEME_COLORS.GENERIC.PRIMARY
          ),
          circleRadius: new FormControl(
            interaction && interaction.style && interaction.style.iconBackground
              ? interaction.style.iconBackground.circleRadius
              : 14
          ),
          circleStrokeColor: new FormControl(
            interaction && interaction.style && interaction.style.iconBackground
              ? interaction.style.iconBackground.circleStrokeColor
              : '#FFFFFF'
          ),
          circleStrokeWidth: new FormControl(
            interaction && interaction.style && interaction.style.iconBackground
              ? interaction.style.iconBackground.circleStrokeWidth
              : 2
          ),
          markerScale: new FormControl(
            interaction && interaction.style && interaction.style.iconBackground
              ? interaction.style.iconBackground.markerScale
              : 1
          ),
          iconColor: new FormControl(
            interaction && interaction.style && interaction.style.iconBackground
              ? interaction.style.iconBackground.iconColor
              : '#FFFFFF'
          ),
        }),
        markerScale: new FormControl(interaction ? interaction.style.markerScale : 1),
        color: new FormControl(
          interaction && interaction.hiddenType === 'destination'
            ? interaction.style.iconBackground.color
            : interaction
            ? interaction.style.color
            : THEME_COLORS.GENERIC.PRIMARY
        ),
        strokeWidth: new FormControl(interaction ? interaction.style.strokeWidth : 4),
        fillColor: new FormControl<string>(
          interaction ? interaction.style.fillColor : Color(THEME_COLORS.GENERIC.PRIMARY).alpha(0.2).string()
        ),
        lineStyle: new FormControl<LineStyle>(interaction ? interaction.style.lineStyle : 'regular'),
        dashStyle: new FormControl<DashStyle>(interaction ? interaction.style.dashStyle : 'regular'),
        arrowStyle: new FormControl<ArrowStyle>(interaction ? interaction.style.arrowStyle : 'regular'),
        clusterDistance: new FormControl(50),
        clusterSizeFactor: new FormControl(1),
        heatmapBlurRadius: new FormControl(8),
        heatmapBlurSize: new FormControl(15),
        circleRadius: new FormControl(interaction ? interaction.style.circleRadius : 9),
        circleStrokeColor: new FormControl(interaction ? interaction.style.circleStrokeColor : '#FFFFFF'),
        circleStrokeWidth: new FormControl(interaction ? interaction.style.circleStrokeWidth : 2),
        lineBorderColor: new FormControl(interaction ? interaction.style.lineBorderColor : '#FFFFFF'),
        lineBorderWidth: new FormControl(interaction ? interaction.style.lineBorderWidth : 1),
      }),
      label: new FormGroup<ILabelOptions>({
        field: new FormControl<string>('NO_LABELS'),
        alignment: new FormControl('center'),
        textColor: new FormControl('#243039'),
        showHalo: new FormControl(true),
        haloColor: new FormControl('#FFFFFF'),
        offsetX: new FormControl(0),
        offsetY: new FormControl(15),
        fontSize: new FormControl(12),
        minZoom: new FormControl(0),
        maxZoom: new FormControl(22),
      }),
    });
  }

  public createWMSLayer(dataLayer?: IMapWMS): FormGroup<IMapWMS> {
    const wmsLayer = new FormGroup<IMapWMS>({
      _id: new FormControl(dataLayer && dataLayer._id),
      id: new FormControl('WMS' + randomIdGenerator(10)),
      name: new FormControl<string>(dataLayer && dataLayer.name),
      url: new FormControl<string>(dataLayer && dataLayer.url),
      layers: new FormArray([]),
      title: new FormArray(
        dataLayer.title
          ? dataLayer.title.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl(dataLayer.name),
              });
            })
      ),
      helptext: new FormArray(
        dataLayer.helptext
          ? dataLayer.helptext.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl<string>(),
              });
            })
      ),
      selectedInMap: new FormControl(
        dataLayer && dataLayer.selectedInMap !== undefined ? dataLayer.selectedInMap : true
      ),
      minZoom: new FormControl(dataLayer && dataLayer.minZoom ? dataLayer.minZoom : 0),
      maxZoom: new FormControl(dataLayer && dataLayer.maxZoom ? dataLayer.maxZoom : 22),
      type: new FormControl('WMS'),
      opacity: new FormControl(dataLayer && dataLayer.opacity !== undefined ? dataLayer.opacity : 100),
      showOnLegend: new FormControl(dataLayer && dataLayer.showOnLegend !== undefined ? dataLayer.showOnLegend : true),
    });

    if (!dataLayer || !dataLayer.layers) {
      return wmsLayer;
    }

    dataLayer.layers.forEach((layerInData: IMapWMSLayer) => {
      const fgLayer = new FormGroup<IMapWMSLayer>({
        id: new FormControl(layerInData.id),
        title: new FormControl(layerInData.title),
        name: new FormControl(layerInData.name),
        parent: new FormControl(layerInData.parent),
        selectedInModal: new FormControl(layerInData.selectedInModal),
        selectedInMap: new FormControl(layerInData.selectedInMap),
      });

      fgLayer.setParent(wmsLayer.get('layers') as FormArray<IMapWMSLayer>);
      wmsLayer.get('layers').setParent(wmsLayer);

      (wmsLayer.get('layers') as FormArray<IMapWMSLayer>).push(fgLayer);
    });

    return wmsLayer;
  }

  public getWmsLayers(url: string, fg: FormGroup<IMapWMS>, data?: IMapWMS) {
    return new Promise<void>((resolve, reject) => {
      this.wmsLoading = true;
      this.wmsError = false;
      const parser = new WmsCapabilites();
      fg.controls.layers = new FormArray([]);
      url = url.split('?')[0].trim();
      fg.getFormControl('url').setValue(url, { emitEvent: false });
      url = url + '?service=WMS&request=GetCapabilities';
      this.api.map
        .getWmsProxy(url)
        .pipe(first())
        .subscribe(
          async (xmlResponse: string | Document) => {
            try {
              const capabilities: IWMSCapabilities = parser.read(xmlResponse);
              this.findWMSLayers(fg, capabilities.Capability.Layer, data);
              resolve();
            } catch (error) {
              this.wmsError = true;
              reject(error);
            }
          },
          (error) => {
            this.wmsError = true;
            reject(error);
          }
        )
        .add(() => {
          this.wmsLoading = false;
        });
    });
  }

  public findWMSLayers(fg: FormGroup<IMapWMS>, layer: ICapabilityLayer, data?: IMapWMS, parentTitle?: string) {
    if (!Array.isArray(layer.Layer)) {
      const layerInData = data && data.layers ? data.layers.find((wmsLayer) => wmsLayer.name === layer.Name) : null;
      const fgLayer = new FormGroup<IMapWMSLayer>({
        id: new FormControl(layerInData ? layerInData.id : 'WMS' + randomIdGenerator()),
        title: new FormControl(layerInData ? layerInData.title : layer.Title),
        name: new FormControl(layerInData ? layerInData.name : layer.Name),
        parent: new FormControl(layerInData ? layerInData.parent : parentTitle),
        selectedInModal: new FormControl(layerInData ? layerInData.selectedInModal : false),
        selectedInMap: new FormControl(layerInData ? layerInData.selectedInMap : true),
      });

      fgLayer.setParent(fg.get('layers') as FormArray<IMapWMSLayer>);
      fg.get('layers').setParent(fg);

      (fg.get('layers') as FormArray<IMapWMSLayer>).push(fgLayer);
    } else {
      layer.Layer.forEach((styleCondition) => {
        this.findWMSLayers(fg, styleCondition, data, layer.Title ? layer.Title : 'WMS');
      });
    }
  }

  public resetDataLoaded() {
    this.dataLoaded.next(false);
  }

  public destroyMap() {
    this.mapDestroyed.next();
  }

  public getMapById(id: ObjectId): Promise<IMapResult> {
    return new Promise((res, rej) => {
      this.api.map.getMapById(id).subscribe(
        (resp) => {
          res(resp);
        },
        (error) => {
          this.api.errorhandler(error);
          rej();
        }
      );
    });
  }

  public onSetCenter(center: number[], zoom?: number, map: MapClsAnalytics | ImageClsAnalytics = this.map) {
    if (zoom) {
      map.map.getView().animate({ center: center, zoom: zoom, duration: 250 });
    } else {
      map.map.getView().animate({ center: center, duration: 250 });
    }
  }

  public initMapForm(
    mapType: MapType = MapType.MAP,
    activeMap?: IMapResult,
    image?: string,
    imageExtent?: Extent,
    options?: { viewer: boolean }
  ): void {
    this.dataLoaded$.pipe(filter<boolean>(Boolean), take(1), delay(0)).subscribe(() => {
      if (!this.mapForm) {
        return;
      }
      this.onLoadLayers(this.map, this.mapForm.getRawValue(), mapType);
    });

    this.mapForm = this.cleanMapForm(mapType, activeMap, image, imageExtent, options) as FormGroup<IMapResult>;

    if (activeMap && this.map && mapType === 'MAP') {
      (this.map as MapClsAnalytics).setCenter(activeMap.center);
      (this.map as MapClsAnalytics).setZoom(activeMap.zoom);
    }
  }

  public cleanMapForm(
    mapType?: MapType,
    activeMap?: IMapResult | ISurveyMap | ISurveyImage,
    image?: string,
    imageExtent?: Extent,
    options?: { viewer: boolean }
  ): FormGroup<IMapResult | ISurveyMap | ISurveyImage> {
    this.surveyCache = [];

    if (!mapType) {
      return;
    }

    const result = new FormGroup<IMapResult | ISurveyMap | ISurveyImage>({
      _id: new FormControl<string>(activeMap ? (activeMap as IMapResult)._id : null),
      survey: new FormControl<string>(this.surveyForImage ? this.surveyForImage._id : null),
      organisation: new FormControl<string>(
        this.workspaceService.getActiveWorkspace() ? this.workspaceService.getActiveWorkspace()._id : null
      ),
      type: new FormControl(mapType),
      published: new FormControl<boolean>(activeMap ? (activeMap as IMapResult).published : null),
      languages: new FormControl<ILanguage[]>(),
      defaultLanguage: new FormControl<ILanguage>(),
      baseLayer: new FormControl<string>(mapType === 'MAP' ? 'mapbox-basic' : 'base-image'),
      dataLayers: new FormArray<IMapDataLayer>([]),
      title: new FormArray<I18n>(
        activeMap
          ? (activeMap as IMapResult).title.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl<string>(),
              });
            })
      ),
      subtitle: new FormArray<I18n>(
        activeMap
          ? (activeMap as IMapResult).subtitle.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language.i18n),
                value: new FormControl(language.value),
              });
            })
          : this.languages.map((language) => {
              return new FormGroup<I18n>({
                i18n: new FormControl(language),
                value: new FormControl<string>(),
              });
            })
      ),
      uri: new FormControl<string>(randomIdGenerator(20)),
      center: new FormControl<Coordinate>(this.workspaceService.getActiveWorkspace()?.mapOptions?.center ?? [0, 0]),
      zoom: new FormControl<number>(this.workspaceService.getActiveWorkspace()?.mapOptions?.zoom ?? 2),
      image: new FormControl(image),
      imageExtent: new FormControl(imageExtent),
      period: new FormControl(),
      qr: new FormControl(),
      thumbnail: new FormControl<string>(),
      options: new FormGroup<IMapOptions>({
        theme: new FormGroup<ITheme>({
          _id: new FormControl<string>(),
          image: new FormControl<string>(),
          colors: new FormGroup<IThemeColors>({
            primaryBg: new FormControl(THEME_COLORS.GENERIC.PRIMARY),
            primaryText: new FormControl('#FFFFFF'),
            accent: new FormControl(THEME_COLORS.GENERIC.SECONDARY),
          }),
          options: new FormGroup<IThemeOptions>({
            map: new FormGroup<IThemeMapOptions>({
              titleBar: new FormGroup<IThemeMapTitleBar>({
                show: new FormControl(true),
                position: new FormControl(TitleBarPosition.TOP),
              }),
              legend: new FormGroup<IThemeMapLegend>({
                show: new FormControl(true),
                collapseByDefault: new FormControl(true),
                style: new FormControl(LegendStyle.FIXED),
                position: new FormControl(LegendPosition.RIGHT),
              }),
              addressSearch: new FormGroup<IThemeMapAddressSearch>({
                show: new FormControl(true),
              }),
              baseLayerToggle: new FormGroup<IThemeMapBaseLayerToggle>({
                show: new FormControl(true),
              }),
            }),
          }),
        }),
        minZoom: new FormControl(2),
        maxZoom: new FormControl(22),
      }),
      _example: new FormControl(false),
      _paused: new FormControl(),
    });

    if (activeMap) {
      if ((activeMap as IMapResult).period && (activeMap as IMapResult).period.length) {
        (activeMap as IMapResult).period = (activeMap as IMapResult).period.map((d) => {
          return moment(d).toDate();
        });
      }
      result.patchValue(activeMap);
      this.patchLayers(result, activeMap, mapType, options ? options.viewer : false);
    }

    return result;
  }

  public getSurveyById(surveyId: string): Observable<ISurvey> {
    return this.api.surveys.getSurveyById(surveyId);
  }

  public getMapType() {
    return this.mapType;
  }

  public setMapType(mapType: MapType) {
    this.mapType = mapType;
  }

  public getImage() {
    return this.image;
  }

  public setImage(image: string) {
    this.image = image;
  }

  public getImageExtent() {
    return this.imageExtent;
  }

  public setImageExtent(imageExtent: Extent) {
    this.imageExtent = imageExtent;
  }

  public getActiveMap() {
    return this.activeMap;
  }

  public setActiveMap(activeMap: IMapResult) {
    this.activeMap = activeMap;
  }

  public getSurveyForImage() {
    return this.surveyForImage;
  }

  public setSurveyForImage(survey: ISurvey) {
    this.surveyForImage = survey;
  }

  public getQRCodes() {
    this.generatingQrCode = true;
    return this.api.map
      .getQRCodes(this.mapForm.getRawValue()._id)
      .toPromise()
      .then((qrCodes) => {
        if (!this.mapForm) {
          return;
        }
        this.mapForm.get('qr').setValue(qrCodes, { emitEvent: false });

        this.generatingQrCode = false;
      });
  }

  public getSurvey(id: string) {
    return this.api.surveys.getSurveyById(id).toPromise();
  }
}
