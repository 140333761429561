import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IMapSurveyLayer, IMapSurveyVector, IStyleCondition, ISurvey, MapType } from '@maplix/utils';
import { ColorEvent } from 'ngx-color';
import { FormGroup } from '@maplix/forms';
import { Subject } from 'rxjs';
import { Type } from 'ol/geom/Geometry';

@Component({
  selector: 'maplix-vector-layer-style',
  templateUrl: './vector-layer-style.component.html',
  styleUrls: ['./vector-layer-style.component.scss'],
})
export class VectorLayerStyleComponent implements OnInit, OnDestroy {
  @Input()
  public dataLayer: FormGroup<IMapSurveyVector | IMapSurveyLayer>;

  @Input()
  public styleCondition: FormGroup<IStyleCondition>;

  @Input()
  public survey: ISurvey;

  @Input()
  public apiUrl: string;

  @Input()
  public language: string;

  @Input()
  public readonly mapType: MapType;

  @Input()
  public isUpload: boolean;

  public pointTypes = [
    { label: 'Circle', value: 'regular', icon: 'far fa-circle' },
    { label: 'Marker', value: 'marker', icon: 'remixicon-map-pin-2-line' },
    { label: 'Triangle', value: 'triangle', icon: 'mdi mdi-triangle-outline' },
    { label: 'Square', value: 'square', icon: 'mdi mdi-square-outline' },
    { label: 'Pentagon', value: 'pentagon', icon: 'mdi mdi-pentagon-outline' },
    { label: 'Hexagon', value: 'hexagon', icon: 'mdi mdi-hexagon-outline' },
    { label: 'Star', value: 'star', icon: 'mdi mdi-star-outline' },
    { label: 'Icon', value: 'icon', icon: 'remixicon-image-2-line' },
  ];

  public lineStyles = [
    { label: 'Default', value: 'regular', icon: 'fas fa-slash' },
    { label: 'Dashed', value: 'dashed', icon: 'remixicon-separator' },
    { label: 'Arrow', value: 'arrow', icon: 'remixicon-arrow-left-up-fill' },
  ];

  public dashStyles = [
    { label: 'Default', value: 'regular', icon: 'remixicon-separator' },
    { label: 'Short', value: 'short', icon: 'remixicon-separator' },
    { label: 'Long-short', value: 'long-short', icon: 'remixicon-separator' },
    { label: 'Short-long', value: 'short-long', icon: 'remixicon-separator' },
  ];

  public arrowStyles = [
    {
      label: 'End of line',
      value: 'regular',
      icon: 'remixicon-arrow-left-up-fill',
    },
    {
      label: 'Each segment',
      value: 'segment',
      icon: 'remixicon-arrow-left-up-fill',
    },
  ];

  public ruleEditor: boolean;

  private destroyed: Subject<void> = new Subject();

  public geometryType: Type | 'Route';

  constructor() {}

  ngOnInit() {
    if (this.dataLayer) {
      this.geometryType =
        this.dataLayer.getControl('geomType').value === 'LineString' &&
        this.dataLayer.get('lineType') &&
        this.dataLayer.get('lineType').value === 'Route'
          ? 'Route'
          : this.dataLayer.getControl('geomType').value;

      if (this.geometryType === 'Polygon' || this.isUpload) {
        this.lineStyles = this.lineStyles.filter((style) => style.value !== 'arrow');
      }
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public onChangeColor(property: string, $event: ColorEvent) {
    this.styleCondition
      .get(`style.${property}`)
      .setValue(`rgba(${$event.color.rgb.r}, ${$event.color.rgb.g}, ${$event.color.rgb.b}, ${$event.color.rgb.a})`);
    this.styleCondition.get('style').markAsDirty();
  }

  public onSetIcon(url: string) {
    this.styleCondition.get('style.icon').setValue(url);
    this.styleCondition.get('style').markAsDirty();
  }

  public onEditConditions() {
    this.ruleEditor = true;
  }

  public getInteractions(dataLayer: FormGroup<IMapSurveyVector | IMapSurveyLayer>): string[] {
    if ('interaction' in dataLayer.getRawValue()) {
      return [(dataLayer as FormGroup<IMapSurveyLayer>).getControl('interaction').value];
    }

    return (dataLayer as FormGroup<IMapSurveyVector>)
      .getRawValue()
      .layers.filter((layer) => layer.selectedInModal)
      .map((layer) => layer.interaction);
  }

  public onChangeAnchor(anchor: number[]) {
    this.styleCondition.get('style.iconAnchor').setValue(anchor);
    this.styleCondition.get('style').markAsDirty();
  }
}
