import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { IUploadLayerDataset } from '@maplix/cloud';
import { FormGroup, FormControl } from '@maplix/forms';
import { WorkspaceService } from '@maplix/services';
import {
  FileUploadHandler,
  IUploadResponse,
} from '@maplix/utils';
import { first } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'maplix-create-upload-layer',
  templateUrl: './create-upload-layer.component.html',
  styleUrls: ['./create-upload-layer.component.scss'],
})
export class CreateUploadLayerComponent implements OnInit {
  @Input()
  public apiUrl: string;

  @Input()
  public geoserverUrl: string;

  @Output()
  public formValidityChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public formValueChange: EventEmitter<IUploadLayerDataset> = new EventEmitter();

  @ViewChild('filePicker', { static: false })
  public picker: ElementRef;

  public selectedFiles: File[];

  public selectedFile: File;

  public error: string;

  public uploadStatus: string;

  public form: FormGroup<IUploadLayerDataset>;

  public uploadHandler: FileUploadHandler;

  private destroyed: Subject<void> = new Subject();

  constructor(private http: HttpClient, private workspaceService: WorkspaceService) {}

  ngOnInit() {}

  public onOpenFilePicker(): void {
    this.picker.nativeElement.click();
  }

  public async onUploadFile(args): Promise<void> {
    this.form = null;
    this.uploadStatus = 'uploading';
    const maxAllowedSize = 25 * 1024 * 1024; // up to 25MB allowed
    if (!(args.target as HTMLInputElement).files.length) return;
    const newUploads: File[] = Array.from(
      (args.target as HTMLInputElement).files
    );

    this.selectedFile = first(newUploads);

    for (let upload of newUploads) {
      this.uploadHandler = new FileUploadHandler(
        { file: upload, maxSize: maxAllowedSize, apiUrl: this.apiUrl },
        this.http
      );

      const response = await this.uploadHandler.upload().catch((msg) => {
        this.error = msg;
        this.uploadStatus = 'error';
        return null;
      });

      if (!response) {
        return;
      }

      this.uploadStatus = 'success';

      this.onCreateDataset(response);
    }
  }

  private onCreateDataset(upload: IUploadResponse) {
    this.form = new FormGroup<IUploadLayerDataset>({
      type: new FormControl('UPLOAD'),
      name: new FormControl<string>(this.selectedFile.name ? this.selectedFile.name.split('.')[0] : null, Validators.required),
      url: new FormControl<string>(`${this.geoserverUrl}wfs`),
      fileUrl: new FormControl<string>(upload.url, Validators.required),
      status: new FormControl<string>('PENDING'),
      organisation: new FormControl<string>(this.workspaceService.getActiveWorkspace()._id),
      creator: new FormControl<string>(this.workspaceService.getUserDetails()._id)
    });

    this.form.valid$
      .pipe(
        tap((valid: boolean) => {
          this.formValidityChange.emit(valid);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();

    this.form.value$
      .pipe(
        filter(() => this.form.valid),
        tap((value: IUploadLayerDataset) => {
          this.formValueChange.emit(value);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public formatSize(size: number) {
    if (size < 1024) {
      return size + ' B';
    }

    if (size < 1024 * 1024) {
      return (Math.round((size/1024) * 10) / 10) + ' KB';
    }

    if (size < 1024 * 1024 * 1024) {
      return (Math.round((size/1024/1024) * 10) / 10) + ' MB';
    }

    if (size < 1024 * 1024 * 1024 * 1024) {
      return (Math.round((size/1024/1024/1024) * 10) / 10) + ' GB';
    }
  }
}
