<div class="d-flex align-items-center no-wrap">
  <div class="d-inline-block">
    <ng-container *ngIf="multiple">
      <ng-container *ngFor="let myImage of files">
        <ng-template [ngTemplateOutlet]="imageTmpl" [ngTemplateOutletContext]="{ img: myImage }"></ng-template>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!multiple && file">
      <ng-template [ngTemplateOutlet]="imageTmpl" [ngTemplateOutletContext]="{ img: file }"></ng-template>
    </ng-container>

    <ng-template [ngTemplateOutlet]="uploadToggleTmpl" *ngIf="!disabled && (multiple || !file)"></ng-template>
  </div>

  <div class="d-inline-block">
    <div class="d-flex flex-column" *ngIf="file">
      <button
        class="btn btn-sm btn-light mb-1"
        (click)="onChangeFile()"
        ngbTooltip="Change"
        placement="right"
        [disabled]="disabled"
      >
        <i class="remixicon-repeat-2-fill"></i>
      </button>
      <button
        class="btn btn-sm btn-light"
        *ngIf="file && showRemove"
        (click)="onRemoveFile()"
        ngbTooltip="Remove"
        placement="right"
        [disabled]="disabled"
      >
        <i class="remixicon-delete-bin-6-line"></i>
      </button>
    </div>
  </div>
  <input
    type="file"
    #filePicker
    class="hidden-picker"
    [multiple]="multiple"
    [(ngModel)]="selectedFiles"
    (change)="onUploadFile($event)"
    [accept]="type === 'image' ? 'image/*' : 'application/pdf'"
  />
</div>
<div class="small text-danger mt-2" *ngIf="error">{{ error }}</div>

<ng-template #uploadToggleTmpl>
  <div [class]="'icon-preview bg-light clickable position-relative upload-' + size" (click)="onOpenFileSelector()">
    <div class="d-flex justify-content-center align-items-center h-100">
      <i class="mdi mdi-image-plus font-30" *ngIf="!uploading && type === 'image'"></i>
      <i class="mdi mdi-file-pdf font-30" *ngIf="!uploading && type === 'pdf'"></i>
      <i class="fas fa-circle-notch fa-spin font-30" *ngIf="uploading"></i>
    </div>
    <div class="image-overlay"></div>
  </div>
</ng-template>

<ng-template #imageTmpl let-img="img" let-multiple="multiple">
  <div class="position-relative card mr-2 mb-0 bg-light">
    <img [src]="img" alt="icon" [class]="'icon-preview upload-' + size" />

    <div class="thumbnail-overlay position-absolute" *ngIf="multiple">
      <div class="d-flex align-items-center justify-content-end px-1">
        <i class="remixicon-close-circle-fill font-20 text-black clickable" (click)="onRemoveFile(img)"></i>
      </div>
    </div>
  </div>
</ng-template>
