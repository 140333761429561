<div class="card ol-popup" [id]="'popup-select-' + mapId">
  <div class="card-header">
    <div class="h5 my-0">{{ popupInfo?.title }}</div>
  </div>
  <div class="card-body p-2 overflow-auto popup-animated perfect-scroll">
    <ng-container *ngIf="!popupInfo || !popupInfo.features || !popupInfo.features.length">
      <div class="p-5 d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-primary" role="status"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="popupInfo?.features?.length > 1">
      <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel [id]="feature.accordionId" *ngFor="let feature of popupInfo?.features">
          <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="text-left">
                <i
                  class="fas mr-3 fa-fw"
                  [class.fa-angle-down]="acc.isExpanded(feature.accordionId)"
                  [class.fa-angle-right]="!acc.isExpanded(feature.accordionId)"
                ></i>
                {{ feature.accordionLabel }}
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <ng-template [ngTemplateOutlet]="featureInfo" [ngTemplateOutletContext]="{ feature: feature }">
            </ng-template>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>
    <ng-container *ngIf="popupInfo?.features?.length === 1">
      <ng-container *ngFor="let feature of popupInfo?.features">
        <ng-template [ngTemplateOutlet]="featureInfo" [ngTemplateOutletContext]="{ feature: feature }"></ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #featureInfo let-feature="feature">
  <div
    class="row no-gutters mb-2 align-items-stretch"
    *ngIf="feature.length || feature.area || feature.coordinates || feature.transportMode; else noFeatureInfoTmpl"
  >
    <div
      [class.col-6]="feature.transportMode"
      [class.pr-2]="feature.transportMode"
      [class.col-12]="!feature.transportMode"
      *ngIf="feature.length as length"
    >
      <div class="element-container d-flex flex-column justify-content-center align-items-center h-100">
        <i class="fas fa-arrows-alt-h font-20"></i>
        {{ length }}
      </div>
    </div>
    <div class="col-12" *ngIf="feature.area as area">
      <div class="element-container d-flex flex-column justify-content-center align-items-center h-100">
        <i class="remixicon-crop-line font-20"></i>
        {{ area }}
      </div>
    </div>
    <div class="col-12" *ngIf="feature.coordinates as coordinates">
      <div class="element-container d-flex flex-column justify-content-center align-items-center h-100">
        <i class="remixicon-map-pin-line font-20"></i>
        <div>
          <span class="mx-1" *ngFor="let coord of coordinates">{{ coord }}</span>
        </div>
      </div>
    </div>
    <div class="col-6 pl-2" *ngIf="feature.transportMode as mode">
      <div class="element-container d-flex flex-column justify-content-center align-items-center h-100">
        <i class="remixicon-walk-line font-20" *ngIf="mode == 'FOOT'"></i>
        <i class="remixicon-riding-line font-20" *ngIf="mode == 'BIKE'"></i>
        <i class="remixicon-riding-line font-20" *ngIf="mode == 'EBIKE'"></i>
        <i class="remixicon-train-line font-20" *ngIf="mode == 'TRAIN'"></i>
        <i class="remixicon-bus-fill font-20" *ngIf="mode == 'PUBLIC'"></i>
        <i class="remixicon-car-fill font-20" *ngIf="mode == 'CAR'"></i>
        <i class="remixicon-motorbike-fill font-20" *ngIf="mode == 'MOTOR'"></i>
        <i class="remixicon-e-bike-2-fill font-20" *ngIf="mode == 'SCOOTER'"></i>
        <i class="mdi mdi-wheelchair-accessibility font-20" *ngIf="mode == 'WHEELCHAIR'"></i>
        <i class="remixicon-question-line font-20" *ngIf="mode == 'OTHER'"></i>
        {{ mode | map: translateTransportMode.bind(this) }}
      </div>
    </div>
  </div>

  <!-- Show answers to questions -->
  <ng-container *ngIf="feature.answers && (feature.answers | map: getKeys)?.length">
    <div
      class="element-container"
      [class.mb-2]="!isLastQuestion"
      *ngFor="let question of feature.answers | map: getKeys; last as isLastQuestion"
    >
      <ng-container *ngLet="feature.answers[question][0] as firstAnswer">
        <!-- Question label -->
        <label class="form-label d-block">
          <ng-template
            [ngTemplateOutlet]="questionIcon"
            [ngTemplateOutletContext]="{
              type: +firstAnswer.type
            }"
          >
          </ng-template>
          <span
            [innerHTML]="firstAnswer.question_alias ? firstAnswer.question_alias : firstAnswer.question_label"
          ></span>
        </label>

        <div class="row no-gutters align-items-stretch">
          <div
            [class.col-12]="+firstAnswer.type !== 8 || !firstAnswer.extra"
            [class.col-9]="+firstAnswer.type === 8 && firstAnswer.extra"
          >
            <ng-container *ngLet="feature.answers[question] | map: getSubquestions as subquestions">
              <ng-container *ngIf="subquestions?.length">
                <ng-container *ngFor="let sq of subquestions">
                  <p class="subquestion mb-1">{{ sq.label }}</p>
                  <ng-template
                    [ngTemplateOutlet]="answerTmpl"
                    [ngTemplateOutletContext]="{
                      answers: feature.answers[question],
                      subquestion: sq.id
                    }"
                  ></ng-template>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!subquestions?.length">
                <ng-template
                  [ngTemplateOutlet]="answerTmpl"
                  [ngTemplateOutletContext]="{
                    answers: feature.answers[question],
                    subquestion: null
                  }"
                ></ng-template>
              </ng-container>
            </ng-container>
          </div>
          <div
            class="col-3 d-flex flex-column align-items-center justify-content-center"
            *ngIf="+firstAnswer.type === 8 && firstAnswer.extra"
          >
            <i class="fe-arrow-up font-20"></i>
            <span class="small mb-2 mt-1 text-center">
              {{ firstAnswer.extra.maxLabel }}
            </span>
            <span class="small mt-2 mb-1 text-center">
              {{ firstAnswer.extra.minLabel }}
            </span>
            <i class="fe-arrow-down font-20"></i>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Properties from shape upload -->
  <ng-container *ngIf="!feature.answers && feature.properties && (feature.properties | map: getKeys)?.length">
    <div
      class="element-container"
      [class.mb-2]="!isLastProperty"
      *ngFor="let property of feature.properties | map: getKeys; last as isLastProperty"
    >
      <label class="form-label d-block">{{ property }}</label>
      <ng-container *ngIf="isLink(feature.properties[property])">
        <img
          [src]="feature.properties[property]"
          class="img-preview clickable"
          *ngIf="loadedImages.includes(feature.properties[property])"
          (click)="onOpenImage(feature.properties[property])"
        />
        <p *ngIf="!loadedImages.includes(feature.properties[property])" class="mb-0">
          <a [href]="feature.properties[property]" target="_blank">{{ feature.properties[property] }}</a>
        </p>
      </ng-container>
      <p *ngIf="!isLink(feature.properties[property])" class="mb-0">
        {{ feature.properties[property] }}
      </p>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (!feature.answers || !(feature.answers | map: getKeys)?.length) &&
      (!feature.properties || !(feature.properties | map: getKeys)?.length)
    "
  >
    <p class="text-muted mb-0">No data to display.</p>
  </ng-container>
</ng-template>

<ng-template #answerTmpl let-answers="answers" let-subquestion="subquestion">
  <!-- Loop through all answers -->
  <ng-container *ngFor="let answer of answers; first as isFirst; last as isLast">
    <ng-container *ngIf="!subquestion || subquestion === answer.subquestion">
      <ng-container [ngSwitch]="answer.type">
        <ng-container *ngSwitchCase="3">
          <maplix-popup-fieldtype-checkbox [answer]="answer"></maplix-popup-fieldtype-checkbox>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <maplix-popup-fieldtype-select [answer]="answer"></maplix-popup-fieldtype-select>
        </ng-container>
        <ng-container *ngSwitchCase="5">
          <div [class.mb-2]="!isLast">
            <maplix-popup-fieldtype-range [answer]="answer"></maplix-popup-fieldtype-range>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="6">
          <maplix-popup-fieldtype-file-upload
            [answer]="answer"
            (open)="onOpenImage($event)"
          ></maplix-popup-fieldtype-file-upload>
        </ng-container>
        <ng-container *ngSwitchCase="7">
          <maplix-popup-fieldtype-table [answer]="answer"></maplix-popup-fieldtype-table>
        </ng-container>
        <ng-container *ngSwitchCase="9">
          <div [class.mb-2]="!isLast">
            <maplix-popup-fieldtype-rating [answer]="answer"></maplix-popup-fieldtype-rating>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="12">
          <maplix-popup-fieldtype-date [answer]="answer" [language]="language"></maplix-popup-fieldtype-date>
        </ng-container>
        <ng-container *ngSwitchCase="14">
          <div [class.mb-2]="!isLast">
            <maplix-popup-fieldtype-budget [answer]="answer"></maplix-popup-fieldtype-budget>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="15">
          <maplix-popup-fieldtype-image-select
            [answer]="answer"
            (open)="onOpenImage($event)"
          ></maplix-popup-fieldtype-image-select>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <p [class.mb-0]="isLast" [class.mb-1]="!isLast">
            {{
              answer.value_label === 'OTHER' || answer.value_label === 'OPTOTHER'
                ? ('survey.OTHER' | translate)
                : answer.value_label
                ? answer.value_label
                : answer.value
            }}
            {{ answer.value_other ? ': ' + answer.value_other : '' }}
          </p>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #questionIcon let-type="type">
  <span class="mr-1">
    <ng-container *ngIf="type === 2">
      <span class="label-icon remixicon-radio-button-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 3">
      <span class="label-icon remixicon-checkbox-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 4">
      <span class="label-icon remixicon-align-bottom"></span>
    </ng-container>
    <ng-container *ngIf="type === 5">
      <span class="label-icon remixicon-equalizer-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 6">
      <span class="label-icon remixicon-upload-cloud-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 7">
      <span class="label-icon remixicon-table-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 8">
      <span class="label-icon remixicon-line-height"></span>
    </ng-container>
    <ng-container *ngIf="type === 9">
      <span class="label-icon remixicon-star-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 10">
      <span class="label-icon remixicon-text"></span>
    </ng-container>
    <ng-container *ngIf="type === 11">
      <span class="label-icon remixicon-font-size-2"></span>
    </ng-container>
    <ng-container *ngIf="type === 12">
      <span class="label-icon remixicon-calendar-event-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 13">
      <span class="label-icon remixicon-align-left"></span>
    </ng-container>
    <ng-container *ngIf="type === 14">
      <span class="label-icon remixicon-percent-line"></span>
    </ng-container>
    <ng-container *ngIf="type === 15">
      <span class="label-icon remixicon-image-2-line"></span>
    </ng-container>
  </span>
</ng-template>
