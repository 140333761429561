<div class="input-group">
  <div ngbDropdown container="body" [class]="hideCode ? '' : 'input-group-prepend'">
    <button class="btn form-control" [class.btn-light]="!hideCode" [class.btn-toolbar]="hideCode" ngbDropdownToggle>
      <div class="color-preview" [ngStyle]="{ 'background-color': color }"></div>
    </button>
    <div ngbDropdownMenu class="p-2">
      <maplix-color-dropdown
        [color]="color"
        [hideOpacity]="hideOpacity"
        (colorChange)="onEmitChange($event)"
      ></maplix-color-dropdown>
    </div>
  </div>
  <input type="text" class="form-control" [(ngModel)]="colorHex" (ngModelChange)="onChangeHex()" *ngIf="!hideCode" />
  <ng-container *ngIf="!hideOpacity">
    <div class="form-control small-control text-center" ngbTooltip="Opacity" placement="right" openDelay="500">
      <i class="remixicon-contrast-line"></i>
    </div>
    <div class="opacity-container">
      <input
        type="number"
        class="form-control input-group-append medium-control"
        [(ngModel)]="opacityPct"
        (ngModelChange)="onChangeHex()"
      />
      <div class="pct">%</div>
    </div>
  </ng-container>
</div>
