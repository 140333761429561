<ng-container [formGroup]="form">
  <div class="form-group">
    <div class="d-flex align-items-center">
      <label class="form-label">WMS URL</label>
      <i class="remixicon-question-line text-muted clickable ml-2" [ngbPopover]="wmsHelptext" placement="right"
        container="body"></i>
    </div>
    <div class="url-input">
      <input type="text" class="form-control" formControlName="url">
      <i class="fas fa-circle-notch fa-spin" *ngIf="wmsStatus === 'loading'"></i>
      <i class="input-icon font-20 text-success remixicon-checkbox-circle-fill" *ngIf="wmsStatus === 'success'"></i>
      <i class="input-icon font-20 text-danger remixicon-error-warning-fill" *ngIf="wmsStatus === 'error'"></i>
    </div>
    <div class="small text-danger" *ngIf="wmsStatus === 'error'">
      This is an invalid WMS URL
    </div>
  </div>
  <div class="form-group">
    <label class="form-label">Name</label>
    <input type="text" class="form-control" formControlName="name">
  </div>
</ng-container>