export enum ProjectStatus {
  CONCEPT = 'CONCEPT',
  SURVEY = 'SURVEY',
  ANALYSIS = 'ANALYSIS',
  DESIGN = 'DESIGN',
  FEEDBACK = 'FEEDBACK',
  PROBLEM = 'PROBLEM',
  PLANNING = 'PLANNING',
  EXECUTION = 'EXECUTION',
  EVALUATION = 'EVALUATION',
  AUTHORIZATION = 'AUTHORIZATION',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
}

export const PROJECT_STATUS_BADGE_MAPPER = {
  START: 'light-primary',
  CONCEPT: 'light-primary',
  SURVEY: 'light-info',
  ANALYSIS: 'light-primary',
  DESIGN: 'light-primary',
  FEEDBACK: 'light-info',
  PROBLEM: 'light-danger',
  PLANNING: 'light-primary',
  EXECUTION: 'secondary text-body',
  EVALUATION: 'light-primary',
  AUTHORIZATION: 'light-primary',
  REJECTED: 'danger',
  COMPLETED: 'primary',
};
