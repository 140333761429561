<p
  *ngIf="!answer?.extra || answer?.value === 'N/A'; else budgetTmpl"
  class="mb-0"
>
  {{ answer?.value }}
</p>

<ng-template #budgetTmpl>
  <p>
    {{ answer?.value }}/{{answer?.extra.scaleMax}} {{answer?.extra.budgetSuffix}} ({{
      answer?.value | map: getPercentage:answer?.extra.scaleMax
    }})
  </p>
</ng-template>
