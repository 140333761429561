<div class="card ol-popup p-2" [id]="'popup-comment'">
  <div class="p-5 d-flex justify-content-center align-items-center" *ngIf="loading">
    <div class="spinner-grow text-primary" role="status"></div>
  </div>

  <ng-container *ngIf="!loading">
    <form *ngIf="firstComment">
      <div class="form-group mb-1">
        <ng-template [ngTemplateOutlet]="userLabel" [ngTemplateOutletContext]="{ user: userDetails }"> </ng-template>
        <ng-template [ngTemplateOutlet]="commentInput"> </ng-template>
      </div>
      <ng-template [ngTemplateOutlet]="commentActions"> </ng-template>
    </form>

    <div class="perfect-scroll" *ngIf="!firstComment">
      <ng-container *ngFor="let comment of comments; last as isLast; first as isFirst">
        <div class="form-group" [class.mb-3]="!isLast" [class.ml-3]="!isFirst">
          <ng-template
            [ngTemplateOutlet]="userLabel"
            [ngTemplateOutletContext]="{ user: comment.author, date: comment._updated, canResolve: isFirst }"
          >
          </ng-template>
          <div [innerHTML]="comment.message"></div>
        </div>
      </ng-container>
    </div>

    <div class="border-top pt-2" *ngIf="!firstComment">
      <ng-template [ngTemplateOutlet]="commentCTA"></ng-template>
    </div>
  </ng-container>
</div>

<ng-template #userLabel let-user="user" let-date="date" let-canResolve="canResolve">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <div class="d-flex align-items-center">
      <img
        [src]="
          user?.image
            ? user?.image
            : user?.user_profile?.data?.picture
            ? user?.user_profile?.data?.picture
            : 'assets/images/profile-empty.png'
        "
        alt=""
        height="30"
        width="30"
        class="rounded-circle profile-image"
      />
      <div class="ml-2">
        <h5 class="my-0">{{ user?.name }}</h5>
        <div class="text-muted font-12" *ngIf="!firstComment">{{ mapDate(date) }}</div>
      </div>
    </div>

    <button
      class="btn btn-toolbar"
      *ngIf="canResolve"
      [ngbTooltip]="'map.actions.comments.RESOLVE' | translate"
      position="top"
      (click)="onResolve()"
    >
      <i class="fe-check"></i>
    </button>
  </div>
</ng-template>

<ng-template #commentInput>
  <maplix-quill-editor
    [options]="{}"
    [control]="commentControl"
    [mentions]="true"
    [mentionItems$]="members$"
    [placeholder]="'map.actions.comments.PLACEHOLDER' | translate"
    [maxHeight]="150"
  ></maplix-quill-editor>
</ng-template>

<ng-template #commentActions>
  <div class="d-flex justify-content-end align-items-center">
    <button class="btn btn-light btn-sm" type="button" (click)="onCancel()">
      {{ 'generic.buttons.CANCEL' | translate }}
    </button>
    <button class="btn btn-primary btn-sm ml-1" type="submit" [disabled]="commentControl?.invalid" (click)="onCreate()">
      {{ 'map.actions.comments.CREATE' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #commentCTA>
  <button class="btn btn-light w-100" (click)="onReply()">
    {{ 'map.actions.comments.REPLY' | translate }}
  </button>
</ng-template>
