<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Create new dataset</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body perfect-scroll">
  <maplix-ui-preloader [display]="saving"></maplix-ui-preloader>

  <ng-container [ngSwitch]="newDataset">
    <ng-container *ngSwitchCase="'WMS'">
      <maplix-create-wms
        [apiUrl]="apiUrl"
        (formValidityChange)="onSetFormValidity($event)"
        (formValueChange)="onSelectElement($event)"
      ></maplix-create-wms>
    </ng-container>
    <ng-container *ngSwitchCase="'UPLOAD'">
      <maplix-create-upload-layer
        [apiUrl]="apiUrl"
        [geoserverUrl]="geoserverUrl"
        (formValidityChange)="onSetFormValidity($event)"
        (formValueChange)="onSelectElement($event)"
      ></maplix-create-upload-layer>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="d-flex align-items-center justify-content-end">
    <maplix-icon-text-button
      [buttonType]="'light'"
      [buttonText]="lightButtonText"
      [icon]="lightButtonIcon"
      (action)="lightButtonClick()"
      class="mr-1"
      *ngIf="showLightButton"
    ></maplix-icon-text-button>
    <maplix-icon-text-button
      [buttonType]="'danger'"
      [buttonText]="dangerButtonText"
      [icon]="dangerButtonIcon"
      (action)="dangerButtonClick()"
      class="mr-1"
      *ngIf="showAccentButton"
    ></maplix-icon-text-button>
    <maplix-icon-text-button
      [buttonType]="'primary'"
      [buttonText]="primaryButtonText"
      [icon]="primaryButtonIcon"
      (action)="primaryButtonClick()"
      *ngIf="showPrimaryButton"
      [disabled]="primaryButtonDisabled"
    >
    </maplix-icon-text-button>
  </div>
</div>
