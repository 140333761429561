import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MapClsActions } from '../../class/mapcls-actions';
import { catchError, debounceTime, delay, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, merge, throwError } from 'rxjs';
import { DestroyComponent } from '@maplix/ng-utils';
import { Type as GeometryType } from 'ol/geom/Geometry';
import BaseEvent from 'ol/events/Event';
import {
  ActionCommentStatus,
  IActionComment,
  IActionCommentThread,
  IActionPlan,
  IActionPlanItem,
  IStyle,
  IUserDetails,
} from '@maplix/utils';
import { Color, ColorEvent } from 'ngx-color';
import { Point } from 'ol/geom';
import { WorkspaceService } from '@maplix/services';
import { ApiService } from '@maplix/api';
import Feature from 'ol/Feature';

@Component({
  selector: 'maplix-map-actions',
  templateUrl: './map-actions.component.html',
  styleUrls: ['./map-actions.component.scss'],
})
export class MapActionsComponent extends DestroyComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public map: MapClsActions;

  @Input()
  public baseLayer: string = 'mapbox-basic';

  @Input()
  private actionPlan: IActionPlan;

  @Input()
  public activeAction: IActionPlanItem;

  @Input()
  public activeActionStyle: IStyle;

  @Input()
  public members$: Observable<IUserDetails>;

  @Input()
  public height: string = '400px';

  @Output()
  public activeButtonChange: EventEmitter<string> = new EventEmitter();

  @Output()
  public actionChange: EventEmitter<BaseEvent> = new EventEmitter();

  @Output()
  public colorChange: EventEmitter<Color> = new EventEmitter();

  @Output()
  public addThread: EventEmitter<IActionCommentThread> = new EventEmitter();

  @Output()
  public replyThread: EventEmitter<string> = new EventEmitter();

  @Output()
  public resolveThread: EventEmitter<string> = new EventEmitter();

  @Output()
  public hideMap: EventEmitter<void> = new EventEmitter();

  public maxBaseToggleHeight: string;

  private activeButton: BehaviorSubject<string> = new BehaviorSubject(null);
  public activeButton$: Observable<string> = this.activeButton.asObservable();

  private showComments: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public showComments$ = this.showComments.asObservable();

  private userDetails = this.workspaceService.getUserDetails();

  public loadingCommentThreads: boolean;

  public loadingComments: boolean;

  public activeComments: IActionComment[];

  private commentSaved: Subject<{ thread: string; actionplan: string }> = new Subject();

  public get hasCommentThreads(): boolean {
    return this.map.hasCommentThreads();
  }

  constructor(private workspaceService: WorkspaceService, private api: ApiService) {
    super();
  }

  ngOnInit(): void {
    this.activeButton$
      .pipe(
        tap((button) => {
          this.activeButtonChange.emit(button);

          if (!button) {
            this.map.stopDrawingAction();
            this.map.stopEditActions();
            this.map.stopRemoveAction();
            this.map.stopDrawingComment();
            return;
          }

          if (button.includes('draw')) {
            const drawend$ = this.map.startDrawingAction(button.split('draw-')[1] as GeometryType);

            // Stop the drawing interaction after geometry is drawn
            drawend$
              .pipe(
                delay(50),
                tap(() => this.onToggleButton(button)),
                take(1)
              )
              .subscribe();
            return;
          }

          if (button === 'edit-actions') {
            this.map.startEditActions();
            return;
          }

          if (button === 'remove-actions') {
            this.map.startRemoveAction();
            return;
          }

          if (button === 'comment') {
            const drawend$ = this.map.startDrawingComment();
            // Stop the drawing interaction after geometry is drawn
            drawend$
              .pipe(
                delay(50),
                tap((e) => {
                  this.onToggleButton(button);

                  this.map.showPopupOverlay(e.feature as Feature<Point>);
                }),
                take(1)
              )
              .subscribe();
            return;
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();

    this.map.actionChange$
      .pipe(
        debounceTime(100),
        tap((e) => this.actionChange.emit(e)),
        takeUntil(this.destroyed)
      )
      .subscribe();

    this.showComments$
      .pipe(
        tap((v) => this.map.setCommentsVisibility(v)),
        takeUntil(this.destroyed)
      )
      .subscribe();

    merge(this.map.commentSelected$, this.commentSaved)
      .pipe(
        filter((obj) => {
          if (!obj || !obj.thread || !obj.actionplan) {
            this.activeComments = [];
            return false;
          }

          return true;
        }),
        tap(() => (this.loadingComments = true)),
        switchMap(({ thread, actionplan }) => this.api.actionPlans.getComments(actionplan, thread)),
        tap(({ _items }) => {
          this.activeComments = _items;
          this.loadingComments = false;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Find max height for baselayer toggle
      const mapElement = document.querySelector(`#actions-map`) as HTMLElement;
      if (!mapElement) {
        return;
      }

      const bbox = mapElement.getBoundingClientRect();
      this.maxBaseToggleHeight = bbox.height - 50 + 'px';

      // Initialize the comment popup
      const element = document.querySelector('#popup-comment') as HTMLElement;
      this.map.initPopup(element);
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.activeAction ||
      changes.activeAction.isFirstChange() ||
      changes.activeAction.currentValue?.id === changes.activeAction.previousValue?.id
    ) {
      return;
    }

    this.activeButton.next(null);
  }

  public onChangeBaseLayer(layer: string) {
    this.map.setBaseLayer(layer);
  }

  public onToggleButton(button: string) {
    if (this.activeButton.value === button) {
      this.activeButton.next(null);
      return;
    }

    this.activeButton.next(button);
  }

  public onChangeColor(event: ColorEvent) {
    this.colorChange.emit(event.color);
  }

  public onToggleComments() {
    this.showComments.next(!this.showComments.value);
  }

  public onCreateThread(message: string) {
    const data: IActionCommentThread = {
      actionplan: this.actionPlan._id,
      action: this.activeAction.id,
      author: this.userDetails._id,
      originalMessage: message,
      status: ActionCommentStatus.OPEN,
      geometry: this.map.getNewCommentGeometry(),
      counters: {
        comments: 0,
      },
    };

    this.loadingCommentThreads = true;
    this.api.actionPlans
      .saveThread(this.actionPlan._id, data)
      .pipe(
        tap((response) => {
          this.map.fixComments({ thread: response._id, actionplan: this.actionPlan._id });
          this.addThread.emit({ _id: response._id, ...data, author: this.userDetails });
        }),
        catchError((err) => {
          this.api.errorhandler(err);
          return throwError(err);
        }),
        finalize(() => (this.loadingCommentThreads = false)),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public onCreateComment(opts: { thread: string; message: string }) {
    const { thread, message } = opts;

    const data = {
      thread,
      actionplan: this.actionPlan._id,
      action: this.activeAction.id,
      author: this.userDetails._id,
      message: message,
    };

    this.loadingComments = true;
    this.api.actionPlans
      .saveComment(this.actionPlan._id, data)
      .pipe(
        tap(() => {
          this.commentSaved.next({ thread, actionplan: this.actionPlan._id });
        }),
        catchError((err) => {
          this.api.errorhandler(err);
          this.loadingComments = false;
          return throwError(err);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public onReplyThread(thread: string) {
    this.replyThread.emit(thread);
  }

  public onResolveThread(thread: string) {
    this.resolveThread.emit(thread);
  }

  public onHideMap() {
    this.hideMap.emit();
  }
}
