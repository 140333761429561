import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorType } from '../confirm-button/confirm-button.component';

@Component({
  selector: 'maplix-icon-text-button',
  templateUrl: './icon-text-button.component.html',
  styleUrls: ['./icon-text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTextButtonComponent implements OnInit {
  @Input()
  public buttonType: ColorType = 'primary';

  @Input()
  public customClass: string;

  public get buttonTypeClass(): string {
    return `icon-text-button btn btn-sm btn-${this.buttonType} ${this.customClass ? this.customClass : ''}`;
  }

  @Input()
  public icon: string;

  public get iconClass(): string {
    return `${this.icon} ${this.buttonText ? 'mr-2' : ''} font-16`;
  }

  @Input()
  public fullWidth: boolean;

  @Input()
  public wide: boolean;

  @Input()
  public buttonText: string;

  @Input()
  public disabled: boolean;

  @Input()
  public type: string = 'button';

  @Output()
  public action: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
