import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@maplix/forms';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-rating',
  templateUrl: './popup-fieldtype-rating.component.html',
  styleUrls: ['./popup-fieldtype-rating.component.scss']
})
export class PopupFieldtypeRatingComponent implements OnInit {
  @Input()
  public answer: ISurveyAnswer;

  public ratingControl: FormControl<number> = new FormControl({value: null, disabled: true});

  constructor() { }

  ngOnInit(): void {
    if (!this.answer || !this.answer.extra) {
      return;
    }

    this.ratingControl.setValue(+this.answer.value);
  }

  public getSelectorItems(total: number) {
    let i = 1;
    const items = [];
    while (i <= total) {
      items.push(i);
      i++;
    }

    return items;
  }

}
