<ng-container *ngIf="!answer?.extra">
  <img
    [src]="answer.value_label"
    class="img-preview mb-2 clickable"
    *ngIf="loadedImages.includes(answer.value_label)"
    (click)="onOpenImage(answer.value_label)"
  />
  <div *ngIf="!loadedImages.includes(answer.value_label)">
    <a [href]="answer.value_label" target="_blank">{{ answer.value_label }}</a>
  </div>
</ng-container>

<ng-container *ngIf="answer?.extra">
  <ng-container *ngIf="answer.extra.type.includes('image'); else defaultFileLinkTmpl">
    <img [src]="answer.value_label" class="img-preview mb-2 clickable" (click)="onOpenImage(answer.value_label)" />
  </ng-container>
</ng-container>

<ng-template #defaultFileLinkTmpl>
  <div (click)="onOpenImage(answer.value_label)" class="clickable">
    <div class="file-preview bg-light">
      <div class="d-flex justify-content-center align-items-center h-100">
        <img [src]="'assets/images/file-icons/' + answer.extra.extension + '.svg'" alt="icon" class="icon-preview" />
      </div>
    </div>
    <div class="card file-preview-footer p-1 font-11 mb-0">
      {{ answer.extra.fileName }}
    </div>
  </div>
</ng-template>
