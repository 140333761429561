import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@maplix/forms';
import { IFieldValue } from '../models';

export function ValidateSelectAtLeastOne(fa: AbstractControl<any>) {
  const selected = (fa as FormArray<any>).controls.filter((x) => x.get('selected') && x.get('selected').value);
  if (!selected.length) return { noneSelected: true };
  return null;
}

export function ValidateTransportModes(fg: AbstractControl<any>) {
  const val = (fg as FormGroup<any>).value;
  const oneSelected = Object.keys(val).filter((x) => val[x]).length;
  if (!oneSelected) return { noneSelected: true };
  return null;
}

export function ValidateSelectMinMax(min?: number, max?: number): ValidatorFn<any> {
  return (fa: AbstractControl<any>): ValidationErrors | null => {
    const selected = (fa as FormArray<any>).controls.filter((x) => x.get('selected') && x.get('selected').value);
    if (min && selected.length < min) return { notEnoughSelected: true };
    else if (max && selected.length > max) return { tooManySelected: true };
    return null;
  };
}

export function ValidateBudget(total: number): ValidatorFn<any> {
  return (fg: FormGroup<any>): ValidationErrors | null => {
    if (!fg.get('subquestions') || !fg.get('subquestions')['controls'].length) {
      return null;
    }

    // if all are left open, then it's fine
    if (
      !fg
        .get('subquestions')
        ['controls'].find((sq: FormGroup<any>) => sq.get('response').value || sq.get('response').value == 0)
    ) {
      return null;
    }

    // if not all are filled in, return error
    if (
      fg
        .get('subquestions')
        ['controls'].find((sq: FormGroup<any>) => !sq.get('response').value && sq.get('response').value != 0)
    ) {
      return { missingBudgetCategories: true };
    }

    const inputValue = fg
      .get('subquestions')
      ['controls'].map((sq: FormGroup<any>) => sq.get('response').value)
      .reduce((a, b) => +a + +b);
    if (inputValue > total) {
      return { largerThanBudget: true };
    }

    if (inputValue < total) {
      return { smallerThanBudget: true };
    }

    return null;
  };
}

export function ValidateRanking(fieldValues: IFieldValue[]): ValidatorFn<any> {
  return (fa: FormArray<any>): ValidationErrors | null => {
    // If no element is dragged yet, it's ok
    if (!fa.controls || !fa.controls.length) {
      return null;
    }

    // If not all elements are dragged, return error
    const draggedValues = fa.controls.map((fg: FormGroup<any>) => fg.get('value').value);
    const allValues = fieldValues.map((fv) => fv.value);
    if (allValues.filter((v) => !draggedValues.includes(v)).length) {
      return { missingValues: true };
    }
    return null;
  };
}

export function ValidateArrayLength(min: number): ValidatorFn<any> {
  return (fc: FormControl<any[]>): ValidationErrors | null => {
    if (!Array.isArray(fc.value)) {
      return { array: true };
    }

    if (!fc.value?.length || fc.value?.length < min) {
      return { minLength: true };
    }

    return null;
  };
}
