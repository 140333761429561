import { ObjectId } from '../object-id';
import { MaplixApp } from './app';

export enum Product {
  ENGAGE_RESPONDENT = 'ENGAGE_RESPONDENT',
  MAPS_VIEW = 'MAPS_VIEW',
}

export enum PaymentStatus {
  CREATED = 'CREATED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export interface IOrder {
  _id?: ObjectId;
  organisation?: ObjectId;
  user?: ObjectId;
  lineItems: IOrderLineItem[];
  type: Product;
  amountSubtotal: number;
  amountTotal: number;
  quantity: number;
  status: PaymentStatus;
  _stripeInvoiceId: string;
  _stripeInvoiceNumber: string;
  _stripeInvoiceLineId: string;
  _stripePriceId: string;
  _stripeInvoicePdf: string;
  _stripeInvoiceUrl: string;
  _created?: Date;
  _updated?: Date;
  _auth?: string;
}

export interface IOrderLineItem {
  product: IOrderProduct;
  credit: IOrderCredit;
  totalQuantity: number;
  amountExcludingTax: number;
  amount: number;
  _stripeLineItemId: string;
  _stripePriceId: string;
}

export interface IOrderProduct {
  name: string;
  quantity: number;
  _stripeProductId: string;
}

export interface IOrderCredit {
  nickname: string;
  app: MaplixApp;
  type: string;
  quantity: number;
}
