export function randomIdGenerator(length: number = 10): string {
  const opts = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let result = '';
  for (let i = 0; i < length; i++) {
    const index = Math.round(Math.random() * (opts.length - 1));
    result += opts[index];
  }
  return result;
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
