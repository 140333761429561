import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  bsConfig,
  conditionOperators,
  ConditionType,
  GroupOperator,
  IMapSurveyLayer,
  IMapSurveyVector,
  IRelation,
  ISurvey,
  ISurveyImage,
  ISurveyMap,
  ISurveyPage,
  ISurveyQuestion,
  MapType,
  transportModes,
} from '@maplix/utils';
import { cloneDeep } from 'lodash';
import { FormArray, FormControl, FormGroup } from '@maplix/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Type } from 'ol/geom/Geometry';

@Component({
  selector: 'maplix-rule-editor',
  templateUrl: './rule-editor.component.html',
  styleUrls: ['./rule-editor.component.scss'],
})
export class RuleEditorComponent implements OnInit, OnDestroy {
  @Input()
  public dataLayer: FormGroup<IMapSurveyLayer | IMapSurveyVector>;

  @Input()
  public interactionsInLayer: string[];

  @Input()
  public rules: FormArray<IRelation>;

  @Input()
  public survey: ISurvey;

  @Input()
  public geometryType: Type | 'Route';

  @Input()
  public question: ISurveyQuestion;

  @Input()
  public disabled: boolean;

  @Input()
  public activePage: ISurveyPage;

  @Input()
  public mapInteraction: ISurveyMap | ISurveyImage;

  @Input()
  public language: string;

  @Output()
  private changed: EventEmitter<void> = new EventEmitter();

  @Input()
  private readonly mapType: MapType;

  public transportModes = cloneDeep(transportModes);

  public readonly bsConfig = bsConfig;

  public allQuestions: { id: string; pageid: number; label: string }[];

  public subQuestions = {};

  public fieldValues = {};

  public operators = {};

  public multiple = {};

  public dates = {};

  public groupOperators: GroupOperator[] = ['AND', 'OR'];

  public readonly allOperators = cloneDeep(conditionOperators);

  public modeOperators = this.allOperators.filter((x) => ['equals', 'not-equal'].includes(x.id));

  public ruleTypes: { id: string; label: string }[] = [
    { id: 'question', label: 'Question' },
    { id: 'transportMode', label: 'Transport mode' },
  ];

  private destroyed: Subject<void> = new Subject();

  constructor() {}

  ngOnInit() {
    if (this.question) {
      const currentPageIndex = this.survey.questions.pages.findIndex((page) => page.id === this.activePage.id);
      const pageIds = this.survey.questions.pages.slice(0, currentPageIndex + 1).map((page) => page.id);

      // this.allQuestions = this.survey.questions.questions
      //   .filter(question => pageIds.includes(question.pageid))
      //   .filter(question => question.pageid == this.activePage.id && x.displayOrder < this.question.displayOrder && (![10, 11].includes(x.type) || x.options.textType === 'number') && x.type !== 13 && x.type !== 6)
      //   .filter(x => this.interaction && this.interaction.categories && this.interaction.categories.length
      //     ? x.category === this.question.get('category').value
      //     : true)
      //   .map(x => {
      //     return {
      //       id: x.id,
      //       pageid: x.pageid,
      //       label: `${x.id}: ${this.concatQuestion(x)}`
      //     }
      //   });
    } else {
      const mapPages =
        this.mapType === 'MAP'
          ? this.survey.questions.mapInteractions.map((page) => page.pageid)
          : this.survey.questions.imageInteractions.map((page) => page.pageid);

      // Legacy for hoogstraten survey
      if (this.survey.general.uri === 'hoogstraten') {
        this.allQuestions = this.survey.questions.questions.map((x) => {
          return {
            id: x.id,
            pageid: x.pageid,
            label: `${x.id}: ${this.concatQuestion(x)}`,
          };
        });
      } else {
        this.allQuestions = this.survey.questions.questions
          .filter(
            (question) =>
              !mapPages.includes(question.pageid) ||
              (this.interactionsInLayer && this.interactionsInLayer.includes(question.category))
          )
          .filter((question) => ![6, 8, 13].includes(question.type))
          .map((x) => {
            return {
              id: x.id,
              pageid: x.pageid,
              label: `${x.id}: ${this.concatQuestion(x)}`,
            };
          });
      }
    }

    if (this.rules) {
      this.rules.controls.forEach((rule: FormGroup<IRelation>) => {
        const questionId = rule.getControl('questionid').value;
        if (questionId) {
          this.onChangeQuestion({ id: questionId });
        }
      });

      this.rules.value$.pipe(takeUntil(this.destroyed), debounceTime(500)).subscribe(() => {
        this.changed.emit();
      });
    }

    if (this.geometryType !== 'Route') {
      this.ruleTypes = this.ruleTypes.filter((type) => type.id !== 'transportMode');
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public onChangeQuestion($event: { id: string }) {
    this.subQuestions[$event.id] = this.getSubquestions($event.id);
    this.fieldValues[$event.id] = this.getFieldValues($event.id);
    this.operators[$event.id] = this.getOperators($event.id);
    this.multiple[$event.id] = this.getMultiple($event.id);
    this.dates[$event.id] = this.getDate($event.id);
  }

  private getOperators(questionid) {
    let operators = cloneDeep(this.allOperators);
    const selectedQuestion = this.survey.questions.questions.find((q) => q.id === questionid);
    if (selectedQuestion) {
      switch (selectedQuestion.type) {
        case 2:
          operators = operators.filter((x) => x.id === 'equals' || x.id === 'not-equal');
          break;
        case 3:
          operators = operators.filter((x) => x.id.includes('selected'));
          break;
        case 4:
          operators = operators.filter((x) => x.id.includes('dropdown-equal'));
          break;
        case 7:
          if (selectedQuestion.options.multiple) {
            operators = operators.filter((x) => x.id.includes('selected'));
          } else {
            operators = operators.filter((x) => x.id === 'equals' || x.id === 'not-equal');
          }
          break;
        default:
          operators = operators.filter((x) => !x.id.includes('selected') && !x.id.includes('dropdown-equal'));
          break;
      }
    }

    return operators;
  }

  getSubquestions(questionid) {
    const selectedQuestion = this.survey.questions.questions.find((q) => q.id === questionid);
    if (selectedQuestion) {
      const subQuestions = selectedQuestion.subquestions;
      if (subQuestions) {
        return subQuestions.map((x) => {
          if (!(selectedQuestion.options.frontLabel && selectedQuestion.options.frontLabel.length)) {
            return {
              id: x.subquestionid,
              label: x.label.find((x) => x.i18n == this.language).value,
            };
          } else {
            const frontLabel = selectedQuestion.options.frontLabel.find((l) => l.value == x.subquestionid).label;
            if (selectedQuestion.options.inlineText) {
              const backLabel = selectedQuestion.options.backLabel.find((l) => l.value == x.subquestionid).label;
              return {
                id: x.subquestionid,
                label:
                  frontLabel.find((x) => x.i18n == this.language).value +
                  ' ... ' +
                  backLabel.find((x) => x.i18n == this.language).value,
              };
            } else {
              return {
                id: x.subquestionid,
                label: frontLabel.find((x) => x.i18n == this.language).value,
              };
            }
          }
        });
      }
    }
  }

  getFieldValues(questionid) {
    const selectedQuestion = this.survey.questions.questions.find((q) => q.id === questionid);
    if (selectedQuestion) {
      return selectedQuestion.fieldValues.map((x) => {
        return {
          id: x.value,
          label: x.label.find((x) => x.i18n == this.language).value
            ? x.label.find((x) => x.i18n == this.language).value
            : x.value,
        };
      });
    }
  }

  private getMultiple(questionid) {
    const selectedQuestion = this.survey.questions.questions.find((q) => q.id === questionid);
    if (selectedQuestion) {
      if ((selectedQuestion.options.multiple && [4, 7].includes(selectedQuestion.type)) || selectedQuestion.type == 3) {
        return true;
      }
    }
    return false;
  }

  private getDate(questionid) {
    const selectedQuestion = this.survey.questions.questions.find((q) => q.id === questionid);
    if (selectedQuestion) {
      if (selectedQuestion.type === 12) {
        return true;
      }
    }
    return false;
  }

  private concatQuestion(q) {
    const alias: string = q.alias.find((x) => x.i18n === this.language)?.value;

    const label: string = q.question.find((x) => x.i18n === this.language)?.value ?? 'Untitled question';

    if (alias) {
      return alias;
    }

    if (!label) {
      return '';
    }

    const cleanText = label.replace(/<\/?[^>]+(>|$)/g, '');
    if (cleanText.length <= 60) return cleanText;
    else {
      return cleanText.slice(0, 29) + ' ... ' + cleanText.slice(cleanText.length - 29, cleanText.length);
    }
  }

  public onRemoveRule(rule) {
    const index = this.rules.controls.findIndex((r) => r === rule);
    this.rules.removeAt(index);
    this.changed.emit();
  }

  public onAddRule() {
    const fg = new FormGroup<IRelation>({
      type: new FormControl<ConditionType>(),
      order: new FormControl(this.rules.controls.length + 1),
      questionid: new FormControl<string>(),
      subquestionid: new FormControl<string>(),
      transportMode: new FormControl<string>(),
      value: new FormControl(),
      operator: new FormControl<string>(),
      groupOperator: new FormControl<GroupOperator>('AND'),
    });

    this.rules.push(fg);
  }

  public onChangeGroupOperator(rule) {
    if (rule.get('groupOperator').value == 'AND') {
      rule.get('groupOperator').setValue('OR');
    } else {
      rule.get('groupOperator').setValue('AND');
    }
  }
}
