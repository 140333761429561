import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@maplix/forms';
import { WorkspaceService } from '@maplix/services';
import { IActionComment, IUserDetails } from '@maplix/utils';
import { MapClsActions } from '../../class';
import { takeUntil, tap } from 'rxjs/operators';
import { DestroyComponent } from '@maplix/ng-utils';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'maplix-map-comment-popup',
  templateUrl: './map-comment-popup.component.html',
  styleUrls: ['./map-comment-popup.component.scss'],
})
export class MapCommentPopupComponent extends DestroyComponent implements OnChanges {
  @Input()
  public comments: IActionComment[];

  @Input()
  private map: MapClsActions;

  @Input()
  public loading: boolean;

  @Input()
  public members$: Observable<IUserDetails>;

  @Output()
  private createThread: EventEmitter<string> = new EventEmitter();

  @Output()
  private createComment: EventEmitter<{ thread: string; message: string }> = new EventEmitter();

  @Output()
  private replyThread: EventEmitter<string> = new EventEmitter();

  @Output()
  private resolveThread: EventEmitter<string> = new EventEmitter();

  public commentControl: FormControl<string> = new FormControl(null, Validators.required);

  public get firstComment() {
    return !this.comments?.length;
  }

  public userDetails: IUserDetails = this.workspaceService.getUserDetails();

  private activeThread: string;

  constructor(private workspaceService: WorkspaceService, private translate: TranslateService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.map?.isFirstChange()) {
      this.map.commentSelected$
        .pipe(
          tap((obj) => (this.activeThread = obj?.thread)),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }

    if (changes.comments) {
      this.commentControl.reset();
      this.commentControl.markAsPristine();
    }
  }

  public onCreate() {
    if (this.firstComment) {
      this.createThread.emit(this.commentControl.value);
      return;
    }

    this.createComment.emit({ message: this.commentControl.value, thread: this.activeThread });
  }

  public onCancel() {
    if (this.firstComment) {
      this.map.removeComment();
    }

    this.commentControl.reset();
    this.commentControl.markAsPristine();
  }

  public onReply() {
    this.replyThread.emit(this.activeThread);
  }

  public mapDate(date: Date) {
    moment.locale(this.workspaceService.getActiveWorkspace().defaultLanguage?.code);

    const today = moment().startOf('day');
    const yetserday = moment().subtract(1, 'day').startOf('day');

    if (moment(date).isSame(today, 'd')) {
      return `${this.translate.instant('generic.date.TODAY')} ${moment(date).format('HH:mm')}`;
    }

    if (moment(date).isSame(yetserday, 'd')) {
      return `${this.translate.instant('generic.date.YESTERDAY')} ${moment(date).format('HH:mm')}`;
    }

    if (moment(date).isSame(today, 'y')) {
      return moment(date).format('DD MMMM HH:mm');
    }

    return moment(date).format('DD MMM yyyy HH:mm');
  }

  public onResolve() {
    this.resolveThread.emit(this.activeThread);
  }
}
