<div class="d-flex flex-column align-items-center justify-content-center">
  <i class="remixicon-folder-upload-fill text-light" style="font-size: 80px"></i>
  <ng-container *ngIf="!selectedFile">
    <div class="text-muted mb-3">
      Upload your geodata in one of the following formats:
      <div class="mt-2">
        <i class="remixicon-check-line mr-2"></i>ESRI Shapefile (zip)<i
          class="remixicon-question-line text-muted ml-1 clickable"
          [ngbPopover]="
            'Upload a zip file containing the .shp, .shx and .dbf files. The zip file cannot contain more than 1 file of each type (.shp, .shx and .dbf), and these files should be at the root level of the zip file (not nested inside one or more folders).'
          "
          placement="bottom"
          container="body"
        ></i>
      </div>
      <div class="mt-1">
        <i class="remixicon-check-line mr-2"></i>GeoPackage <span class="badge badge-light-secondary ml-1">BETA</span
        ><i
          class="remixicon-question-line text-muted ml-1 clickable"
          [ngbPopover]="
            'We currently only allow GeoPackages with 1 layer. If your GeoPackage contains multiple layers, only the first one will be added to the dataset.'
          "
          placement="bottom"
          container="body"
        ></i>
      </div>
      <div class="mt-1">
        <i class="remixicon-check-line mr-2"></i>GeoJSON <span class="badge badge-light-secondary ml-1">BETA</span
        ><i
          class="remixicon-question-line text-muted ml-1 clickable"
          [ngbPopover]="
            'We currently only allow GeoJSONs with 1 geometry type. If your GeoJSON contains multiple geometry types, only the first one will be added to the dataset.'
          "
          placement="bottom"
          container="body"
        ></i>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedFile">
    <div class="card py-2 px-3 d-flex flex-row align-items-center w-100">
      <i class="text-muted font-24 fas fa-circle-notch fa-spin" *ngIf="uploadStatus === 'uploading'"></i>
      <i class="text-danger font-24 remixicon-error-warning-fill" *ngIf="uploadStatus === 'error'"></i>
      <i class="text-success font-24 remixicon-checkbox-circle-fill" *ngIf="uploadStatus === 'success'"></i>
      <div class="px-3 w-100 uploaded-file">
        <h5>{{ selectedFile?.name }}</h5>
        <ngb-progressbar
          height="8px"
          type="primary"
          [value]="uploadHandler.uploadProgress"
          [striped]="true"
          [animated]="true"
          *ngIf="uploadStatus === 'uploading'"
        ></ngb-progressbar>
        <div class="small text-muted" *ngIf="uploadStatus === 'success' && selectedFile">
          {{ selectedFile?.size | map: formatSize }}
        </div>
        <div class="small text-danger" *ngIf="uploadStatus === 'error' && error">{{ error }}</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!selectedFile || uploadStatus === 'error'">
    <maplix-icon-text-button
      [buttonType]="'primary'"
      [buttonText]="selectedFile ? 'Select another file' : 'Select a file'"
      [icon]="'remixicon-upload-2-line'"
      (click)="onOpenFilePicker()"
      class="mb-2"
    ></maplix-icon-text-button>
    <input
      type="file"
      #filePicker
      class="hidden-picker"
      [multiple]="false"
      [(ngModel)]="selectedFiles"
      (input)="onUploadFile($event)"
      accept="application/zip,.geojson,.gpkg"
    />
  </ng-container>
</div>

<ng-container *ngIf="uploadStatus === 'success' && form" [formGroup]="form">
  <div class="form-group w-100">
    <label class="form-label">Name</label>
    <input type="text" class="form-control" formControlName="name" />
  </div>
</ng-container>
