import {Injectable} from '@angular/core';
import {AbstractControlOptions} from 'ngx-typesafe-forms';

import {FormControl} from './form-control';
import {FormGroup} from './form-group';
import {FormArray} from './form-array';
import {GroupArg, StringKeys} from './interfaces';
import {AbstractControl} from './abstract-control';

@Injectable()
export class FormBuilder {
	public group<T>(
		controls: {
			[K in keyof T]: GroupArg<T[K]> | FormGroup<T[K]> | FormArray<T[K]>;
		},
		options?: AbstractControlOptions<T>
	): FormGroup<T> {
		const result = new FormGroup<T>({}, options);
		const keys = Object.keys(controls) as StringKeys<T>[];

		keys.forEach(key => {
			const control =
				controls[key] instanceof FormGroup || controls[key] instanceof FormArray
					? (controls[key] as AbstractControl<T[typeof key]>)
					: new FormControl<T[typeof key]>(Array.isArray(controls[key]) ? controls[key][0] : controls[key]);

			result.addControl(key, control);
		});

		return result;
	}

	public array<T>(
		controls: {
			[K in keyof T]: GroupArg<T[K]>;
		}[],
		options?: AbstractControlOptions<T[]>
	): FormArray<T> {
		const result = new FormArray<T>([], options);
		controls.forEach(control => {
			const fg = this.group(control);
			result.push(fg);
		});

		return result;
	}
}
