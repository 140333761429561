import { Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';

export class PopupOverlay extends Overlay {
  constructor(element: HTMLElement) {
    super({
      element,
      autoPan: {
        animation: {
          duration: 100,
        },
        margin: 50,
      },
      positioning: 'bottom-right',
    });
  }

  public show(coordinate: Coordinate) {
    this.setPosition(coordinate);
  }

  public hide() {
    this.setPosition(undefined);
  }
}
