import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MaplixApp } from '@maplix/utils';

@Component({
  selector: 'maplix-map-attribution',
  templateUrl: './map-attribution.component.html',
  styleUrls: ['./map-attribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapAttributionComponent implements OnInit {
  @Input()
  public hideOnSmartphone: boolean;

  @Input()
  public app: MaplixApp = MaplixApp.EXPLORE;

  constructor() {}

  ngOnInit() {}
}
