<div class="position-relative">
  <div class="py-5" *ngIf="loading">
    <maplix-ui-preloader [display]="loading"></maplix-ui-preloader>
  </div>

  <div class="d-flex flex-column align-items-center py-2" *ngIf="!loading && !uploads?.length">
    <i class="remixicon-server-fill text-light" style="font-size: 80px"></i>
    <div class="h4">No images found</div>
    <p>Upload an image to your Maplix Cloud</p>
    <maplix-icon-text-button
      [buttonText]="'Upload image'"
      [buttonType]="'primary'"
      [icon]="'remixicon-upload-cloud-line'"
      (action)="uploadImage()"
    ></maplix-icon-text-button>
  </div>

  <ng-container *ngIf="uploads?.length">
    <div class="d-flex flex-column align-items-center py-3 border-bottom">
      <maplix-icon-text-button
        [buttonText]="'Upload image'"
        [buttonType]="'primary'"
        [icon]="'remixicon-upload-cloud-line'"
        (action)="uploadImage()"
      ></maplix-icon-text-button>
    </div>

    <p class="font-13 text-muted text-uppercase mt-3">My images</p>

    <maplix-searchable-list
      [searchable]="false"
      [page]="page"
      [pageSize]="pageSize"
      (pageChange)="getNewPage($event)"
      [totalItems]="totalItems"
    >
      <ng-container *ngFor="let group of dataGroups">
        <ng-container *ngIf="uploads | map: getUploadsForGroup:group as groupedUploads">
          <ng-container *ngIf="groupedUploads?.length">
            <label class="form-label mb-2 text-muted ml-1">{{ group.label }}</label>
            <div class="d-flex flex-wrap mb-1">
              <ng-container *ngFor="let upload of groupedUploads">
                <div class="card mr-2 clickable mb-2 bg-light" (click)="selectImage(upload.url)">
                  <img class="icon-preview upload-medium" [title]="upload.fileName" [src]="upload.url" />
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </maplix-searchable-list>
  </ng-container>
</div>
