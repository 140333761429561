<form [formGroup]="styleCondition">
  <!-- Define the rules if rule-based -->
  <ng-container *ngIf="dataLayer.get('styleSource').value === 'rule-based'">
    <div class="form-group">
      <label class="form-label">Rule label</label>
      <ng-container
        *ngFor="
          let title of styleCondition.get('title')['controls'] | formGroupFilter: { field: 'i18n', filter: language }
        "
        [formGroup]="title"
      >
        <input type="text" class="form-control" formControlName="value" />
      </ng-container>
    </div>
  </ng-container>

  <form [formGroup]="styleCondition.get('style')">
    <!-- Point style -->
    <ng-container
      *ngIf="dataLayer.get('styleSource').value !== 'heatmap' && dataLayer.get('geomType').value === 'Point'"
    >
      <div
        class="form-group"
        *ngIf="dataLayer.get('styleSource').value === 'regular' || dataLayer.get('styleSource').value === 'rule-based'"
      >
        <label class="form-label">Point type</label>
        <ng-select
          [items]="pointTypes"
          formControlName="pointStyle"
          bindValue="value"
          [clearable]="false"
          [searchable]="false"
          appendTo=".layer-editor"
        >
          <ng-template ng-label-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="font-13 text-muted text-uppercase mb-3 mt-4"
        *ngIf="
          dataLayer.get('geomType').value === 'Point' &&
          styleCondition.get('style.pointStyle').value !== 'marker' &&
          styleCondition.get('style.pointStyle').value !== 'icon' &&
          dataLayer.get('styleSource').value !== 'heatmap' &&
          dataLayer.get('styleSource').value !== 'cluster'
        "
      >
        Style options
      </div>
      <div
        class="font-13 text-muted text-uppercase mb-3 mt-4"
        *ngIf="dataLayer.get('geomType').value === 'Point' && dataLayer.get('styleSource').value === 'cluster'"
      >
        Cluster options
      </div>

      <div
        class="form-group"
        *ngIf="
          dataLayer.get('styleSource').value === 'cluster' ||
          (styleCondition.get('style.pointStyle').value !== 'marker' &&
            styleCondition.get('style.pointStyle').value !== 'icon')
        "
      >
        <label class="form-label" *ngIf="dataLayer.get('styleSource').value !== 'cluster'">{{
          styleCondition.get('style.pointStyle').value === 'regular' ? 'Radius' : 'Size'
        }}</label>
        <label class="form-label" *ngIf="dataLayer.get('styleSource').value === 'cluster'">Base radius</label>
        <div class="input-group">
          <input type="number" class="form-control" formControlName="circleRadius" />
          <div class="input-group-append">
            <span class="input-group-text bg-white">pixels</span>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Cluster parameters -->
    <ng-container
      *ngIf="dataLayer.get('styleSource').value === 'cluster' && dataLayer.get('geomType').value === 'Point'"
    >
      <div class="form-group">
        <label class="form-label">Cluster distance</label>
        <div class="input-group">
          <input type="number" class="form-control" formControlName="clusterDistance" />
          <div class="input-group-append">
            <span class="input-group-text bg-white">pixels</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Cluster size factor</label>
        <input type="number" class="form-control" formControlName="clusterSizeFactor" />
      </div>
    </ng-container>

    <!-- Heatmap parameters -->
    <ng-container *ngIf="dataLayer.get('styleSource').value === 'heatmap'">
      <!-- For points -->
      <ng-container *ngIf="dataLayer.get('geomType').value === 'Point'">
        <div class="font-13 text-muted text-uppercase mb-3 mt-4">Heatmap options</div>
        <div class="form-group">
          <label class="form-label">Radius</label>
          <div class="input-group">
            <input type="number" class="form-control" formControlName="heatmapBlurRadius" />
            <div class="input-group-append">
              <span class="input-group-text bg-white">pixels</span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Blur size</label>
          <div class="input-group">
            <input type="number" class="form-control" formControlName="heatmapBlurSize" />
            <div class="input-group-append">
              <span class="input-group-text bg-white">pixels</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div
      class="font-13 text-muted text-uppercase mb-3 mt-4"
      *ngIf="
        dataLayer.get('geomType').value === 'Point' &&
        styleCondition.get('style.pointStyle').value === 'marker' &&
        dataLayer.get('styleSource').value !== 'heatmap' &&
        dataLayer.get('styleSource').value !== 'cluster'
      "
    >
      Marker options
    </div>

    <!-- General styles -->
    <ng-container
      *ngIf="
        (dataLayer.get('styleSource').value === 'cluster' && dataLayer.get('geomType').value === 'Point') ||
        (dataLayer.get('geomType').value === 'Point' &&
          styleCondition.get('style.pointStyle').value !== 'icon' &&
          dataLayer.get('styleSource').value !== 'heatmap') ||
        dataLayer.get('geomType').value !== 'Point'
      "
    >
      <div
        class="font-13 text-muted text-uppercase mb-3"
        [class.mt-4]="survey || dataLayer.get('geomType').value === 'Point'"
        *ngIf="
          (dataLayer.get('geomType').value === 'Point' && dataLayer.get('styleSource').value === 'cluster') ||
          dataLayer.get('geomType').value !== 'Point'
        "
      >
        Style options
      </div>

      <div class="form-group" *ngIf="dataLayer.get('geomType').value === 'Polygon'">
        <label class="form-label">Fill color</label>
        <maplix-color-picker
          [color]="styleCondition.get('style.fillColor').value"
          (colorChange)="onChangeColor('fillColor', $event)"
          [hideOpacity]="
            dataLayer.get('geomType').value === 'Point' && styleCondition.get('style.pointStyle').value === 'marker'
          "
        >
        </maplix-color-picker>
      </div>
      <div class="form-group" *ngIf="dataLayer.get('geomType').value === 'Point'">
        <label
          class="form-label"
          *ngIf="
            styleCondition.get('style.pointStyle').value !== 'icon' || dataLayer.get('styleSource').value === 'cluster'
          "
          >Fill color</label
        >
        <maplix-color-picker
          [color]="styleCondition.get('style.color').value"
          (colorChange)="onChangeColor('color', $event)"
          [hideOpacity]="true"
        >
        </maplix-color-picker>
      </div>
      <div
        class="form-group"
        *ngIf="
          dataLayer.get('geomType').value !== 'Point' &&
          (!dataLayer.get('lineType') ||
            dataLayer.get('lineType').value !== 'Route' ||
            dataLayer.get('geomType').value === 'Polygon')
        "
      >
        <label class="form-label" *ngIf="dataLayer.get('geomType').value !== 'LineString'">Border type</label>
        <label class="form-label" *ngIf="dataLayer.get('geomType').value === 'LineString'">Line type</label>
        <ng-select
          [items]="lineStyles"
          formControlName="lineStyle"
          bindValue="value"
          [clearable]="false"
          [searchable]="false"
        >
          <ng-template ng-label-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
        </ng-select>
        <!-- <maplix-selector
          [items]="lineStyles"
          [control]="styleCondition.get('style.lineStyle')"
        ></maplix-selector> -->
      </div>

      <div
        class="form-group"
        *ngIf="
          ((dataLayer.get('geomType').value === 'LineString' &&
            (!dataLayer.get('lineType') || dataLayer.get('lineType').value !== 'Route')) ||
            dataLayer.get('geomType').value === 'Polygon') &&
          styleCondition.get('style.lineStyle').value === 'dashed'
        "
      >
        <label class="form-label" *ngIf="dataLayer.get('geomType').value === 'LineString'">Line dash type</label>
        <label class="form-label" *ngIf="dataLayer.get('geomType').value === 'Polygon'">Border dash type</label>
        <!-- <maplix-selector [items]="dashStyles" [control]="styleCondition.get('style.dashStyle')"></maplix-selector> -->
        <ng-select
          [items]="dashStyles"
          formControlName="dashStyle"
          bindValue="value"
          [clearable]="false"
          [searchable]="false"
        >
          <ng-template ng-label-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="form-group"
        *ngIf="
          dataLayer.get('geomType').value === 'LineString' &&
          (!dataLayer.get('lineType') || dataLayer.get('lineType').value !== 'Route') &&
          styleCondition.get('style.lineStyle').value === 'arrow'
        "
      >
        <label for="arrowStyle" class="form-label">Arrow position</label>
        <!-- <maplix-selector [items]="arrowStyles" [control]="styleCondition.get('style.arrowStyle')"></maplix-selector> -->
        <ng-select
          [items]="arrowStyles"
          formControlName="arrowStyle"
          bindValue="value"
          [clearable]="false"
          [searchable]="false"
        >
          <ng-template ng-label-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <i [class]="item.icon"></i>
            <span class="ml-2">{{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="form-group"
        *ngIf="
          dataLayer.get('geomType').value !== 'Point' ||
          styleCondition.get('style.pointStyle').value !== 'marker' ||
          dataLayer.get('styleSource').value === 'cluster'
        "
      >
        <label class="form-label" *ngIf="dataLayer.get('geomType').value !== 'LineString'">Border color</label>
        <label class="form-label" *ngIf="dataLayer.get('geomType').value === 'LineString'">Line color</label>
        <maplix-color-picker
          [color]="
            dataLayer.get('geomType').value === 'Point'
              ? styleCondition.get('style.circleStrokeColor').value
              : styleCondition.get('style.color').value
          "
          (colorChange)="
            onChangeColor(dataLayer.get('geomType').value === 'Point' ? 'circleStrokeColor' : 'color', $event)
          "
        >
        </maplix-color-picker>
      </div>

      <div
        class="form-group"
        *ngIf="
          dataLayer.get('geomType').value !== 'Point' ||
          styleCondition.get('style.pointStyle').value !== 'marker' ||
          dataLayer.get('styleSource').value === 'cluster'
        "
      >
        <label class="form-label" *ngIf="dataLayer.get('geomType').value !== 'LineString'">Border width</label>
        <label class="form-label" *ngIf="dataLayer.get('geomType').value === 'LineString'">Line width</label>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            *ngIf="dataLayer.get('geomType').value === 'Point'"
            formControlName="circleStrokeWidth"
          />
          <input
            type="number"
            class="form-control"
            *ngIf="dataLayer.get('geomType').value !== 'Point'"
            formControlName="strokeWidth"
          />
          <div class="input-group-append">
            <span class="input-group-text bg-white">pixels</span>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="dataLayer.get('geomType').value === 'LineString'">
        <label class="form-label"
          >Border color
          <i
            class="remixicon-question-line text-muted ml-2 clickable"
            [ngbPopover]="
              'It is best to only use this when no transparency is applied to the line color. Set the border width to 0 to disable the line border.'
            "
            placement="bottom"
            container="body"
          ></i>
        </label>
        <maplix-color-picker
          [color]="styleCondition.get('style.lineBorderColor').value"
          (colorChange)="onChangeColor('lineBorderColor', $event)"
        >
        </maplix-color-picker>
      </div>
      <div class="form-group" *ngIf="dataLayer.get('geomType').value === 'LineString'">
        <label class="form-label">Border width</label>
        <div class="input-group">
          <input type="number" class="form-control" formControlName="lineBorderWidth" />
          <div class="input-group-append">
            <span class="input-group-text bg-white">pixels</span>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Icon upload -->
    <ng-container
      *ngIf="
        dataLayer.get('geomType').value === 'Point' &&
        styleCondition.get('style.pointStyle').value === 'icon' &&
        (dataLayer.get('styleSource').value === 'regular' || dataLayer.get('styleSource').value === 'rule-based')
      "
    >
      <div class="form-group">
        <label class="form-label">Icon</label>
        <maplix-icon-upload
          [file]="styleCondition.get('style.icon').value"
          [multiple]="false"
          [apiUrl]="apiUrl"
          [size]="'medium'"
          (fileChange)="onSetIcon($event)"
        >
        </maplix-icon-upload>
      </div>

      <div class="font-13 text-muted text-uppercase mb-3 mt-4">Icon options</div>

      <div class="form-group">
        <label class="form-label">Icon scale</label>
        <input type="number" class="form-control" formControlName="iconScale" />
      </div>

      <div class="form-group">
        <maplix-icon-anchor-picker
          [anchor]="styleCondition.get('style.iconAnchor')?.value"
          (anchorChange)="onChangeAnchor($event)"
        ></maplix-icon-anchor-picker>
      </div>
    </ng-container>

    <!-- Marker -->
    <ng-container
      *ngIf="
        dataLayer.get('geomType').value === 'Point' &&
        styleCondition.get('style.pointStyle').value === 'marker' &&
        (dataLayer.get('styleSource').value === 'regular' || dataLayer.get('styleSource').value === 'rule-based')
      "
    >
      <div class="form-group">
        <label class="form-label">Marker scale</label>
        <input type="number" class="form-control" formControlName="markerScale" />
      </div>
    </ng-container>
  </form>
</form>
