import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountToDirective } from './count-to.directive';
import { UiModule } from '@maplix/ui';
import { NgLetDirective } from './ng-let.directive';

@NgModule({
  declarations: [CountToDirective, NgLetDirective],
  imports: [CommonModule, UiModule],
  exports: [CountToDirective, NgLetDirective],
})
export class DirectivesModule {
  static forRoot(environment) {
    return {
      ngModule: DirectivesModule,
      providers: [{ provide: 'environment', useValue: environment }],
    };
  }
}
