import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'maplix-quill-view',
  templateUrl: './quill-view.component.html',
  styleUrls: ['./quill-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuillViewComponent implements OnInit {

  @Input() content: string;

  @Input() sanitize: boolean;

  constructor(public readonly sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  sanitizeHTML(content: string, sanitizer: DomSanitizer): SafeHtml {
    return sanitizer.bypassSecurityTrustHtml(content);
  }

}
