<div class="row no-gutters">
  <div [class]="twoColumns ? 'col-6 pr-2' : 'col-12'">
    <label class="form-label">Horizontal position</label>
    <div class="row no-gutters">
      <div class="col-4 small">Left</div>
      <div class="col-4 small text-center">Middle</div>
      <div class="col-4 small text-right">Right</div>
    </div>
    <input
      type="range"
      class="custom-range"
      [(ngModel)]="xValue"
      (ngModelChange)="onSetAnchor()"
      [min]="0"
      [max]="1"
      [step]="0.1"
    />
  </div>
  <div [class]="twoColumns ? 'col-6 pl-2' : 'col-12 mt-2'">
    <label class="form-label">Vertical position</label>
    <div class="row no-gutters">
      <div class="col-4 small">Bottom</div>
      <div class="col-4 small text-center">Middle</div>
      <div class="col-4 small text-right">Top</div>
    </div>
    <input
      type="range"
      class="custom-range"
      [(ngModel)]="yValue"
      (ngModelChange)="onSetAnchor()"
      [min]="0"
      [max]="1"
      [step]="0.1"
    />
  </div>
</div>
