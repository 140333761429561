import { Inject, Injectable } from "@angular/core";
import Plausible from 'plausible-tracker'

@Injectable({
  providedIn: 'root'
})
export class PlausibleService {
  private tracker;

  constructor(@Inject('environment') private environment: any) {}

  public initTracker() {
    this.tracker = Plausible({
      domain: this.environment.plausible.domain,
      trackLocalhost: true
    });

    this.tracker.enableAutoPageviews();
  }

  public trackEvent(eventName: string, props?: any) {
    if (props) {
      this.tracker.trackEvent(eventName, {props});
      return;
    }

    this.tracker.trackEvent(eventName);    
  }
}