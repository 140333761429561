import { Component, Input, OnInit } from '@angular/core';
import { MapClsAnalytics } from '../../class';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'maplix-map-zoom-indicator',
  templateUrl: './map-zoom-indicator.component.html',
  styleUrls: ['./map-zoom-indicator.component.scss'],
})
export class MapZoomIndicatorComponent implements OnInit {
  @Input()
  private map: MapClsAnalytics;

  private currentZoomSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  public currentZoom$: Observable<number> = this.currentZoomSubject.asObservable();

  ngOnInit(): void {
    this.updateCurrentZoom();

    this.map.map.getView().on('change', () => {
      this.updateCurrentZoom();
    });
  }

  private updateCurrentZoom() {
    const zoom = this.map.map.getView().getZoom();
    this.currentZoomSubject.next(zoom);

    const pct = Math.round((zoom / 22) * 100);
    const el = document.querySelector('#current-zoom') as HTMLElement;
    if (el) {
      el.style.left = pct + '%';
    }
  }
}
