<div id="actions-map" [ngStyle]="{height}">
  <div id="actions-map-mapbox-bg" class="mapbox-bg"></div>

  <maplix-address-search [map]="map.map" [position]="'right'"></maplix-address-search>

  <maplix-baselayer-toggle
    [maxHeight]="maxBaseToggleHeight"
    [(activeLayer)]="baseLayer"
    (activeLayerChange)="onChangeBaseLayer($event)"
  >
  </maplix-baselayer-toggle>

  <div style="visibility: hidden; position: absolute; top: 0; left: 0">
    <maplix-map-comment-popup
      [loading]="loadingComments"
      [comments]="activeComments"
      [map]="map"
      [members$]="members$"
      (createThread)="onCreateThread($event)"
      (createComment)="onCreateComment($event)"
      (replyThread)="onReplyThread($event)"
      (resolveThread)="onResolveThread($event)"
    ></maplix-map-comment-popup>
  </div>
</div>

<ng-container *ngIf="activeAction">
  <div
    class="toolbar d-flex align-items-center justify-content-between px-3"
    *ngLet="activeButton$ | async as activeButton"
  >
    <div class="d-flex align-items-center">
      <div class="mr-2" [ngbTooltip]="'map.actions.toolbar.CHANGECOLOR' | translate" position="top">
        <maplix-color-picker
          *ngIf="activeActionStyle"
          [hideOpacity]="true"
          [hideCode]="true"
          [color]="activeActionStyle?.color"
          (colorChange)="onChangeColor($event)"
        ></maplix-color-picker>
      </div>
      <button
        class="btn btn-toolbar mr-2"
        [class.active]="activeButton === 'draw-Point'"
        [ngbTooltip]="
          (activeButton === 'draw-Point' ? 'map.actions.toolbar.FINISH' : 'map.actions.toolbar.DRAWPOINT') | translate
        "
        position="top"
        (click)="onToggleButton('draw-Point')"
      >
        <i class="fas fa-map-marker-alt font-18"></i>
      </button>
      <button
        class="btn btn-toolbar mr-2"
        [class.active]="activeButton === 'draw-LineString'"
        [ngbTooltip]="
          (activeButton === 'draw-LineString' ? 'map.actions.toolbar.FINISH' : 'map.actions.toolbar.DRAWLINE')
            | translate
        "
        position="top"
        (click)="onToggleButton('draw-LineString')"
      >
        <i class="fas fa-slash font-16"></i>
      </button>
      <button
        class="btn btn-toolbar mr-2"
        [class.active]="activeButton === 'draw-Polygon'"
        [ngbTooltip]="
          (activeButton === 'draw-Polygon' ? 'map.actions.toolbar.FINISH' : 'map.actions.toolbar.DRAWPOLYGON')
            | translate
        "
        position="top"
        (click)="onToggleButton('draw-Polygon')"
      >
        <i class="remixicon-shape-line"></i>
      </button>
      <button
        class="btn btn-toolbar mr-2"
        [class.active]="activeButton === 'edit-actions'"
        [ngbTooltip]="
          (activeButton === 'edit-actions' ? 'map.actions.toolbar.FINISH' : 'map.actions.toolbar.EDITACTIONS')
            | translate
        "
        position="top"
        (click)="onToggleButton('edit-actions')"
      >
        <i class="remixicon-pencil-line"></i>
      </button>
      <button
        class="btn btn-toolbar mr-2"
        [class.active]="activeButton === 'remove-actions'"
        [ngbTooltip]="
          (activeButton === 'remove-actions' ? 'map.actions.toolbar.FINISH' : 'map.actions.toolbar.REMOVEACTIONS')
            | translate
        "
        position="top"
        (click)="onToggleButton('remove-actions')"
      >
        <i class="remixicon-delete-bin-6-line"></i>
      </button>
    </div>

    <div class="d-flex align-items-center" *ngLet="showComments$ | async as showComments">
      <!-- <button
      *ngIf="hasCommentThreads"
      class="btn btn-toolbar ml-2"
      [ngbTooltip]="
        (showComments ? 'map.actions.toolbar.HIDECOMMENTS' : 'map.actions.toolbar.SHOWCOMMENTS') | translate
      "
      position="top"
      (click)="onToggleComments()"
    >
      <i [class]="showComments ? 'remixicon-question-answer-line text-danger' : 'remixicon-question-answer-fill'"></i>
    </button> -->
      <button
        class="btn btn-toolbar ml-2"
        [class.active]="activeButton === 'comment'"
        [ngbTooltip]="
          (activeButton === 'comment' ? 'map.actions.toolbar.CANCEL' : 'map.actions.toolbar.DRAWCOMMENT') | translate
        "
        position="top"
        (click)="onToggleButton('comment')"
      >
        <i class="remixicon-chat-new-line"></i>
      </button>
      <button
        class="btn btn-toolbar ml-2"
        [ngbTooltip]="'map.actions.toolbar.HIDEMAP' | translate"
        position="top"
        (click)="onHideMap()"
      >
        <i class="remixicon-collapse-diagonal-line"></i>
      </button>
    </div>
  </div>
</ng-container>
