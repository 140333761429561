import { Map, View } from 'ol';
import { defaults as DefaultInteractions } from 'ol/interaction.js';
import Projection from 'ol/proj/Projection';
import { Vector as VectorLayer, Image as ImageLayer } from 'ol/layer.js';
import VectorSource from 'ol/source/Vector.js';
import { SafeUrl } from '@angular/platform-browser';
import Static from 'ol/source/ImageStatic';
import { Extent, getCenter } from 'ol/extent';
import * as styleUtils from '@maplix/utils';
import { TranslateService } from '@ngx-translate/core';
import { MapClsGeneric } from './mapcls-generic';
import { HttpClient } from '@angular/common/http';
import { Coordinate } from 'ol/coordinate';

export class ImageCls extends MapClsGeneric {
  constructor(
    public target: string,
    protected translate: TranslateService,
    protected http: HttpClient,
    environment: any,
    protected image: string,
    protected imageExtent: Extent,
    center?: Coordinate,
    zoom?: number
  ) {
    super(target, translate, http, environment);
    this.init(center, zoom);
  }

  init(center?: Coordinate, zoom?: number) {
    this.projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: this.imageExtent,
    });

    this.map = new Map({
      target: this.target,
      layers: [this.getBaseImage(this.image)],
      interactions: DefaultInteractions({
        doubleClickZoom: true,
        mouseWheelZoom: true,
      }),
      view: new View({
        projection: this.projection,
        extent: [-this.imageExtent[2], -this.imageExtent[3], this.imageExtent[2] * 2, this.imageExtent[3] * 2],
        center: center ?? getCenter(this.imageExtent),
        zoom: zoom ?? 2,
        maxZoom: 7,
      }),
      moveTolerance: this.isTouchScreen ? 6 : 1,
    });

    this.map.getView().on('change', () => {
      this.center.next(this.map.getView().getCenter());
      this.zoom.next(this.map.getView().getZoom());
    });
  }

  private getBaseImage(image: string | SafeUrl) {
    const layer = new ImageLayer({
      source: new Static({
        url: image as string,
        projection: this.projection,
        imageExtent: this.imageExtent,
        crossOrigin: 'anonymous',
      }),
      visible: true,
    });
    layer.set('base', true);
    layer.set('name', 'base-image');
    return layer;
  }

  public addDrawLayer() {
    this.lDraw = new VectorLayer({
      source: new VectorSource({}),
      style: (f) => {
        return styleUtils.drawStyle(f);
      },
      zIndex: 40000,
    });

    this.lDraw.set('base', false);
    this.lDraw.set('name', 'lDraw');

    this.map.addLayer(this.lDraw);
  }
}
