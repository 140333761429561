import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DestroyComponent } from '@maplix/ng-utils';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { takeUntil, tap } from 'rxjs/operators';

export type ColorType = 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'light' | 'dark';

export type ButtonSize = 'xs' | 'sm' | 'lg';

export type Placement = 'top' | 'left' | 'right' | 'bottom';

@Component({
  selector: 'maplix-confirm-button',
  templateUrl: './confirm-button.component.html',
  styleUrls: ['./confirm-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmButtonComponent extends DestroyComponent implements OnInit {
  //button variables

  @Input()
  public alertIcon: string = 'warning';

  @Input()
  public isDropdownItem: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  private buttonType: ColorType = 'primary';

  @Input()
  public customButtonClass: string;

  @Input()
  private buttonSize: ButtonSize;

  public get buttonSizeClass(): string {
    if (this.buttonSize) {
      return `btn btn-${this.buttonSize} `;
    } else {
      return 'btn ';
    }
  }

  public get buttonTypeClass(): string {
    return `btn-${this.buttonType} `;
  }

  public get confirmButtonTypeClass(): string {
    return `btn-${this.confirmButtonType} `;
  }

  //popover variables

  @Input()
  public placement: Placement = 'top';

  @Input()
  public popoverTitle: string;

  @Input()
  public popoverMessage: string;

  @Input()
  public confirmText: string = 'Ok';

  @Input()
  public confirmButtonType: ColorType;

  public get popoverClass(): string {
    return `popover-confirm`;
  }

  @Input()
  public hideCancelButton: boolean = false;

  @Input()
  public cancelText: string;

  @Input()
  public cancelButtonType: ColorType = 'light';

  @Output()
  private confirm: EventEmitter<void> = new EventEmitter();

  @Output()
  private cancel: EventEmitter<void> = new EventEmitter();

  @ViewChild('mySwal', { static: false })
  private swal: SwalComponent;

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    if (!this.confirmButtonType) {
      this.confirmButtonType = this.buttonType;
    }

    if (!this.cancelText) {
      this.translate
        .stream('generic.buttons.CANCEL')
        .pipe(
          tap((value) => {
            this.cancelText = value;
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }

  public onConfirm(): void {
    this.confirm.emit();
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public onFireSwal(): void {
    this.swal
      .fire()
      .then((result) => {
        if (result.value) {
          this.onConfirm();
        } else {
          this.onCancel();
        }
      })
      .catch(() => this.onCancel());
  }
}
