export function removeEmptyProperties(obj: any) {
  if (Array.isArray(obj)) {
    for (let item of obj) {
      item = removeEmptyProperties(item);
    }
  } else {
    for (const property in obj) {
      if (obj[property] === undefined || obj[property] === null || obj[property] === '') {
        delete obj[property];
      } else if (typeof obj[property] === 'object' && !(obj instanceof Date)) {
        obj[property] = removeEmptyProperties(obj[property]);
      }
    }
  }

  return obj;
}
