import { CreateModalWrapperComponent, OverviewModalWrapperComponent } from '@maplix/cloud';
import { FormArray, FormGroup } from '@maplix/forms';
import {
  IMapDataLayer,
  IMapResult,
  IMapVector,
  IStyleCondition,
  ISurveyImage,
  ISurveyMap,
  MapType,
  ObjectId,
  ToastrNotification,
} from '@maplix/utils';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { ImageCls, MapCls } from '.';
import { ImportSurveyLayersComponent } from '../components/import-survey-layers/import-survey-layers.component';
import { ImportUploadLayersComponent } from '../components/import-upload-layers/import-upload-layers.component';
import { ImportWmsLayersComponent } from '../components/import-wms-layers/import-wms-layers.component';
import { MapLayerService } from '../services';

export class DataLayerHandler {
  private layerModal: NgbModalRef;

  private skipSurvey: ObjectId;

  private previousLayerValue: IMapDataLayer;

  public selectedIndex: number;

  constructor(
    protected mapType: MapType,
    protected map: MapCls | ImageCls,
    protected mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage>,
    protected environment: any,
    protected mapService: MapLayerService,
    protected modalService: NgbModal,
    protected notification: ToastrNotification
  ) {}

  public setSurveyToSkip(survey: ObjectId) {
    this.skipSurvey = survey;
  }

  public async onOpenLayerModal($event) {
    const { type, layer, fullSurvey, isNew, fromCloud } = $event;

    switch (type) {
      case 'SURVEY':
        this.layerModal = this.modalService.open(ImportSurveyLayersComponent, {
          size: 'lg',
          beforeDismiss: () => this.beforeModalDismiss(type, isNew),
          windowClass: 'draggable-modal',
        });

        this.layerModal.componentInstance.selectedSurvey = fullSurvey;
        this.layerModal.componentInstance.surveyLayer = layer;
        this.layerModal.componentInstance.mapType = this.mapType;
        break;
      case 'WMS':
        this.layerModal = this.modalService.open(ImportWmsLayersComponent, {
          size: 'lg',
          beforeDismiss: () => this.beforeModalDismiss(type, isNew),
          windowClass: 'draggable-modal',
        });

        this.layerModal.componentInstance.wmsLayer = layer;
        this.layerModal.componentInstance.fromCloud = fromCloud;
        break;
      case 'UPLOAD':
        this.layerModal = this.modalService.open(ImportUploadLayersComponent, {
          size: 'lg',
          beforeDismiss: () => this.beforeModalDismiss(type, isNew),
          windowClass: 'draggable-modal',
        });

        this.layerModal.componentInstance.uploadLayer = layer;
        this.layerModal.componentInstance.mapType = this.mapType;
        break;
    }

    this.layerModal.componentInstance.mapForm = this.mapForm;
    this.layerModal.componentInstance.new = isNew;
    this.layerModal.componentInstance.applyFunction = this.handleLayerChanges.bind(this);

    const reason = await this.layerModal.result.catch(() => {
      if (!isNew) {
        this.selectedIndex = undefined;
        return;
      }

      this.onDeleteLayer(layer);
    });

    this.handleLayerChanges(layer, reason);
  }

  private beforeModalDismiss(type: string, isNew: boolean) {
    let layer: FormGroup<any>;
    switch (type) {
      case 'SURVEY':
        layer = this.layerModal.componentInstance.surveyLayer;
        break;
      case 'UPLOAD':
        layer = this.layerModal.componentInstance.uploadLayer;
        return isNew || !!layer.get('typeName').value;
      case 'WMS':
        layer = this.layerModal.componentInstance.wmsLayer;
        break;
    }

    return (
      isNew ||
      !!((layer.get('layers') as FormArray<any>).controls.filter((l) => l.get('selectedInModal').value).length >= 1)
    );
  }

  public async onOpenCloudModal(filter: 'datasets' | 'surveys' = 'datasets', previousValue?: IMapDataLayer) {
    this.previousLayerValue = previousValue;

    const cloudModal = this.modalService.open(OverviewModalWrapperComponent, {
      size: 'xl',
    });

    cloudModal.componentInstance.showLightButton = true;
    cloudModal.componentInstance.showPrimaryButton = false;
    cloudModal.componentInstance.apiUrl = this.environment.api;
    cloudModal.componentInstance.skipSurvey = this.skipSurvey;
    cloudModal.componentInstance.filter = filter;
    cloudModal.componentInstance.selectedElement = this.previousLayerValue
      ? {
          _id: (<any>this.previousLayerValue)._id
            ? (<any>this.previousLayerValue)._id
            : (<any>this.previousLayerValue).survey,
        }
      : null;

    let selectedDataset = await cloudModal.result;

    if (!selectedDataset) {
      return;
    }

    let layer: FormGroup<IMapDataLayer>;
    const type = selectedDataset.type;

    if (selectedDataset.isNewDataset) {
      const createCloudModal = this.modalService.open(CreateModalWrapperComponent);
      createCloudModal.componentInstance.showLightButton = true;
      createCloudModal.componentInstance.showPrimaryButton = true;
      createCloudModal.componentInstance.newDataset = type;
      createCloudModal.componentInstance.apiUrl = this.environment.api;
      createCloudModal.componentInstance.geoserverUrl = this.environment.geoserver;

      const newDataset = await createCloudModal.result;
      if (!newDataset) {
        return;
      }
      selectedDataset = { ...newDataset };

      if (type === 'UPLOAD') {
        this.notification.warning(selectedDataset.name, 'Processing dataset');
        const data = await this.onOpenCloudModal('datasets');
        return data;
      }
    }

    switch (type) {
      case 'UPLOAD':
        layer = this.mapService.createUploadLayer(selectedDataset);
        layer.get('typeName').markAsDirty();
        break;
      case 'SURVEY':
        layer = this.mapService.createSurveyLayer(this.mapType, selectedDataset.fullSurvey);
        layer.get('survey').markAsDirty();
        break;
      case 'WMS':
        layer = this.mapService.createWMSLayer(selectedDataset);
        layer.get('url').markAsDirty();
        break;
    }

    if (layer && !this.previousLayerValue) {
      (this.mapForm.get('dataLayers') as FormArray<IMapDataLayer>).push(layer);

      // this.onOpenLayerModal({
      //   type,
      //   layer,
      //   fullSurvey: selectedDataset.fullSurvey,
      //   isNew: true,
      // });

      return {
        type,
        layer,
        fullSurvey: selectedDataset.fullSurvey,
        isNew: true,
      };
    }

    if (this.previousLayerValue) {
      (this.mapForm.get('dataLayers') as FormArray<IMapDataLayer>).removeAt(this.selectedIndex);
      (this.mapForm.get('dataLayers') as FormArray<IMapDataLayer>).insert(this.selectedIndex, layer);

      // this.onOpenLayerModal({
      //   type,
      //   layer,
      //   fullSurvey: selectedDataset.fullSurvey,
      //   isNew: false,
      //   fromCloud: true,
      // });

      return {
        type,
        layer,
        fullSurvey: selectedDataset.fullSurvey,
        isNew: false,
        fromCloud: true,
      };
    }
  }

  private isDatasetChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed = false;
    if (layer.get('typeName') && layer.get('typeName').dirty) {
      // console.log('dataset changed (typeName)');
      changed = true;
    }

    if (layer.get('survey') && layer.get('survey').dirty) {
      // console.log('dataset changed (survey)');
      changed = true;
    }

    if (layer.get('url') && layer.get('url').dirty) {
      // console.log('dataset changed (url)');
      changed = true;
    }

    return changed;
  }

  private isLayersChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('layers')) {
      layer.get('layers')['controls'].forEach((layerControl) => {
        if (layerControl.get('selectedInModal').dirty) {
          changed = true;
        }
      });
    }

    // if (changed) console.log('layers changed', changed);

    return changed;
  }

  private isCombineSublayersChanged(layer: FormGroup<IMapDataLayer>): boolean {
    if (layer.get('combineSubLayers') && layer.get('combineSubLayers').dirty) {
      // console.log('combineSubLayers changed')
      return true;
    }

    return false;
  }

  private isRulesChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('styleConditions')) {
      layer.get('styleConditions')['controls'].forEach((styleCondition) => {
        if (styleCondition.get('rules').dirty) {
          changed = true;
        }
      });
    }

    if (!changed && layer.get('layers')) {
      layer.get('layers')['controls'].forEach((layerControl) => {
        if (layerControl.get('styleConditions')) {
          layerControl.get('styleConditions')['controls'].forEach((styleCondition) => {
            if (styleCondition.get('rules').dirty) {
              changed = true;
            }
          });
        }
      });
    }

    // if (changed) console.log('rules changed', changed);

    return changed;
  }

  private isConditionsChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('styleConditions') && (layer.get('styleConditions') as FormArray<IStyleCondition>).arrayChanged) {
      changed = true;
    }

    if (!changed && layer.get('layers')) {
      layer.get('layers')['controls'].forEach((layerControl) => {
        if (
          layerControl.get('styleConditions') &&
          (layerControl.get('styleConditions') as FormArray<IStyleCondition>).arrayChanged
        ) {
          changed = true;
        }
      });
    }

    // if (changed) console.log('styleConditions arrayChanged', changed)

    return changed;
  }

  private isPopupOptionsChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('isSelectable') && layer.get('isSelectable').dirty) {
      changed = true;
    }

    return changed;
  }

  private isStyleChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('opacity') && layer.get('opacity').dirty) {
      changed = true;
    }

    if (!changed && layer.get('styleConditions')) {
      layer.get('styleConditions')['controls'].forEach((styleCondition) => {
        if (styleCondition.get('style').dirty) {
          changed = true;
        }
        if (styleCondition.get('label')?.dirty) {
          changed = true;
        }
      });
    }

    if (!changed && layer.get('layers')) {
      layer.get('layers')['controls'].forEach((layerControl) => {
        if (layerControl.get('styleConditions')) {
          layerControl.get('styleConditions')['controls'].forEach((styleCondition) => {
            if (styleCondition.get('style').dirty) {
              changed = true;
            }
            if (styleCondition.get('label')?.dirty) {
              changed = true;
            }
          });
        }
      });
    }

    // if (changed) console.log('style dirty', changed)

    return changed;
  }

  private isStyleSourceChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('styleSource') && layer.get('styleSource').dirty) {
      changed = true;
    }

    if (!changed && layer.get('layers')) {
      layer.get('layers')['controls'].forEach((layerControl) => {
        if (layerControl.get('styleSource') && layerControl.get('styleSource').dirty) {
          changed = true;
        }
      });
    }

    // if (changed) console.log('styleSource dirty', changed)

    return changed;
  }

  private isZoomRangeChanged(layer: FormGroup<IMapDataLayer>): boolean {
    let changed: boolean = false;

    if (layer.get('minZoom') && layer.get('minZoom').dirty) {
      changed = true;
    }

    if (layer.get('maxZoom') && layer.get('maxZoom').dirty) {
      changed = true;
    }

    // if (changed) console.log('minZoom or maxZoom dirty', changed)

    return changed;
  }

  private markLayerAsPristine(layer: FormGroup<IMapDataLayer>): void {
    if (layer.get('url')) {
      layer.get('url').markAsPristine();
    }

    if (layer.get('combineSubLayers')) {
      layer.get('combineSubLayers').markAsPristine();
    }

    if (layer.get('styleSource')) {
      layer.get('styleSource').markAsPristine();
    }

    if (layer.get('minZoom')) {
      layer.get('minZoom').markAsPristine();
    }

    if (layer.get('maxZoom')) {
      layer.get('maxZoom').markAsPristine();
    }

    if (layer.get('typeName')) {
      layer.get('typeName').markAsPristine();
    }

    if (layer.get('survey')) {
      layer.get('survey').markAsPristine();
    }

    if (layer.get('opacity')) {
      layer.get('opacity').markAsPristine();
    }

    if (layer.get('styleConditions')) {
      (layer.get('styleConditions') as FormArray<IStyleCondition>).markArrayAsUnchanged();

      layer.get('styleConditions')['controls'].forEach((styleCondition) => {
        styleCondition.get('rules').markAsPristine();
        styleCondition.get('style').markAsPristine();
        styleCondition.get('label')?.markAsPristine();
      });
    }

    if (layer.get('layers')) {
      layer.get('layers')['controls'].forEach((layerControl) => {
        if (layerControl.get('styleSource')) {
          layerControl.get('styleSource').markAsPristine();
        }

        if (layerControl.get('selectedInModal')) {
          layerControl.get('selectedInModal').markAsPristine();
        }

        if (layerControl.get('styleConditions')) {
          (layerControl.get('styleConditions') as FormArray<IStyleCondition>).markArrayAsUnchanged();

          layerControl.get('styleConditions')['controls'].forEach((styleCondition) => {
            styleCondition.get('rules').markAsPristine();
            styleCondition.get('style').markAsPristine();
            styleCondition.get('label')?.markAsPristine();
          });
        }
      });
    }
  }

  /**
   * If dataset was changed (typeName dirty): remove and add layer
   * If sublayers were changed (layer.selectedInModal dirty): remove and add layer
   * If combineSubLayers (combineSubLayers dirty) was changed: remove and add layer
   * If styleSource was changed (styleSource dirty): remove and add layer
   * If styling was changed (styleCondition.style dirty): refresh style
   * Rule-based: if rule was added or removed (custom), or conditions of a rule were changed (styleCondition.rules dirty): remove and add layer
   * Rule-based: if style of a rule was changed (styleCondition.style dirty): refresh style
   * If legend labels were changed: update popup title (layer.title dirty)
   */
  public handleLayerChanges(layer: FormGroup<IMapDataLayer>, reason?: any) {
    if (reason && reason.type === 'Open cloud') {
      this.onOpenCloudModal(reason.filter, reason.previousValue);
      return;
    }

    if (reason === 'Done') {
      this.selectedIndex = undefined;
    }

    let zIndex: number;
    let shouldAdd = false;
    // When delete was clicked, or the dataset or sublayers were changed, remove the layer
    if (
      reason !== 'Add' &&
      (reason === 'Deleted' ||
        this.isDatasetChanged(layer) ||
        this.isLayersChanged(layer) ||
        this.isCombineSublayersChanged(layer) ||
        this.isStyleSourceChanged(layer) ||
        this.isConditionsChanged(layer) ||
        this.isRulesChanged(layer))
    ) {
      // console.log('should remove layer');
      zIndex = this.mapService.onRemoveLayer(
        this.map,
        this.previousLayerValue ? this.previousLayerValue : layer.getRawValue()
      );

      this.previousLayerValue = null;

      shouldAdd = true;

      if (reason === 'Deleted') {
        return;
      }
    }

    // If new layer or the dataset or sublayers were changed, add to map
    if (reason === 'Add' || shouldAdd) {
      // console.log('should add layer')
      this.mapService.onAddLayer(this.map, layer.getRawValue(), this.mapType, zIndex);
      this.markLayerAsPristine(layer);
      return;
    }

    // If only the styles were changed, we just update the styling
    if (this.isStyleChanged(layer)) {
      this.mapService.onRefreshLayerStyle(this.map, layer.getRawValue());
    }

    // If only zoom levels were changed, just update zoomlevels
    if (this.isZoomRangeChanged(layer)) {
      this.mapService.onRefreshLayerZoomLevels(this.map, layer.getRawValue() as IMapVector);
    }

    // If only the selectable property is changed, just update the layer property
    if (this.isPopupOptionsChanged(layer)) {
      // console.log('should set selectable')
      this.mapService.setLayerSelectable(this.map, layer.getRawValue() as IMapVector);
    }

    this.markLayerAsPristine(layer);
  }

  public onDeleteLayer(dataLayer: IMapDataLayer) {
    const index = (this.mapForm.getControl('dataLayers') as FormArray<IMapDataLayer>).controls.findIndex(
      (layer: FormGroup<IMapDataLayer>) => layer.getFormControl('id').value === dataLayer.id
    );

    (this.mapForm.getControl('dataLayers') as FormArray<IMapDataLayer>).removeAt(index);

    this.mapService.onRemoveLayer(this.map, dataLayer);
  }
}
