import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'maplix-grid-list-toggle',
  templateUrl: './grid-list-toggle.component.html',
  styleUrls: ['./grid-list-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListToggleComponent implements OnInit {

  @Input()
  public selectedView: string = 'grid';

  @Output()
  private selectedViewChange: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onToggleSelectedView() {
    this.selectedView = this.selectedView === 'grid' ? 'list' : 'grid';
    this.selectedViewChange.emit(this.selectedView);
  }

}
