import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ILanguage, IMapDataLayer, IMapResult, IPopupInfo, ISurveyImage, ISurveyMap, MapType } from '@maplix/utils';
import { FormGroup } from '@maplix/forms';
import { Map } from 'ol';
import { ILegendUrl } from '../../class';
import { onToggleLayerVisibility } from '../../utils/toggle-layer-visibility';
import { Subject } from 'rxjs';
import VectorImageLayer from 'ol/layer/VectorImage';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import VectorSource from 'ol/source/Vector';

@Component({
  selector: 'maplix-map-full',
  templateUrl: './map-full.component.html',
  styleUrls: ['./map-full.component.scss'],
})
export class MapFullComponent implements OnInit, OnDestroy {
  @Input()
  public readonly loading: boolean;

  @Input()
  public readonly mapType: MapType = MapType.MAP;

  @Input()
  public mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage>;

  @Input()
  public readonly map: Map;

  @Input()
  public WMSLegendUrls: ILegendUrl;

  @Input()
  public popupInfo: IPopupInfo;

  @Input()
  public readonly language: ILanguage;

  @Input()
  public readonly editable: boolean;

  @Input()
  public readonly mapId: string;

  @Input()
  public readonly fullscreenBox: string = 'fullscreenBox';

  @Input()
  public readonly height: string;

  @Input()
  public readonly legendText: string = 'Legend';

  @Input()
  public readonly searchPlaceholder: string = 'Search location';

  @Input()
  public showBaseToggle: boolean = true;

  public isMobile: boolean = window.screen.availWidth <= 768;

  public featuresInView: number = 0;

  private destroyed: Subject<void> = new Subject();

  private viewChange: EventsKey;
  private layerChanges: EventsKey[] = [];
  private layerArrayChange: EventsKey;

  constructor() {}

  ngOnInit() {
    this.findFeaturesInView();

    this.viewChange = this.map.getView().on('change', () => {
      this.findFeaturesInView();
    });

    this.map.getLayers().on('change:length', () => {
      this.map
        .getLayers()
        .getArray()
        .filter((layer) => layer.getVisible() && layer instanceof VectorImageLayer && !layer.get('base'))
        .forEach((layer: VectorImageLayer<VectorSource>) => {
          this.layerChanges.push(
            layer.on('change:visible', () => {
              this.findFeaturesInView();
            })
          );
          this.layerChanges.push(
            layer.getSource().on('addfeature', () => {
              this.findFeaturesInView();
            }) as EventsKey
          );
          this.layerChanges.push(
            layer.getSource().on('removefeature', () => {
              this.findFeaturesInView();
            }) as EventsKey
          );
        });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();

    unByKey(this.viewChange);
    unByKey(this.layerArrayChange);
    this.layerChanges.forEach((change) => {
      unByKey(change);
    });
  }

  public onChangeBaseLayer(layer: string) {
    if ('baseLayer' in this.mapForm.getRawValue()) {
      (this.mapForm as FormGroup<IMapResult>).getControl('baseLayer').setValue(layer);
    } else {
      (this.mapForm as FormGroup<ISurveyMap>).getControl('defaultBaseLayer').setValue(layer);
    }

    this.map.getLayers().forEach((l) => {
      if (l.get('name') !== layer && l.getVisible() && l.get('base')) {
        l.setVisible(false);
      } else if (l.get('name') === layer) {
        l.setVisible(true);
      }
    });
  }

  public async onChangeVisibility(layer: FormGroup<IMapDataLayer>) {
    onToggleLayerVisibility({
      layer,
      map: this.map,
    });
  }

  private findFeaturesInView() {
    if (!this.map) {
      return;
    }

    let amount = 0;
    this.map
      .getLayers()
      .getArray()
      .filter((layer) => layer.getVisible() && layer instanceof VectorImageLayer && !layer.get('base'))
      .forEach((layer: VectorImageLayer<VectorSource>) => {
        const features = layer.getSource().getFeaturesInExtent(this.map.getView().calculateExtent());
        amount += features.length;
      });

    this.featuresInView = amount;
  }
}
