<p *ngIf="!answer?.extra || !answer?.extra?.multiple">
  {{
    answer.value_label === 'OTHER' || answer.value_label === 'OPTOTHER'
      ? ('survey.OTHER' | translate)
      : answer.value_label
      ? answer.value_label
      : answer.value
  }}
  {{ answer.value_other ? ': ' + answer.value_other : '' }}
</p>

<ul class="mb-1" *ngIf="answer?.extra && answer?.extra?.multiple">
  <li>
    {{
      answer.value_label === 'OTHER' || answer.value_label === 'OPTOTHER'
        ? ('survey.OTHER' | translate)
        : answer.value_label
        ? answer.value_label
        : answer.value
    }}
    {{ answer.value_other ? ': ' + answer.value_other : '' }}
  </li>
</ul>
