import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ILayerDataset, ISurveyLayerDataset } from '../../interfaces';
import { NotificationsService, WorkspaceService } from '@maplix/services';
import { ISurvey, ObjectId } from '@maplix/utils';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@maplix/forms';

interface IDataGroup {
  minDate: moment.Moment;
  maxDate: moment.Moment;
  label: string;
}

@Component({
  selector: 'maplix-dataset-overview',
  templateUrl: './dataset-overview.component.html',
  styleUrls: ['./dataset-overview.component.scss'],
})
export class DatasetOverviewComponent implements OnInit, OnDestroy {
  @Input()
  private readonly apiUrl: string;

  @Input()
  private readonly skipSurvey: string;

  @Input()
  private readonly filter: string;

  @Input()
  public selectedDataset: ILayerDataset;

  @Input()
  public selectedSurvey: ISurvey;

  @Input()
  public showFilter: boolean;

  @Output()
  private readonly onSelect: EventEmitter<ILayerDataset> = new EventEmitter();

  @Output()
  private readonly onCreate: EventEmitter<string> = new EventEmitter();

  private destroyed: Subject<void> = new Subject();

  public dataSets: any[];

  public surveys: ISurvey[];

  public dataGroups: IDataGroup[] = [
    {
      minDate: moment().startOf('day'),
      maxDate: moment().endOf('day'),
      label: 'Today',
    },
    {
      minDate: moment().subtract(6, 'days').startOf('day'),
      maxDate: moment().subtract(1, 'days').endOf('day'),
      label: 'Earlier this week',
    },
    {
      minDate: moment().subtract(1, 'month').add(1, 'day').startOf('day'),
      maxDate: moment().subtract(7, 'days').endOf('day'),
      label: 'Last month',
    },
    {
      minDate: moment('1970-01-01').startOf('day'),
      maxDate: moment().subtract(1, 'month').endOf('day'),
      label: 'Older',
    },
  ];

  public selectedView: string = 'grid';

  public selectedFilterControl: FormControl<string> = new FormControl();
  private selectedFilter: string;

  public loading: boolean;

  public detailSelected: ILayerDataset;

  public page: number = 1;

  public pageSize: number = 20;

  public totalItems: number = 0;

  public searchTerm: string;

  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private workspaceService: WorkspaceService
  ) {}

  ngOnInit() {
    this.selectedFilterControl.setValue(this.filter);

    this.selectedFilterControl.value$
      .pipe(
        tap((filter: string) => {
          // Reset page when filter changed
          if (filter !== this.selectedFilter) {
            this.page = 1;
          }
          this.selectedFilter = filter;

          let searchFilter = '';

          switch (filter) {
            case 'datasets':
              this.loading = true;
              if (this.searchTerm) {
                searchFilter = `, "name":{"$regex":".*(?i)${this.searchTerm}.*"}`;
              }
              this.http
                .get<any>(
                  `${this.apiUrl}layers?where={"status": { "$ne": "FAILED" }${searchFilter}}&max_results=${this.pageSize}&page=${this.page}&sort=[("_created", -1)]&embedded={"creator":1}`
                )
                .pipe(
                  tap((response) => {
                    this.dataSets = response._items;
                    this.totalItems = response._meta.total;
                    this.loading = false;
                  }),
                  takeUntil(this.destroyed)
                )
                .subscribe();
              break;
            case 'surveys':
              this.loading = true;
              if (this.searchTerm) {
                searchFilter = `, "general.title.value":{"$regex":".*(?i)${this.searchTerm}.*"}`;
              }
              this.http
                .get<any>(
                  `${this.apiUrl}surveys?where={"organisation":"${
                    this.workspaceService.getActiveWorkspace()._id
                  }", "questions.mapInteractions.0": {"$exists": true}${searchFilter}}&max_results=${
                    this.pageSize
                  }&page=${this.page}&sort=[("_created", -1)]`
                )
                .pipe(
                  tap((response) => {
                    this.surveys = response._items.filter((survey) => survey._id !== this.skipSurvey);
                    this.totalItems = response._meta.total;
                    this.loading = false;
                  }),
                  takeUntil(this.destroyed)
                )
                .subscribe();
              break;
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();

    this.notificationsService.newNotifications$
      .pipe(
        filter((notis) => !!notis.find((noti) => noti.layerId && noti.status !== 'PENDING')),
        tap(() => {
          this.selectedFilterControl.setValue(this.filter);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public getNewPage($event: number) {
    this.page = $event ? $event : 1;
    this.selectedFilterControl.setValue(this.selectedFilterControl.value);
  }

  public onFilterDatasets(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.page = 1;
    this.selectedFilterControl.setValue(this.selectedFilterControl.value);
  }

  public getDatasetsForGroup(dataSets: any[], group: IDataGroup) {
    if (!dataSets) {
      return;
    }

    return dataSets.filter((dataset) =>
      moment(dataset._created).isBetween(group.minDate, group.maxDate, 'second', '[]')
    );
  }

  public onSelectDataset(dataset: ILayerDataset) {
    if (!dataset || (this.selectedDataset && this.selectedDataset._id === dataset._id)) {
      this.selectedDataset = null;
      this.onSelect.emit(null);
      return;
    }

    this.selectedDataset = dataset;
    this.onSelect.emit(this.selectedDataset);
  }

  public onDeleteDataset(dataset: ILayerDataset) {
    if (!dataset) {
      return;
    }

    this.detailSelected = null;
    this.loading = true;

    this.http
      .delete(`${this.apiUrl}layers/${dataset._id}`)
      .pipe(
        tap(() => {
          this.toastr.success('Dataset deleted successfully');
          this.dataSets = this.dataSets.filter((set) => set._id !== dataset._id);
          this.loading = false;
        }),
        catchError((err) => {
          this.toastr.error('Failed to delete dataset');
          return throwError(err);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public onSelectSurvey(survey: ISurvey) {
    if (!survey || (this.selectedSurvey && this.selectedSurvey._id === survey._id)) {
      this.selectedSurvey = null;
      this.onSelect.emit(null);
      return;
    }

    this.selectedSurvey = survey;

    this.onSelect.emit({
      type: 'SURVEY',
      survey: survey._id,
      fullSurvey: survey,
      name: this.getDefaultSurveyTitle(survey),
    } as ISurveyLayerDataset);
  }

  public onCreateNewDataset(type: string) {
    this.onCreate.emit(type);
  }

  public onSelectFilter(filter: string) {
    this.page = 1;
    this.selectedFilterControl.setValue(filter);
  }

  public getDefaultSurveyTitle(survey: ISurvey) {
    if (!survey) {
      return;
    }

    const defaultLang = survey.general.defaultLanguage.code;

    return survey.general.title.find((title) => title.i18n === defaultLang).value;
  }

  public onShowDetails(dataset: ILayerDataset) {
    setTimeout(() => {
      this.detailSelected = dataset;
    });
  }

  public onHideDetails() {
    this.detailSelected = undefined;
  }

  public onCopyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.toastr.info('URL copied to clipboard.');
  }
}
