import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILayerDataset } from '../../interfaces';

@Component({
  selector: 'maplix-dataset-detail',
  templateUrl: './dataset-detail.component.html',
  styleUrls: ['./dataset-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatasetDetailComponent {
  @Input()
  public detailSelected: ILayerDataset;

  @Output()
  public onDelete: EventEmitter<void> = new EventEmitter();

  @Output()
  public onClose: EventEmitter<void> = new EventEmitter();

  @Output()
  public copyUrl: EventEmitter<string> = new EventEmitter();

  constructor() {}

  public onDeleteDataset() {
    this.onDelete.emit();
  }

  public onCloseDetail() {
    this.onClose.emit();
  }

  public onCopyToClipboard(text: string) {
    this.copyUrl.emit(text);
  }
}
