import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import * as moment from 'moment';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { WorkspaceService } from './workspace.service';
import { Router } from '@angular/router';
import { NotificationType } from '@maplix/utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private previousNotifications: any[] = [];

  private stopPolling$: Subject<void> = new Subject();

  private triggerPolling$: Subject<void> = new Subject();

  private pollingTries = 0;

  private isPolling: boolean;

  private newNotificationsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public newNotifications$ = this.newNotificationsSubject.asObservable();

  private notificationsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public notifications$ = this.notificationsSubject.asObservable();

  public viewId: string;

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    private toastr: ToastrService,
    private workspaceService: WorkspaceService,
    private router: Router
  ) {}

  public get notifications(): any[] {
    return this.notificationsSubject.value;
  }

  public loadNotifications(max: number = 20): Observable<any> {
    return this.getNotifications(true, max).pipe(
      tap((notifications: any[]) => {
        // start polling automatically if the initial call has a pending layer in it
        if (notifications.find((noti) => noti.status === 'PENDING')) {
          this.startPollingNotifications(5000);
        }
      })
    );
  }

  public triggerPollingNotifcations() {
    this.triggerPolling$.next();
  }

  public startPollingNotifications(delay: number = 0): void {
    if (this.isPolling) {
      return;
    }
    this.isPolling = true;
    this.pollingTries = 1;
    timer(delay, 2500)
      .pipe(
        switchMap(() => this.getNotifications()),
        takeUntil(this.stopPolling$)
      )
      .subscribe();
  }

  public stopPollingNotifications() {
    this.stopPolling$.next();
    this.pollingTries = 0;
    this.isPolling = false;
  }

  private getNotifications(firstTry?: boolean, max: number = 20): Observable<any> {
    return this.http
      .get<any>(
        `${this.environment.api}notifications?where={"$or": [{"organisation": null}, {"organisation": "${
          this.workspaceService.getActiveWorkspace()._id
        }"}]}&sort=[("_created", -1)]&max_results=${max}`
      )
      .pipe(
        tap((response) => {
          this.viewId = response._meta.view;
        }),
        map(({ _items }) => _items),
        tap((notifications: any[]) => {
          this.notificationsSubject.next(notifications);
          this.pollingTries += 1;

          const newNotis = notifications.filter(
            (noti) => noti._fresh && !this.previousNotifications.map((prev) => prev._id).includes(noti._id) && !firstTry
          );

          this.newNotificationsSubject.next(newNotis);

          if (newNotis.length) {
            this.showToastrs(newNotis);
          }
          this.previousNotifications = notifications;
          if (this.pollingTries > 5 && !notifications.find((noti) => noti.status === 'PENDING')) {
            this.stopPollingNotifications();
            return;
          }
        }),
        catchError(() => of([]))
      );
  }

  private showToastrs(notifications: any[]) {
    notifications.forEach((noti) => {
      switch (noti.status) {
        case 'SUCCESS':
          if (noti.title.includes(':')) {
            this.toastr.success(noti.title.split(':')[1].trim(), noti.title.split(':')[0].trim());
          } else {
            this.toastr.success(noti.title.trim());
          }
          break;
        case 'FAILED':
          if (noti.title.includes(':')) {
            this.toastr.error(noti.title.split(':')[1].trim(), noti.title.split(':')[0].trim());
          } else {
            this.toastr.error(noti.title.trim());
          }
          break;
        case 'PENDING':
          if (noti.title.includes(':')) {
            this.toastr.warning(noti.title.split(':')[1].trim(), noti.title.split(':')[0].trim());
          } else {
            this.toastr.warning(noti.title.trim());
          }
          break;
      }
    });
  }

  public readNotifications(view?: string): Observable<any> {
    if (view) {
      return this.http
        .patch(`${this.environment.api}notifications/views/${view}`, {
          timestamp: moment().toDate(),
        })
        .pipe(
          tap(() => {
            const updated = this.notificationsSubject.value.map((noti) => {
              return {
                ...noti,
                _fresh: false,
              };
            });

            this.notificationsSubject.next(updated);
          })
        );
    }

    return this.http
      .post(`${this.environment.api}notifications/views`, {
        user: this.workspaceService.getUserDetails()._id,
        organisation: this.workspaceService.getActiveWorkspace()._id,
        timestamp: moment().toDate(),
      })
      .pipe(
        tap(() => {
          const updated = this.notificationsSubject.value.map((noti) => {
            return {
              ...noti,
              _fresh: false,
            };
          });

          this.notificationsSubject.next(updated);
        })
      );
  }

  public mapNotificationTime(dateString: string): string {
    const dateDiffMinutes = moment().diff(moment(dateString), 'minutes');

    if (dateDiffMinutes <= 0) {
      return 'Just now';
    }

    if (dateDiffMinutes < 60) {
      const roundedMinutes = Math.ceil(dateDiffMinutes);
      return `${roundedMinutes} min ago`;
    }

    const dateDiffHours = moment().diff(moment(dateString), 'hours');

    if (dateDiffHours < 24) {
      const roundedHours = Math.ceil(dateDiffHours);
      return `${roundedHours} hour${roundedHours > 1 ? 's' : ''} ago`;
    }

    const dateDiffDays = moment().diff(moment(dateString), 'days');

    if (dateDiffDays < 7) {
      const roundedDays = Math.ceil(dateDiffDays);
      return `${roundedDays} day${roundedDays > 1 ? 's' : ''} ago`;
    }

    if (moment(dateString).toDate().getFullYear() === moment().toDate().getFullYear()) {
      return moment(dateString).format('MMMM DD');
    }

    return moment(dateString).format('MMMM DD, YYYY');
  }

  public getNotificationClass(notification: any): string {
    if (!notification) {
      return;
    }

    let result = 'notify-icon ';
    switch (notification.status) {
      case 'INFO':
        result += 'bg-soft-primary text-primary';
        break;
      case 'SUCCESS':
        result += 'bg-soft-success text-success';
        break;
      case 'FAILED':
      case 'ERROR':
        result += 'bg-soft-danger text-danger';
        break;
      case 'PENDING':
      case 'WARNING':
        result += 'bg-soft-warning text-warning';
        break;
    }

    return result;
  }

  public getNotificationIcon(notification: any): string {
    if (!notification) {
      return;
    }

    if (notification.status === 'PENDING') {
      return 'fas fa-circle-notch fa-spin';
    }

    let result = 'remixicon-information-line';
    switch (notification.type) {
      case NotificationType.ANNOUNCEMENT:
        result = 'remixicon-volume-up-line';
        break;
      case NotificationType.LAYER:
        result = 'fe-layers';
        break;
      case NotificationType.EXPORT:
        result = 'remixicon-download-2-line';
        break;
    }

    return result;
  }

  public onOpenNotification(notification: any) {
    switch (notification.type) {
      case NotificationType.EXPORT:
        this.router.navigate(['surveys', notification.surveyId, 'results', 'exports']);
        break;
      case NotificationType.ANNOUNCEMENT:
        this.router.navigate(['announcements', notification.announcementId]);
        break;
      default:
        this.router.navigate(['notifications', notification._id]);
        break;
    }
  }
}
