import { Component, Input } from '@angular/core';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-date',
  templateUrl: './popup-fieldtype-date.component.html',
  styleUrls: ['./popup-fieldtype-date.component.scss'],
})
export class PopupFieldtypeDateComponent {
  @Input()
  public answer: ISurveyAnswer;

  @Input()
  public language: string;

  public get dateFormat(): string {
    if (!this.language || this.language === 'en') {
      return 'MM/dd/yyyy';
    }

    return 'dd/MM/yyyy';
  }
}
