import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@maplix/forms';
import {
  IMapSurveyLayer,
  IMapSurveyVector,
  IRelation,
  IStyleCondition,
  ISurvey,
  ISurveyImage,
  ISurveyMap,
  LineType,
  MapStyleSource,
  MapType,
} from '@maplix/utils';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ImportLayers } from '../import-layers/import-layers';
import { clone } from 'lodash';
import { MapLayerService } from '@maplix/maps';
import { BehaviorSubject, Observable } from 'rxjs';
import { Type } from 'ol/geom/Geometry';

@Component({
  selector: 'maplix-import-survey-layers',
  templateUrl: './import-survey-layers.component.html',
  styleUrls: ['./import-survey-layers.component.scss'],
})
export class ImportSurveyLayersComponent extends ImportLayers implements OnInit {
  @Input()
  public surveyLayer: FormGroup<IMapSurveyVector>;

  @Input()
  public selectedSurvey: ISurvey;

  @Input()
  public new: boolean;

  @Input()
  public themeColors: any;

  public mapType: MapType = this.mapService.getMapType();

  public survey$: Observable<ISurvey>;

  public geometryTypes = [
    { label: 'Point', value: 'Point', icon: 'remixicon-map-pin-2-line' },
    { label: 'Line', value: 'LineString', icon: 'fas fa-slash' },
    { label: 'Polygon', value: 'Polygon', icon: 'remixicon-shape-line' },
  ];

  public lineTypes = [
    { label: 'Default', value: 'regular', icon: 'fas fa-slash' },
    { label: 'Route', value: 'Route', icon: 'remixicon-route-line' },
  ];

  public styleTypes = [
    { label: 'Individually', value: false, icon: 'remixicon-list-check' },
    { label: 'As one layer', value: true, icon: 'remixicon-link' },
  ];

  public styleSources = [
    { label: 'Default', value: 'regular', icon: 'remixicon-palette-line' },
    { label: 'Heatmap', value: 'heatmap', icon: 'remixicon-contrast-2-fill' },
    { label: 'Clustered', value: 'cluster', icon: 'remixicon-focus-2-line' },
    { label: 'Rule based', value: 'rule-based', icon: 'remixicon-shuffle-line' },
  ];

  public allStyleSources = clone(this.styleSources);

  public selectedRule: FormGroup<IStyleCondition>;

  public selectedRuleView: 'style' | 'conditions';

  public visibleHelptexts: string[] = [];

  constructor(@Inject('environment') environment: any, public mapService: MapLayerService) {
    super(environment, mapService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setStyleSources();

    if (!this.selectedSurvey) {
      this.survey$ = this.mapService
        .getSurveyById(this.surveyLayer.getFormControl('survey').value)
        .pipe(filter<ISurvey>(Boolean));
    } else {
      this.survey$ = new BehaviorSubject<ISurvey>(this.selectedSurvey).pipe(
        filter<ISurvey>(Boolean),
        tap((survey) => {
          this.mapService.setLayerInteractions(this.surveyLayer, this.mapType, survey);
        })
      );
    }

    // Listen to geomType changes
    this.surveyLayer
      .getControl('geomType')
      .valueChanges.pipe(takeUntil(this.destroyed))
      .subscribe(() => this.onGeomTypeChange());

    // Listen to lineType changes
    this.surveyLayer
      .getControl('lineType')
      .valueChanges.pipe(takeUntil(this.destroyed))
      .subscribe(() => this.onLineTypeChange());
  }

  private onGeomTypeChange() {
    (this.surveyLayer.get('layers') as FormArray<IMapSurveyLayer>).controls.forEach(
      (fgLayer: FormGroup<IMapSurveyLayer>) => {
        fgLayer.getControl('selectedInModal').setValue(false);
      }
    );

    this.setStyleSources();
  }

  private onLineTypeChange() {
    (this.surveyLayer.get('layers') as FormArray<IMapSurveyLayer>).controls.forEach(
      (fgLayer: FormGroup<IMapSurveyLayer>) => {
        fgLayer.getControl('selectedInModal').setValue(false);
      }
    );
  }

  public getInteractionsOfType(
    mapPages: (ISurveyMap | ISurveyImage)[],
    geomType: Type,
    lineType: LineType
  ): (ISurveyMap | ISurveyImage)[] {
    if (!mapPages) {
      return;
    }
    if (geomType === 'LineString' && lineType === 'Route') {
      return (mapPages as any[]).filter(
        (mapPage) =>
          !!(
            mapPage.categories &&
            mapPage.categories.find(
              (cat) => cat.drawOptions.geomType === geomType && cat.drawOptions.lineType === lineType
            )
          )
      );
    } else if (geomType === 'LineString') {
      return (mapPages as any[]).filter(
        (mapPage) =>
          !!(
            mapPage.categories &&
            mapPage.categories.find(
              (cat) => cat.drawOptions.geomType === geomType && cat.drawOptions.lineType !== 'Route'
            )
          )
      );
    }

    return (mapPages as any[]).filter(
      (mapPage) => !!(mapPage.categories && mapPage.categories.find((cat) => cat.drawOptions.geomType === geomType))
    );
  }

  public onRemoveSurveyLayer(layer: FormGroup<IMapSurveyVector>) {
    this.onRemoveDataLayer(layer, this.mapForm);
  }

  private setStyleSources() {
    const geomType = this.surveyLayer.getControl('geomType').value;

    if (geomType === 'Point') {
      this.styleSources = clone(this.allStyleSources);
    } else {
      this.styleSources = clone(this.allStyleSources).filter(
        (source) => source.value !== 'cluster' && source.value !== 'heatmap'
      );
    }

    this.surveyLayer
      .get('styleSource')
      .valueChanges.pipe(
        tap((styleSource: MapStyleSource) => {
          if (styleSource !== 'rule-based') {
            this.surveyLayer.get('styleConditions')['controls'] = this.surveyLayer
              .get('styleConditions')
              ['controls'].slice(0, 1);

            (this.surveyLayer.get('styleConditions')['controls'][0] as FormGroup<IStyleCondition>).controls.rules =
              new FormArray<IRelation>([]);
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();

    (this.surveyLayer.get('layers') as FormArray<IMapSurveyLayer>).controls
      .filter((layerControl) => layerControl.get('selectedInModal').value)
      .forEach((layerControl) => {
        layerControl
          .get('styleSource')
          .valueChanges.pipe(
            tap((styleSource: MapStyleSource) => {
              if (styleSource !== 'rule-based') {
                layerControl.get('styleConditions')['controls'] = layerControl
                  .get('styleConditions')
                  ['controls'].slice(0, 1);

                (layerControl.get('styleConditions')['controls'][0] as FormGroup<IStyleCondition>).controls.rules =
                  new FormArray<IRelation>([]);
              }
            }),
            takeUntil(this.destroyed)
          )
          .subscribe();
      });
  }

  public getInteractionTitle(pageid: number, value: string, survey: ISurvey): string {
    if (!pageid && value) {
      return 'New sublayer';
    }

    const interaction =
      this.mapType === 'MAP'
        ? survey.questions.mapInteractions
            .find((mapPage) => mapPage.pageid === pageid)
            .categories.find((int) => int.value === value)
        : survey.questions.imageInteractions
            .find((mapPage) => mapPage.pageid === pageid)
            .categories.find((int) => int.value === value);

    if (!interaction) {
      return 'New sublayer';
    }

    let interactionLabel = interaction.label.find((label) => label.i18n === this.mapService.language);
    if (!interactionLabel || !interactionLabel.value) {
      interactionLabel = interaction.label.find((label) => Boolean(label.value));
    }

    return interactionLabel ? interactionLabel.value : 'Untitled interaction';
  }

  public onEditRule(styleCondition: FormGroup<IStyleCondition>): void {
    this.selectedRule = styleCondition;
    this.selectedRuleView = 'style';
    styleCondition.markAsDirty();
  }

  public onEditConditions(styleCondition: FormGroup<IStyleCondition>): void {
    this.selectedRule = styleCondition;
    this.selectedRuleView = 'conditions';
    styleCondition.markAsDirty();
  }

  public onRemoveRule(array: FormArray<IStyleCondition>, styleCondition: FormGroup<IStyleCondition>) {
    const index = array.controls.findIndex(
      (ctrl: FormGroup<IStyleCondition>) => ctrl.getControl('id').value === styleCondition.getControl('id').value
    );
    array.removeAt(index);
    array.markArrayAsChanged();
  }

  public onChangeTab() {
    this.selectedRule = undefined;
  }

  public onAddRule(array: FormArray<IStyleCondition>): void {
    const newRule = this.mapService.getDefaultStyling();
    array.push(newRule);
    array.markArrayAsChanged();
  }

  public getInteractions(dataLayer: FormGroup<IMapSurveyVector | IMapSurveyLayer>): string[] {
    if ('interaction' in dataLayer.getRawValue()) {
      return [(dataLayer as FormGroup<IMapSurveyLayer>).getControl('interaction').value];
    }

    return (dataLayer as FormGroup<IMapSurveyVector>)
      .getRawValue()
      .layers.filter((layer) => layer.selectedInModal)
      .map((layer) => layer.interaction);
  }

  public onToggleHelptext(layer: string) {
    if (this.visibleHelptexts.includes(layer)) {
      this.visibleHelptexts = this.visibleHelptexts.filter((id) => id !== layer);
    } else {
      this.visibleHelptexts.push(layer);
    }
  }

  public onToggleLayer() {
    const layers = this.surveyLayer.getControl('layers') as FormArray<IMapSurveyLayer>;
    const selected = layers.controls.find(
      (layer: FormGroup<IMapSurveyLayer>) => layer.getFormControl('selectedInModal').value
    );
    if (selected) {
      const { geomType, lineType } = selected.value;

      layers.controls
        .filter(
          (layer) =>
            layer.value.geomType === geomType &&
            (layer.value.geomType !== 'LineString' || layer.value.lineType === lineType)
        )
        .forEach((ctrl) => ctrl.get('selectedInModal').enable());

      layers.controls
        .filter(
          (layer) =>
            layer.value.geomType !== geomType ||
            (layer.value.geomType === 'LineString' && layer.value.lineType !== lineType)
        )
        .forEach((ctrl) => ctrl.get('selectedInModal').disable());

      this.surveyLayer.getControl('geomType').setValue(geomType, { emitEvent: false });
      this.surveyLayer.getControl('lineType').setValue(lineType, { emitEvent: false });
      return;
    }

    layers.controls.forEach((ctrl) => ctrl.get('selectedInModal').enable());
  }

  public hasInteractions(geomType, surveyLayer: FormGroup<IMapSurveyVector>) {
    const layers = surveyLayer.getControl('layers') as FormArray<IMapSurveyLayer>;

    const hasLayer = layers.controls.find(
      (layer: FormGroup<IMapSurveyLayer>) =>
        layer.value.geomType === geomType.geomType &&
        (layer.value.geomType !== 'LineString' || layer.value.lineType === geomType.lineType)
    );

    return !!hasLayer;
  }
}
