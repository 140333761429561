export const environment = {
  env: 'DEVELOP',
  cookieDomain: 'maplix.com',
  production: false,
  apps: {
    root: 'https://dev.maplix.com/survey/',
    auth: 'https://dev.maplix.com/account/',
    home: 'https://dev.maplix.com/dashboard/',
    editor: 'https://dev.maplix.com/editor/',
    surveyViewer: 'https://dev.maplix.com/survey/',
    mapViewer: 'https://dev.maplix.com/map/',
  },
  api: 'https://dev.api.maplix.com/',
  stitchId: 'maplix-account-dev-vkgph',
  redirect: 'dev.engage.maplix.com',
  geoserver: 'https://geo.maplix.com/geoserver/',
  geoserverDb: 'maplix-dev',
  mapboxToken: 'pk.eyJ1IjoiY2VkcmljdmVyc2x1eXMiLCJhIjoiY2s5azFwaTFvMDg5czNsbnNxY2ExbHgyeSJ9.W_Tk-JA9ujNEL55b_cf7Hw',
  plausible: {
    domain: 'dev.maplix.com',
  },
  strava: {
    clientId: '113255',
    clientSecret: '71c98f77b8feea19fb631cca4ae6d35af57d93b4',
  },
};
