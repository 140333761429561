import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-file-upload',
  templateUrl: './popup-fieldtype-file-upload.component.html',
  styleUrls: ['./popup-fieldtype-file-upload.component.scss']
})
export class PopupFieldtypeFileUploadComponent implements OnInit {
  @Input()
  public answer: ISurveyAnswer;

  @Output()
  private open: EventEmitter<string> = new EventEmitter();

  public loadedImages = [];

  constructor() { }

  ngOnInit(): void {
    if (this.answer && !this.answer.extra) {
      this.testImage(this.answer.value_label);
    }
  }

  public testImage(url: string) {
    var tester = new Image();
    tester.onload = () => {
      this.loadedImages.push(url);
    };
    tester.src = url;
  }
  
  public onOpenImage(image: string) {
    this.open.emit(image);
  }

}
