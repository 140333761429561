export const transportModes: { label: string, id: string }[] = [
    { label: 'On foot', id: 'FOOT' },
    { label: 'Bike', id: 'BIKE' },
    { label: 'E-Bike', id: 'EBIKE' },
    { label: 'Train', id: 'TRAIN' },
    { label: 'Bus/Tram/Metro', id: 'PUBLIC' },
    { label: 'Motorcycle', id: 'MOTOR' },
    { label: 'Scooter', id: 'SCOOTER' },
    { label: 'Car', id: 'CAR' },
    { label: 'Other', id: 'OTHER' }
  ];