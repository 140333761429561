import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MapClsFree } from '@maplix/maps';
import Polyline from 'ol/format/Polyline';
import { IStravaMap } from '../../interfaces';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Stroke, Style } from 'ol/style';
import { THEME_COLORS } from '@maplix/utils';
import { SimpleGeometry } from 'ol/geom';

@Component({
  selector: 'maplix-summary-map',
  templateUrl: './summary-map.component.html',
  styleUrls: ['./summary-map.component.scss'],
})
export class SummaryMapComponent implements OnInit, AfterViewInit {
  @Input()
  private stravaMap: IStravaMap;

  public get mapId(): string {
    return this.stravaMap?.id;
  }

  private map: MapClsFree;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.map = new MapClsFree(this.mapId);

      const polyline = new Polyline().readFeature(this.stravaMap.summary_polyline, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });

      const lineLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          stroke: new Stroke({
            width: 3,
            color: THEME_COLORS.STRAVA.PRIMARY,
          }),
        }),
      });

      lineLayer.getSource().addFeature(polyline);

      this.map.addLayer(lineLayer);

      this.map.getView().fit(polyline.getGeometry() as SimpleGeometry, { padding: [10, 10, 10, 10] });
    });
  }
}
