import { Component, Input, OnInit } from '@angular/core';
import { ILabelOptions, IStyle, MapStyleSource } from '@maplix/utils';
import { FormControl, FormGroup } from '@maplix/forms';
import { ApiService } from '@maplix/api';
import { ColorEvent } from 'ngx-color';
import { MapLayerService } from '../../services';
import { Type } from 'ol/geom/Geometry';

const LABELFIELD_ICON_MAP = {
  String: 'remixicon-font-size-2',
  Integer: 'remixicon-calculator-line',
  Real: 'remixicon-calculator-line',
  Date: 'remixicon-calendar-event-line',
};

@Component({
  selector: 'maplix-vector-layer-labels',
  templateUrl: './vector-layer-labels.component.html',
  styleUrls: ['./vector-layer-labels.component.scss'],
})
export class VectorLayerLabelsComponent implements OnInit {
  @Input()
  public labelControl: FormGroup<ILabelOptions>;

  @Input()
  public uploadId: string;

  @Input()
  public styleSource: MapStyleSource;

  @Input()
  public geometryType: Type;

  @Input()
  public themeColors: any;

  @Input()
  public style: IStyle;

  public labelFields;

  public labelAlignments = [
    { label: 'Center', value: 'center' },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
  ];

  public haloOptions = [
    { label: 'Show halo', value: true, icon: 'remixicon-eye-line' },
    { label: 'No halo', value: false, icon: 'remixicon-eye-off-line' },
  ];

  constructor(private api: ApiService, public mapService: MapLayerService) {}

  ngOnInit(): void {
    if (this.uploadId) {
      this.getUploadFields();
    }
  }

  private async getUploadFields() {
    const upload = await this.api.map.getUploadById(this.uploadId).toPromise();

    if (!upload.fields || !upload.fields?.length) {
      this.labelFields = [];
      return;
    }

    this.labelFields = [
      { label: 'No labels', value: 'NO_LABELS', icon: 'remixicon-eye-off-line' },
      ...upload.fields.map((field) => {
        return {
          label: field.name,
          value: field.name,
          icon: LABELFIELD_ICON_MAP[field.type] ?? 'remixicon-font-size-2',
          type: field.type,
        };
      }),
    ];
  }

  public onChangeLabelColor(control: FormControl<string>, $event: ColorEvent) {
    control.setValue(
      `rgba(${$event.color.rgb.r}, ${$event.color.rgb.g}, ${$event.color.rgb.b}, ${$event.color.rgb.a})`
    );
    control.parent?.markAsDirty();
  }
}
