import { Inject, Injectable } from '@angular/core';
import { OverviewModalWrapperComponent } from '../components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(@Inject('environment') private environment: any, private modalService: NgbModal) {}

  public async onOpenCloudModal(): Promise<string> {
    const cloudModal = this.modalService.open(OverviewModalWrapperComponent, {
      size: 'xl',
    });

    cloudModal.componentInstance.dataType = 'image';
    cloudModal.componentInstance.modalTitle = 'Select an image';
    cloudModal.componentInstance.showLightButton = false;
    cloudModal.componentInstance.showPrimaryButton = false;
    cloudModal.componentInstance.apiUrl = this.environment.api;

    const selectedImage: string = await cloudModal.result;

    return selectedImage;
  }
}
