import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@maplix/ui';
import { StravaRedirectComponent } from './components/strava-redirect/strava-redirect.component';
import { StravaActivitiesModalComponent } from './components/strava-activities-modal/strava-activities-modal.component';
import { DirectivesModule } from '@maplix/directives';
import { PipesModule } from '@maplix/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { SummaryMapComponent } from './components/summary-map/summary-map.component';

@NgModule({
  declarations: [StravaRedirectComponent, StravaActivitiesModalComponent, SummaryMapComponent],
  imports: [CommonModule, UiModule, DirectivesModule, PipesModule, TranslateModule],
  exports: [StravaRedirectComponent, StravaActivitiesModalComponent],
})
export class StravaPluginModule {
  static forRoot(environment: any) {
    return {
      ngModule: StravaPluginModule,
      providers: [{ provide: 'environment', useValue: environment }],
    };
  }
}
