const ROLE_PERMISSIONS: { [key: string]: string[] } = {
  ADMIN: ['canEditOrganisation', 'canEditUsers', 'canEditOrganisationDatasets'],
  PROJECT_MANAGEMENT: ['canEditProjectDetails', 'canAddProjectPartners', 'canEditProjectActionPlanning'],
  PROJECT_ACTIONS: ['canEditProjectAction', 'canRequestProjectActionAdvice'],
  PROJECT_ADVICE: ['canGiveProjectActionAdvice'],
  PROJECT_AUTHORIZATION: ['canAgendaProject', 'canApproveProject', 'canRejectProject', 'canRevokeProject'],
  PROJECT_DOCUMENTS: ['canAddProjectDocuments'],
  SURVEY_DESIGN: ['canEditSurvey', 'canPublishSurvey', 'canTerminateSurvey'],
  SURVEY_ANALYSIS: ['canCreateSurveyReport'],
  MAP_DESIGN: ['canEditMap', 'canEditMapDatasets'],
};

export function getRolePermissions(roles: string[]) {
  return roles
    .map((r) => ROLE_PERMISSIONS[r])
    .reduce((prev, cur) => {
      prev.push(...cur);
      return prev;
    });
}
