import { IndividualConfig, ToastrService } from "ngx-toastr";

export class ToastrNotification {

  constructor(private toastr: ToastrService) { }

  public success(msg: string, title?: string, config?: Partial<IndividualConfig>) {
    this.toastr.success(msg, title, config)
  }

  public warning(msg: string, title?: string, config?: Partial<IndividualConfig>) {
    this.toastr.warning(msg, title, config)
  }

  public info(msg: string, title?: string, config?: Partial<IndividualConfig>) {
    this.toastr.info(msg, title, config)
  }

  public danger(msg: string, title?: string, config?: Partial<IndividualConfig>) {
    this.toastr.error(msg, title, config)
  }

}