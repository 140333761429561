import { Component, Input, OnInit } from '@angular/core';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-checkbox',
  templateUrl: './popup-fieldtype-checkbox.component.html',
  styleUrls: ['./popup-fieldtype-checkbox.component.scss'],
})
export class PopupFieldtypeCheckboxComponent implements OnInit {
  @Input()
  public answer: ISurveyAnswer;

  constructor() {}

  ngOnInit(): void {}
}
