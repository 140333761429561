import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'maplix-destroy',
  template: '',
})
export class DestroyComponent implements OnDestroy {
  protected destroyed: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
