import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, ToastrModule, HttpClientModule],
})
export class ServicesModule {
  static forRoot(environment: any, app?: string) {
    return {
      ngModule: ServicesModule,
      providers: [
        { provide: 'environment', useValue: environment },
        { provide: 'app', useValue: app },
      ],
    };
  }
}
