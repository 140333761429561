import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'maplix-color-dropdown',
  templateUrl: './color-dropdown.component.html',
  styleUrls: ['./color-dropdown.component.scss']
})
export class ColorDropdownComponent {
  @Input()
  public color: string;

  @Input()
  public hideOpacity: boolean;

  @Output()
  private colorChange: EventEmitter<ColorEvent> = new EventEmitter();

  public onEmitChange($event: ColorEvent) {
    this.colorChange.emit($event);
  }

}
