import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@maplix/forms';
import { IMapUploadVector, MapType } from '@maplix/utils';
import { clone } from 'lodash';
import { MapLayerService } from '@maplix/maps';
import { ImportLayers } from '../import-layers/import-layers';
import { IUploadLayerDataset } from '@maplix/cloud';
import { ApiService } from '@maplix/api';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'maplix-import-upload-layers',
  templateUrl: './import-upload-layers.component.html',
  styleUrls: ['./import-upload-layers.component.scss'],
})
export class ImportUploadLayersComponent extends ImportLayers implements OnInit {
  @Input()
  public uploadLayer: FormGroup<IMapUploadVector>;

  @Input()
  public new: boolean;

  @Input()
  public themeColors: any;

  public disabled: boolean;

  public mapType: MapType = MapType.MAP;

  public styleSources = [
    { label: 'Default', value: 'regular', icon: 'remixicon-palette-line' },
    { label: 'Heatmap', value: 'heatmap', icon: 'remixicon-contrast-2-fill' },
    { label: 'Clustered', value: 'cluster', icon: 'remixicon-focus-2-line' },
  ];

  public allStyleSources = clone(this.styleSources);

  public visibleHelptexts: string[] = [];

  public get icon(): string {
    let result =
      this.uploadLayer.value.type === 'WMS'
        ? 'remixicon-server-fill'
        : this.uploadLayer.value.type === 'UPLOAD' && !this.uploadLayer.value.geomType
        ? 'remixicon-folder-upload-fill'
        : this.uploadLayer.value.geomType === 'Point'
        ? 'fas fa-map-marker-alt'
        : this.uploadLayer.value.geomType === 'LineString'
        ? 'fas fa-slash'
        : this.uploadLayer.value.geomType === 'Polygon'
        ? 'fas fa-vector-square'
        : 'remixicon-map-2-line';

    result += ' mr-2';
    return result;
  }

  constructor(@Inject('environment') environment: any, public mapService: MapLayerService) {
    super(environment, mapService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setStyleSources();
  }

  private setStyleSources() {
    const geomType = this.uploadLayer.getControl('geomType').value;

    if (geomType === 'Point') {
      this.styleSources = clone(this.allStyleSources);
    } else {
      this.styleSources = clone(this.allStyleSources).filter(
        (source) => source.value !== 'cluster' && source.value !== 'heatmap'
      );
    }
  }

  public onToggleHelptext(layer: string) {
    if (this.visibleHelptexts.includes(layer)) {
      this.visibleHelptexts = this.visibleHelptexts.filter((id) => id !== layer);
    } else {
      this.visibleHelptexts.push(layer);
    }
  }
}
