import { Map as OlMap } from 'ol';
import { defaults } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';

export class MapClsFree extends OlMap {
  constructor(target: string) {
    super({
      target,
      controls: [],
      interactions: defaults({
        shiftDragZoom: false,
        altShiftDragRotate: false,
        doubleClickZoom: false,
        dragPan: false,
        keyboard: false,
        mouseWheelZoom: false,
        pinchZoom: false,
        pinchRotate: false,
      }),
    });

    this.addBaseLayer();
  }

  private addBaseLayer() {
    this.addLayer(new TileLayer({ source: new OSM({ attributions: null }), opacity: 0.5 }));
  }
}
