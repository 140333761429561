<img
  [src]="answer?.extra?.image"
  class="img-preview clickable"
  (click)="onOpenImage(answer?.extra?.image)"
  *ngIf="answer?.extra"
/>

<p class="mb-0" *ngIf="!answer?.extra">
  {{ answer.value_label ? answer.value_label : answer.value }}
</p>
