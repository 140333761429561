<div class="base-toggle-overlay" *ngIf="!collapsed" (click)="toggleBaseLayer()">
  <div class="d-flex d-md-none flex-column align-items-center justify-content-center h-100 w-100 px-5 pb-5 mx-3">
    <h3 class="text-center">
      {{ 'map.CHANGEBASELAYER' | translate }}
    </h3>
  </div>
</div>

<div
  class="base-toggle clickable"
  [id]="id"
  *ngIf="!loading"
  [class.d-none]="hideOnSmartphone"
  [class.d-md-block]="hideOnSmartphone"
>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed">
    <div class="d-flex flex-column flex-wrap justify-content-end" [ngStyle]="{ height: maxHeight }">
      <ng-container *ngFor="let layer of baseLayers">
        <img
          [src]="'assets/images/map/' + layer.id + '.png'"
          class="mb-2 mr-2"
          [class.border-primary]="layer.id === activeLayer"
          (click)="onChangeBaseLayer(layer.id)"
          alt="basemap"
          ngbTooltip="{{ layer.label }}"
          placement="right"
          openDelay="500"
        />
      </ng-container>
    </div>
  </div>
  <img
    [src]="'assets/images/map/base-layer-selector.png'"
    (click)="toggleBaseLayer()"
    alt="basemap"
    [ngbTooltip]="tooltipText"
    placement="right"
    openDelay="500"
    #baselayerTooltip="ngbTooltip"
    *ngIf="collapsed"
  />

  <div class="layer-icon-container" *ngIf="!collapsed" (click)="toggleBaseLayer()">
    <i class="fe-layers h4 my-0"></i>
  </div>
</div>
