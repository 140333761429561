<i
  *ngIf="geomType === 'Polygon'"
  class="fas fa-vector-square fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="geomType === 'LineString' && lineType === 'Route'"
  class="remixicon-route-line mr-1 fa-fw font-16"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="geomType === 'LineString' && lineType !== 'Route' && style.lineStyle === 'arrow'"
  class="remixicon-arrow-left-up-fill fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="geomType === 'LineString' && lineType !== 'Route' && style.lineStyle === 'regular'"
  class="fas fa-slash fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' && styleSource !== 'heatmap' && (style.pointStyle === 'regular' || styleSource === 'cluster')
  "
  class="fas fa-circle fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' && style.pointStyle === 'marker' && (styleSource === 'regular' || styleSource === 'rule-based')
  "
  class="fas fa-map-marker-alt fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' &&
    style.pointStyle === 'triangle' &&
    (styleSource === 'regular' || styleSource === 'rule-based')
  "
  class="mdi mdi-triangle fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' && style.pointStyle === 'square' && (styleSource === 'regular' || styleSource === 'rule-based')
  "
  class="mdi mdi-square fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' &&
    style.pointStyle === 'pentagon' &&
    (styleSource === 'regular' || styleSource === 'rule-based')
  "
  class="mdi mdi-pentagon fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' &&
    style.pointStyle === 'hexagon' &&
    (styleSource === 'regular' || styleSource === 'rule-based')
  "
  class="mdi mdi-hexagon fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>
<i
  *ngIf="
    geomType === 'Point' && style.pointStyle === 'star' && (styleSource === 'regular' || styleSource === 'rule-based')
  "
  class="mdi mdi-star fa-fw font-16 mr-1"
  [ngStyle]="{ color: style.color }"
></i>

<img
  [src]="style.icon"
  alt="icon image"
  class="img-thumbnail-small not-rounded mr-1"
  *ngIf="
    style.icon &&
    geomType === 'Point' &&
    style.pointStyle === 'icon' &&
    (styleSource === 'regular' || styleSource === 'rule-based')
  "
/>

<img
  [src]="'assets/images/map/heatmap.png'"
  alt="heatmap"
  class="img-thumbnail-small mr-1"
  *ngIf="geomType === 'Point' && styleSource === 'heatmap'"
/>

<span class="mr-1" *ngIf="!(geomType !== 'LineString' || lineType === 'Route' || style.lineStyle !== 'dashed')">
  <svg width="16" height="16">
    <g>
      <rect
        [attr.stroke]="style.color"
        [attr.fill]="style.color"
        transform="rotate(35 2.6351220607757564,4.150240421295166)"
        rx="1"
        id="svg_1"
        height="2"
        width="4"
        y="3.15024"
        x="0.63512"
        opacity="undefined"
      />
      <rect
        [attr.stroke]="style.color"
        [attr.fill]="style.color"
        transform="rotate(35 8.000000953674313,8.000000000000002)"
        rx="1"
        id="svg_2"
        height="2"
        width="4"
        y="7"
        x="6"
        opacity="undefined"
      />
      <rect
        [attr.stroke]="style.color"
        [attr.fill]="style.color"
        transform="rotate(35 13.38515377044678,11.837325096130371)"
        rx="1"
        id="svg_3"
        height="2"
        width="4"
        y="10.83733"
        x="11.38515"
        opacity="undefined"
      />
    </g>
  </svg>
</span>
