import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@maplix/forms';
import { IWMSLayerDataset } from '@maplix/cloud';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, takeUntil, tap } from 'rxjs/operators';
import WmsCapabilites from 'ol/format/WMSCapabilities';
import { WorkspaceService } from '@maplix/services';
import { Validators } from '@angular/forms';

@Component({
  selector: 'maplix-create-wms',
  templateUrl: './create-wms.component.html',
  styleUrls: ['./create-wms.component.scss'],
})
export class CreateWmsComponent implements OnInit, OnDestroy {
  @Input()
  public apiUrl: string;

  @Output()
  public formValidityChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public formValueChange: EventEmitter<IWMSLayerDataset> = new EventEmitter();

  public readonly wmsHelptext: string =
    "Paste the root url of the WMS here. The root url is everything that comes before the '?'.";

  public form: FormGroup<IWMSLayerDataset>;

  public wmsStatus: string;

  private destroyed: Subject<void> = new Subject();

  constructor(private http: HttpClient, private workspaceService: WorkspaceService) {}

  ngOnInit() {
    this.form = new FormGroup<IWMSLayerDataset>({
      type: new FormControl<string>('WMS'),
      name: new FormControl<string>(null, Validators.required),
      url: new FormControl<string>(null, Validators.required),
      status: new FormControl("SUCCESS"),
      organisation: new FormControl<string>(this.workspaceService.getActiveWorkspace()._id),
      creator: new FormControl<string>(this.workspaceService.getUserDetails()._id)
    });

    this.form
      .getFormControl('url')
      .valueChanges.pipe(
        tap(() => {
          this.wmsStatus = 'loading';
        }),
        debounceTime(300),
        tap((url: string) => {
          url = url.split('?')[0].trim();
          url = url + '?service=WMS&request=GetCapabilities';
          const parser = new WmsCapabilites();
          this.http
            .get(`${this.apiUrl}wms/${url}`, { responseType: 'text' })
            .pipe(
              first(),
              tap((xmlResponse) => {
                try {
                  parser.read(xmlResponse);
                  this.wmsStatus = 'success';
                  this.form.getFormControl('url').setErrors(null);
                } catch (error) {
                  this.wmsStatus = 'error';
                  this.form.getFormControl('url').setErrors({invalidUrl: true});
                }
              }),
              takeUntil(this.destroyed)
            )
            .subscribe();
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();

      this.form.valid$.pipe(
        tap((valid: boolean) => {
          this.formValidityChange.emit(valid);
        }),
        takeUntil(this.destroyed)
      ).subscribe();

      this.form.value$.pipe(
        filter(() => this.form.valid),
        tap((value: IWMSLayerDataset) => {
          this.formValueChange.emit(value);
        }),
        takeUntil(this.destroyed)
      ).subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
