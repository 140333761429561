import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISurveyAnswer } from '@maplix/utils';

@Component({
  selector: 'maplix-popup-fieldtype-image-select',
  templateUrl: './popup-fieldtype-image-select.component.html',
  styleUrls: ['./popup-fieldtype-image-select.component.scss']
})
export class PopupFieldtypeImageSelectComponent implements OnInit {
  @Input()
  public answer: ISurveyAnswer;

  @Output()
  private open: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onOpenImage(image: string) {
    this.open.emit(image);
  }

}
