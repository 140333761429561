import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IOrganisation, MaplixApp } from '@maplix/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root',
})
export class CreditsService {
  private credits: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
  public credits$: Observable<any[]> = this.credits.asObservable();

  private remainingCreditAmount: BehaviorSubject<number> = new BehaviorSubject(undefined);
  public remainingCreditAmount$: Observable<number> = this.remainingCreditAmount.asObservable();

  constructor(
    @Inject('environment') private environment: any,
    @Inject('app') private app: MaplixApp,
    private http: HttpClient,
    private workspaceService: WorkspaceService
  ) {}

  public async getCredits() {
    let appFilter = '';
    if (this.app) {
      appFilter = `?where={"app":"${this.app}"}`;
    }

    const credits = await this.workspaceService.activeWorkspace$
      .pipe(
        filter<IOrganisation>(Boolean),
        distinctUntilKeyChanged('_id'),
        switchMap((organisation) => {
          return this.http.get<any>(`${this.environment.api}organisations/${organisation._id}/credits${appFilter}`);
        }),
        map(({ _items }) => _items),
        tap((credits) => {
          this.remainingCreditAmount.next(
            credits && credits.length ? credits.map((c) => c.remaining).reduce((a, b) => a + b) : 0
          );
        })
      )
      .toPromise();

    this.credits.next(credits);
  }
}
