export enum NotificationType {
  LAYER = 'LAYER',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  EXPORT = 'EXPORT',
}

export enum NotificationStatus {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface INotification {
  _id?: string;
  _fresh?: boolean;
  _created?: Date | string;
  _updated?: Date | string;
  type: NotificationType;
  status: NotificationStatus;
  title: string;
  message?: string;
  layerId?: string;
  organisation?: string;
}
