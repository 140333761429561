import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@maplix/api';
import { RealmService } from '@maplix/realm';
import { IOrganisation, IOrganisationMember, IUserDetails } from '@maplix/utils';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private userDetails: BehaviorSubject<IUserDetails> = new BehaviorSubject(undefined);
  public userDetails$: Observable<IUserDetails> = this.userDetails.asObservable();

  private activeWorkspace: BehaviorSubject<IOrganisation> = new BehaviorSubject(undefined);
  public activeWorkspace$: Observable<IOrganisation> = this.activeWorkspace.asObservable();

  private activeMember: BehaviorSubject<IOrganisationMember> = new BehaviorSubject(undefined);
  public activeMember$: Observable<IOrganisationMember> = this.activeMember.asObservable();

  private isOwner: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public isOwner$: Observable<boolean> = this.isOwner.asObservable();

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    private cookie: CookieService,
    private realmService: RealmService,
    private api: ApiService
  ) {}

  public getUserDetails() {
    return this.userDetails.value;
  }

  public async setUserDetails(details: any): Promise<void> {
    this.userDetails.next(details);

    this.cookie.set(
      `userDetails${this.environment.env}`,
      JSON.stringify({ _id: details?._id }),
      30,
      '/',
      this.environment.cookieDomain
    );

    const isOwner = await this.getOwner();
    this.isOwner.next(isOwner);
  }

  public getActiveWorkspace() {
    return this.activeWorkspace.value;
  }

  public setActiveWorkspace(workspace: IOrganisation): void {
    this.activeWorkspace.next(workspace);

    this.cookie.set(
      `activeWorkspace${this.environment.env}`,
      JSON.stringify({ _id: workspace?._id }),
      30,
      '/',
      this.environment.cookieDomain
    );
  }

  public setActiveMember(member: IOrganisationMember): void {
    this.activeMember.next(member);
  }

  public getActiveMember() {
    return this.activeMember.value;
  }

  private async getOwner(): Promise<boolean> {
    if (!this.getUserDetails() || !this.getActiveWorkspace()) {
      return undefined;
    }

    if (this.getActiveMember()) {
      return this.getActiveMember().owner;
    }

    const userId = this.getUserDetails()._id;

    return await this.http
      .get<any>(
        `${this.environment.api}organisations/${this.getActiveWorkspace()._id}/members?where={"user":"${userId}"}`
      )
      .pipe(
        tap(({ _items }) => this.setActiveMember(_items[0])),
        map(({ _items }) => Boolean(_items.find((member) => member.owner)))
      )
      .toPromise();
  }
}
