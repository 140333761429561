import VectorLayer from 'ol/layer/Vector';
import { IMapOpts, MapCls } from './mapcls';
import VectorSource from 'ol/source/Vector';
import {
  IActionCommentThread,
  IActionPlanItem,
  IActionPlanItemLocation,
  IStyle,
  THEME_COLORS,
  defaultStyle,
} from '@maplix/utils';
import { Circle, Fill, Icon, Stroke, Style } from 'ol/style';
import { Collection, Feature, MapBrowserEvent, Overlay } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import {
  Draw as DrawInteraction,
  Modify as ModifyInteraction,
  Select as SelectInteraction,
  Translate as TranslateInteraction,
} from 'ol/interaction';
import Geometry, { Type as GeometryType } from 'ol/geom/Geometry';
import { Subject, Observable } from 'rxjs';
import { DrawEvent } from 'ol/interaction/Draw';
import { SelectEvent } from 'ol/interaction/Select';
import { Point } from 'ol/geom';
import { FeatureLike } from 'ol/Feature';
import Color from 'color';

const COMMENT_MAP_STYLE = (f) => {
  if (f.get('hidden')) {
    return null;
  }

  return [
    new Style({
      image: new Circle({
        radius: 15,
        fill: new Fill({
          color: '#fff',
        }),
        stroke: new Stroke({
          width: 2,
          color: f.get('selected') ? THEME_COLORS.GENERIC.PRIMARY : '#D9D9D9',
        }),
      }),
    }),
    new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: 'assets/images/map/comment.svg',
        scale: 1,
      }),
    }),
  ];
};

const COMMENT_DRAW_MAP_STYLE = (f) => {
  return [
    new Style({
      image: new Circle({
        radius: 9,
        fill: new Fill({
          color: '#fff',
        }),
        stroke: new Stroke({
          width: 1,
          color: THEME_COLORS.GENERIC.PRIMARY,
        }),
      }),
    }),
    new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: 'assets/images/map/comment.svg',
        scale: 0.5,
      }),
    }),
  ];
};

const STATUS_COLOR_MAPPER = {
  NEW: THEME_COLORS.GENERIC.INFO,
  IN_PROGRESS: THEME_COLORS.GENERIC.SECONDARY,
  ON_HOLD: THEME_COLORS.GENERIC.GREY,
  COMPLETED: THEME_COLORS.GENERIC.PRIMARY,
};

export class MapClsActions extends MapCls {
  private lAction: VectorLayer<VectorSource>;

  private actionChangeSubject: Subject<any> = new Subject();
  public actionChange$: Observable<any> = this.actionChangeSubject.asObservable();

  private lComment: VectorLayer<VectorSource<Point>>;
  private lDrawComment: VectorLayer<VectorSource<Point>>;

  private commentSelected: Subject<{ thread: string; actionplan: string }> = new Subject();
  public commentSelected$ = this.commentSelected.asObservable();

  private iDrawAction: DrawInteraction;

  private iDrawComment: DrawInteraction;

  private iModifyAction: ModifyInteraction;

  private iTranslateAction: TranslateInteraction;

  private iRemoveAction: SelectInteraction;

  private _defaultMapConfig: IMapOpts;

  private editing: boolean;

  private clearPopupContent: Subject<void> = new Subject();
  public clearPopupContent$ = this.clearPopupContent.asObservable();

  private actionSelected: Subject<FeatureLike> = new Subject();
  public actionSelected$ = this.actionSelected.asObservable();

  constructor(opts: IMapOpts) {
    super(
      opts.target,
      opts.translate,
      opts.http,
      opts.environment,
      'mapbox-basic',
      opts.center,
      opts.zoom,
      null,
      opts.defaults
    );

    this._defaultMapConfig = opts;
    this.addActionLayer();
    this.addCommentLayer();

    this.map.on('click', (e) => this.onClickMap(e));

    this.map.on('pointermove', (e) => this.onHoverMap(e));
  }

  protected onClickMap(e: MapBrowserEvent<UIEvent>) {
    return new Promise<void>((res, rej) => {
      const commentFeatures = [];
      const actionFeatures = [];
      this.map.forEachFeatureAtPixel(
        e.pixel,
        (f, layer) => {
          if (layer.get('name') === 'lComment' && layer.get('visible')) {
            if (!commentFeatures.map((feature) => feature.getId()).includes(f.getId())) {
              commentFeatures.push(f);
            }
          } else if (layer.get('name') === 'lAction' && layer.get('visible') && f?.get('selectable')) {
            if (!actionFeatures.map((feature) => feature.getId()).includes(f.getId())) {
              actionFeatures.push(f);
            }
          }
        },
        { hitTolerance: this.isTouchScreen ? 8 : 6 }
      );

      if (actionFeatures?.length) {
        this.hidePopupOverlay();
        const actionFeature = actionFeatures[0];
        this.actionSelected.next(actionFeature);
        return;
      }

      if (!commentFeatures?.length) {
        this.hidePopupOverlay();
        return;
      }

      const commentFeature = commentFeatures[0];
      if (!commentFeature.get('selected')) {
        this.showPopupOverlay(commentFeature);
      }
    });
  }

  private onHoverMap(e: MapBrowserEvent<UIEvent>) {
    const features = [];
    this.map.forEachFeatureAtPixel(
      e.pixel,
      (f, layer) => {
        if (layer?.get('name') === 'lComment' && layer?.get('visible')) {
          features.push(f);
        }

        if (layer?.get('name') === 'lAction' && layer?.get('visible') && f?.get('selectable')) {
          features.push(f);
        }
      },
      { hitTolerance: 4 }
    );

    if (features.length) {
      document.getElementById(this.target).classList.add('clickable-map');
    } else {
      document.getElementById(this.target).classList.remove('clickable-map');
    }
  }

  private addActionLayer() {
    this.lAction = new VectorLayer({
      source: new VectorSource(),
      style: defaultStyle,
      zIndex: 40000,
    });

    this.lAction.set('name', 'lAction');
    this.map.addLayer(this.lAction);

    this.lAction.getSource().on('addfeature', (e) => {
      if (!this.editing) {
        return;
      }
      const geojson = this.getActionGeoJSON();
      this.actionChangeSubject.next({ geometry: geojson, feature: e.feature });
    });

    this.lAction.getSource().on('changefeature', () => {
      if (!this.editing) {
        return;
      }
      const geojson = this.getActionGeoJSON();
      this.actionChangeSubject.next({ geometry: geojson });
    });

    this.lAction.getSource().on('removefeature', () => {
      if (!this.editing) {
        return;
      }
      const geojson = this.getActionGeoJSON();
      this.actionChangeSubject.next({ geometry: geojson });
    });
  }

  private addCommentLayer() {
    this.lComment = new VectorLayer({
      source: new VectorSource(),
      style: COMMENT_MAP_STYLE,
      zIndex: 40000,
    });

    this.lDrawComment = new VectorLayer({
      source: new VectorSource(),
      style: COMMENT_MAP_STYLE,
      zIndex: 40001,
    });

    this.lComment.set('name', 'lComment');
    this.lComment.set('selectable', true);
    this.map.addLayer(this.lComment);

    this.lComment.on('change:visible', () => this.hidePopupOverlay());

    this.lDrawComment.set('name', 'lDrawComment');
    this.map.addLayer(this.lDrawComment);
  }

  public showPopupOverlay(feature: Feature<Point>) {
    this.lComment.getSource().forEachFeature((ft) => ft.set('selected', false));

    feature.set('selected', true);
    this.popupOverlay.show(feature?.getGeometry()?.getCoordinates());
    this.commentSelected.next({ thread: feature.get('thread'), actionplan: feature.get('actionplan') });
  }

  public hidePopupOverlay() {
    this.lComment.getSource().forEachFeature((f) => f.set('selected', false));

    this.lDrawComment.getSource().clear();

    this.popupOverlay?.hide();
  }

  public setActionsOverview(actions: IActionPlanItem[], styleGroup?: string) {
    this.stopDrawingAction();
    this.stopEditActions();
    this.stopRemoveAction();

    const source = this.lAction.getSource();
    source.clear();

    actions
      .filter((a) => a.location.geometry)
      .forEach((a) => {
        const features = new GeoJSON().readFeatures(a.location.geometry);
        features.forEach((f) => {
          f.setProperties(a);
          f.set('drawStyle', styleGroup === 'status' ? this.getStyleFromStatus(a.status) : a.location.style);
          f.set('selectable', true);
        });
        source.addFeatures(features);
      });

    this.map
      .getView()
      .animate({ center: this._defaultMapConfig.center, zoom: this._defaultMapConfig.zoom + 1, duration: 250 });
  }

  public setAction(actionLocation: IActionPlanItemLocation) {
    this.stopDrawingAction();
    this.stopEditActions();
    this.stopRemoveAction();

    const source = this.lAction.getSource();
    source.clear();

    if (!actionLocation || !actionLocation.geometry) {
      this.map
        .getView()
        .animate({ center: this._defaultMapConfig.center, zoom: this._defaultMapConfig.zoom, duration: 250 });
      return;
    }

    const features = new GeoJSON().readFeatures(actionLocation.geometry);
    features.forEach((f) => f.set('drawStyle', actionLocation.style));
    source.addFeatures(features);

    if (!features.length) {
      this.map
        .getView()
        .animate({ center: this._defaultMapConfig.center, zoom: this._defaultMapConfig.zoom, duration: 250 });
      return;
    }

    if (features.length === 1 && features[0].getGeometry().getType() === 'Point') {
      this.map
        .getView()
        .animate({ center: (features[0].getGeometry() as Point).getCoordinates(), zoom: 16, duration: 250 });
      return;
    }

    this.map.getView().fit(source.getExtent(), { padding: [100, 100, 100, 100], duration: 250 });
  }

  public setCommentsForAction(action: string) {
    this.hidePopupOverlay();

    this.lComment
      .getSource()
      .getFeatures()
      .forEach((f) => {
        if (f.get('action') === action) {
          f.set('hidden', false);
        } else {
          f.set('hidden', true);
        }
      });
  }

  setActionStyle(style: Partial<IStyle>) {
    const features = this.lAction.getSource().getFeatures();
    features?.forEach((f) => f.set('drawStyle', style));
  }

  private getStyleFromStatus(status: string) {
    const style: IStyle = {
      color: Color(STATUS_COLOR_MAPPER[status]).toString(),
    };
    return style;
  }

  public startDrawingAction(geometryType: GeometryType): Observable<DrawEvent> {
    this.stopDrawingAction();
    this.stopEditActions();

    this.iDrawAction = new DrawInteraction({
      source: this.lAction.getSource(),
      type: geometryType,
      freehandCondition: () => {
        return false;
      },
      stopClick: true,
      clickTolerance: this.isTouchScreen ? 8 : 6,
    });

    this.map.addInteraction(this.iDrawAction);
    this.editing = true;

    const drawSubject = new Subject<DrawEvent>();
    this.iDrawAction.once('drawend', (e) => {
      drawSubject.next(e);
    });

    return drawSubject.asObservable();
  }

  public stopDrawingAction() {
    this.iDrawAction?.abortDrawing();
    this.map.removeInteraction(this.iDrawAction);
    this.editing = false;
  }

  public startEditActions() {
    this.stopEditActions();

    this.iModifyAction = new ModifyInteraction({
      snapToPointer: true,
      features: new Collection(
        this.lAction
          .getSource()
          .getFeatures()
          .filter((f) => f.getGeometry().getType() !== 'Point')
      ),
    });

    this.map.addInteraction(this.iModifyAction);

    this.iTranslateAction = new TranslateInteraction({
      filter: (f: Feature<Geometry>, l: VectorLayer<VectorSource>) => {
        if (f?.getGeometry().getType() === 'Point' && l?.get('name') === 'lAction') {
          return true;
        }
        return false;
      },
    });

    this.map.addInteraction(this.iTranslateAction);

    this.editing = true;
  }

  public stopEditActions() {
    this.map.removeInteraction(this.iModifyAction);
    this.map.removeInteraction(this.iTranslateAction);
    this.editing = false;
  }

  public startRemoveAction(): Observable<SelectEvent> {
    this.stopRemoveAction();

    this.iRemoveAction = new SelectInteraction({
      layers: [this.lAction],
      hitTolerance: this.isTouchScreen ? 8 : 6,
    });

    this.map.addInteraction(this.iRemoveAction);
    this.editing = true;

    const selectSubject = new Subject<SelectEvent>();

    this.iRemoveAction.on('select', (e) => {
      e.selected?.forEach((f) => this.lAction.getSource().removeFeature(f));
      selectSubject.next(e);
    });

    return selectSubject.asObservable();
  }

  public stopRemoveAction() {
    this.map.removeInteraction(this.iRemoveAction);
    this.editing = false;
  }

  public setComments(threads: IActionCommentThread[]) {
    if (!threads) {
      return;
    }

    this.lComment.getSource().clear();

    this.lComment.getSource().addFeatures(
      threads
        .filter((t) => t.geometry)
        .map((t) => {
          const feature = new GeoJSON().readFeature(t.geometry) as Feature<Point>;
          feature.set('action', t.action);
          feature.set('thread', t._id);
          feature.set('actionplan', t.actionplan);
          feature.setId(t._id);
          return feature;
        })
    );
  }

  public startDrawingComment() {
    this.hidePopupOverlay();

    this.iDrawComment = new DrawInteraction({
      source: this.lDrawComment.getSource(),
      type: 'Point',
      freehandCondition: () => {
        return false;
      },
      stopClick: true,
      clickTolerance: this.isTouchScreen ? 8 : 6,
      style: COMMENT_DRAW_MAP_STYLE,
    });

    this.map.addInteraction(this.iDrawComment);

    const drawSubject = new Subject<DrawEvent>();
    this.iDrawComment.once('drawend', (e) => {
      e.feature.set('selected', true);
      drawSubject.next(e);
    });

    return drawSubject.asObservable();
  }

  public stopDrawingComment() {
    this.iDrawComment?.abortDrawing();
    this.map.removeInteraction(this.iDrawComment);
  }

  public removeComment() {
    this.hidePopupOverlay();
  }

  public hasCommentThreads(): boolean {
    return !!this.lComment?.getSource()?.getFeatures()?.length;
  }

  public setCommentsVisibility(visible: boolean) {
    this.lComment.setVisible(visible);
  }

  public getNewCommentGeometry() {
    const obj = new GeoJSON().writeFeatureObject(this.lDrawComment.getSource().getFeatures()[0]);
    delete obj.properties;
    return obj;
  }

  public fixComments(opts: { thread: string; actionplan: string }) {
    const { thread, actionplan } = opts;

    const features = [];
    this.lDrawComment
      .getSource()
      .getFeatures()
      .forEach((f, index) => {
        f.set('selected', false);
        f.set('thread', thread);
        f.set('actionplan', actionplan);
        f.setId(thread);

        features.push(f);

        if (index === this.lDrawComment.getSource().getFeatures().length - 1) {
          this.showPopupOverlay(f as Feature<Point>);
        }
      });

    this.lComment.getSource().addFeatures(features);
    this.lDrawComment.getSource().clear();
  }

  private getActionGeoJSON() {
    return new GeoJSON().writeFeaturesObject(this.lAction.getSource().getFeatures());
  }

  public zoomToComment(threadId: string) {
    this.setCommentsVisibility(true);
    const feature = this.lComment.getSource().getFeatureById(threadId);

    this.map.getView().animate({ center: feature.getGeometry().getCoordinates(), zoom: 18, duration: 250 });
    this.showPopupOverlay(feature);
  }

  // public resolveComment(threadId: string) {
  //   const feature = this.lComment.getSource().getFeatureById(threadId);
  //   this.lComment.getSource().removeFeature(feature);
  // }
}
