import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[descriptionModalContent]' })
export class DescriptionModalContentTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'maplix-description-modal-with-image',
  templateUrl: './description-modal-with-image.component.html',
  styleUrls: ['./description-modal-with-image.component.scss'],
})
export class DescriptionModalWithImageComponent implements AfterViewInit {
  @Input()
  public item: { id: string; title: string; description: string; longDescription: string; thumbnail: string };

  @Input()
  public selectText: string = 'Select';

  @Input()
  public cancelText: string = 'Cancel';

  @Input()
  public showSelectButton: boolean = true;

  @Input()
  public showCancelButton: boolean = false;

  @Output()
  private select: EventEmitter<void> = new EventEmitter();

  @Output()
  private cancel: EventEmitter<void> = new EventEmitter();

  @ContentChild(DescriptionModalContentTemplateDirective, { read: TemplateRef }) modalContentTemplate: TemplateRef<any>;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.showSelectButton) {
      (document.querySelector('#select-btn') as HTMLButtonElement)?.focus();
    }
  }

  public onSelect() {
    this.select.emit();
  }

  public onCancel() {
    this.cancel.emit();
  }
}
