import { Inject, Injectable } from '@angular/core';
import * as Realm from 'realm-web';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class RealmService {
  private app: Realm.App;

  private msalInstance: IPublicClientApplication;

  constructor(@Inject('environment') private environment: any, private cookie: CookieService) {
    this.app = new Realm.App({ id: environment.stitchId });
  }

  public auth = {
    loginWithEmail: (email, password): Promise<Realm.User> => {
      const credentials = Realm.Credentials.emailPassword(email, password);
      return this.app.logIn(credentials);
    },

    loginWithGoogle: async (): Promise<Realm.User> => {
      const credentials = Realm.Credentials.google(`${this.environment.apps.auth}redirect`);
      const user: Realm.User = await this.app.logIn(credentials);
      return user;
    },

    loginWithFacebook: async (redirectUrl: string): Promise<Realm.User> => {
      const credentials = Realm.Credentials.facebook(redirectUrl);
      const user: Realm.User = await this.app.logIn(credentials);
      return user;
    },

    registerWithEmail: (email, password): Promise<void> => {
      return this.app.emailPasswordAuth.registerUser(email, password);
    },

    resetPasswordWithEmail: (email): Promise<void> => {
      return this.app.emailPasswordAuth.callResetPasswordFunction(email, 'temporary', []);
    },

    resetPasswordConfirm: (token, tokenId, password): Promise<void> => {
      return this.app.emailPasswordAuth.resetPassword(token, tokenId, password);
    },

    registerWithEmailConfirm: (token: string, tokenid: string): Promise<void> => {
      return this.app.emailPasswordAuth.confirmUser(token, tokenid);
    },

    setApiKey: async (): Promise<void> => {
      const user: Realm.User = this.app.currentUser;
      const currentToken: string = this.cookie.get(`MaplixToken${this.environment.env}`);
      if (!currentToken) {
        const keys = await user.apiKeys.fetchAll();
        if (keys.length >= 10) {
          keys.sort((a, b) => {
            if (moment(a.name, 'DDMMYYYYHHmmss').diff(moment(b.name, 'DDMMYYYYHHmmss')) < 0) {
              return -1;
            } else if (moment(a.name, 'DDMMYYYYHHmmss').diff(moment(b.name, 'DDMMYYYYHHmmss')) === 0) {
              return -1;
            } else {
              return 1;
            }
          });
          await user.apiKeys.delete(keys[0]._id);
        }
        const apiKey = await user.apiKeys.create(moment().format('DDMMYYYYHHmmss'));
        this.cookie.set(`MaplixToken${this.environment.env}`, apiKey.key, 30, '/', this.environment.cookieDomain);
        this.cookie.set(`MaplixTokenId${this.environment.env}`, apiKey._id, 30, '/', this.environment.cookieDomain);
      }
    },

    removeApiKey: async (token): Promise<void> => {
      const credentials = Realm.Credentials.apiKey(token);
      const user: Realm.User = await this.app.logIn(credentials);
      const tokenId = this.cookie.get(`MaplixTokenId${this.environment.env}`);
      if (tokenId) {
        await user.apiKeys.delete(tokenId);
      }
    },

    logout: () => {
      this.cookie.delete(`userDetails${this.environment.env}`, '/', this.environment.cookieDomain);
      this.cookie.delete(`activeWorkspace${this.environment.env}`, '/', this.environment.cookieDomain);
      this.cookie.delete(`MaplixToken${this.environment.env}`, '/', this.environment.cookieDomain);
      this.cookie.delete(`MaplixTokenId${this.environment.env}`, '/', this.environment.cookieDomain);
      location.href = `${this.environment.apps.auth}login`;
    },

    resendConfirmEmail: (email: string) => {
      return this.app.emailPasswordAuth.retryCustomConfirmation({ email });
    },

    initializeMSAL: async (clientId: string) => {
      const msalConfig = {
        auth: { clientId },
      };

      this.msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);
    },

    loginWithMicrosoft: async () => {
      const payload = await this.msalInstance.loginPopup();
      const credentials = Realm.Credentials.function(payload);
      const user: Realm.User = await this.app.logIn(credentials);
      return user;
    },
  };
}
