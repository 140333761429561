import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@maplix/forms';
import { isEqual } from 'lodash';

export interface SelectorItem {
  value: any;
  label?: string;
  icon?: string;
  iconWrapper?: string;
}

@Component({
  selector: 'maplix-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {
  @Input()
  public items: SelectorItem[] = [];

  @Input()
  public disabledItems: SelectorItem[] = [];

  @Input()
  public control: FormControl<any>;

  @Input()
  protected unsettable: boolean = false;

  @Input()
  protected minWidth: number = 150;

  @Input()
  public bindLabel: string = 'label';

  @Input()
  protected bindValue: string = 'value';

  @Input()
  protected rating: boolean = false;

  @Input()
  public size: string = 'sm';

  @Input()
  public stacked: boolean;

  @Input()
  public translateLabels: boolean = false;

  @Output()
  protected onSelect: EventEmitter<any> = new EventEmitter();

  get disabled(): boolean {
    if (!this.control) {
      return false;
    }

    return this.control.disabled;
  }

  get minWidthCss(): string {
    return this.minWidth ? this.minWidth + 'px' : '0';
  }

  protected selectedIndex: number = null;

  protected hoveredRatingIndex: number = null;
  protected hoveredRatingIndices: number[] = [];

  constructor() {}

  ngOnInit() {
    if (this.control.value) {
      this.selectedIndex = this.control.value - 1;
    }
  }

  public isSelected(item: any) {
    const itemValue = this.getItemValue(item);
    const controlValue = this.control && this.control.value;

    return isEqual(itemValue, controlValue);
  }

  public isBeforeSelected(index: number) {
    if (!this.rating) {
      return;
    }

    if (!this.selectedIndex && this.selectedIndex !== 0) {
      return;
    }

    return index < this.selectedIndex;
  }

  public getItemValue(item): any {
    return this.bindValue ? item[this.bindValue] : item;
  }

  public getItemLabel(item, bindLabel): any {
    return bindLabel ? item[bindLabel] : item;
  }

  public onSelectItem(item, index) {
    if (!this.control) {
      console.error('Please enter a valid FormControl instance.');
      return;
    }
    const itemElements = document.querySelectorAll('.btn.selector-btn');
    itemElements.forEach((elem: HTMLButtonElement) => {
      elem.blur();
    });

    if (!this.control || this.disabled) {
      return;
    }

    this.selectedIndex = index;

    const value = this.bindValue ? item[this.bindValue] : item;
    if (value == this.control.value) {
      if (!this.unsettable) {
        return;
      }
      this.control.setValue(null);
      this.control.markAsDirty();
      this.onSelect.emit(null);
      return;
    }

    this.control.setValue(value);
    this.control.markAsDirty();
    this.onSelect.emit(value);
  }

  public isItemDisabled(item: any, disabledItems: any[]): boolean {
    return disabledItems && disabledItems.includes(item);
  }

  public onHoverItem(index) {
    if (!this.rating) {
      return;
    }

    this.hoveredRatingIndices = [...Array(index + 1).keys()];
  }

  public onStopHovering() {
    if (!this.rating) {
      return;
    }

    this.hoveredRatingIndices = [];
  }

  public isHovered(index) {
    if (!this.rating) {
      return;
    }

    this.hoveredRatingIndex = index;
    return this.hoveredRatingIndices.includes(index);
  }

  public isAfterHovered(index) {
    if (!this.rating) {
      return;
    }

    return index > this.hoveredRatingIndex;
  }
}
