import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Coordinate } from 'ol/coordinate';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { first } from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapSettingsService {
  public mapboxToken;

  private hClient: HttpClient;

  private headers: HttpHeaders;

  private loadingRouteSegment: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public loadingRouteSegment$: Observable<boolean> = this.loadingRouteSegment.asObservable();

  public get isLoadingRouteSegment(): boolean {
    return this.loadingRouteSegment.value;
  }

  constructor(@Inject('environment') environment: any, private httpHandler: HttpBackend) {
    this.mapboxToken = environment.mapboxToken;
    this.hClient = new HttpClient(this.httpHandler);
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
  }

  public calculateRoute(coordinates: [Coordinate, Coordinate], transportMode: string, distance?: number): Promise<any> {
    this.loadingRouteSegment.next(true);

    // On distances longer than 5k, always use directions api because map matching api will take long or throw an error
    if (distance && distance >= 5000) {
      return this.getDirections(coordinates, transportMode)
        .pipe(finalize(() => this.loadingRouteSegment.next(false)))
        .toPromise();
    }

    // Else, try map matching first. If it throws an error, or finds no match, use directions api.
    return this.getMatch(coordinates, transportMode, distance)
      .pipe(
        catchError(() => {
          return this.getDirections(coordinates, transportMode).pipe(
            map((geometry) => {
              return {
                code: 'Ok from directions',
                geometry,
              };
            })
          );
        }),
        switchMap((response: any) => {
          if (response.code === 'Ok') {
            const match: any = first(response.matchings);
            match.recalculateCyclingDuration = transportMode === 'BIKE' || transportMode === 'EBIKE';
            return of(match);
          }

          if (response.code === 'Ok from directions') {
            return of(response.geometry);
          }

          return this.getDirections(coordinates, transportMode);
        }),
        catchError((err) => {
          return throwError(err);
        }),
        finalize(() => this.loadingRouteSegment.next(false))
      )
      .toPromise();
  }

  private getMatch(coordinates: Coordinate[], transportMode: string, distance?: number) {
    const matchProfile =
      transportMode === 'CAR' ||
      transportMode === 'MOTOR' ||
      transportMode === 'SCOOTER' ||
      transportMode === 'BIKE' ||
      transportMode === 'EBIKE'
        ? 'mapbox/driving'
        : 'mapbox/walking';

    const ignore = matchProfile === 'mapbox/driving' ? '&ignore=access,oneways,restrictions' : '';

    const radiuses = !distance || distance >= 1000 ? '25;25' : distance >= 500 ? '20;20' : '10;10';

    return this.hClient.get(
      `https://api.mapbox.com/matching/v5/${matchProfile}/${coordinates[0][0]},${coordinates[0][1]};${coordinates[1][0]},${coordinates[1][1]}?overview=full&geometries=geojson&steps=false&radiuses=${radiuses}${ignore}&access_token=${this.mapboxToken}`,
      { headers: this.headers }
    );
  }

  private getDirections(coordinates: Coordinate[], transportMode: string) {
    const routeProfile =
      transportMode === 'CAR' || transportMode === 'MOTOR' || transportMode === 'SCOOTER'
        ? 'mapbox/driving'
        : transportMode === 'BIKE' || transportMode === 'EBIKE'
        ? 'mapbox/cycling'
        : 'mapbox/walking';

    return this.hClient
      .get(
        `https://api.mapbox.com/directions/v5/${routeProfile}/${coordinates[0][0]},${coordinates[0][1]};${coordinates[1][0]},${coordinates[1][1]}?overview=full&geometries=geojson&steps=false&access_token=${this.mapboxToken}`,
        { headers: this.headers }
      )
      .pipe(map((response: any) => first(response.routes)));
  }
}
