export const conditionOperators: { label: string, id: string }[] = [
    { id: 'equals', label: 'Equals' },
    { id: 'not-equal', label: 'Is not equal to' },
    { id: 'greater-than-or-equals', label: 'Is greater than or equals' },
    { id: 'smaller-than-or-equals', label: 'Is smaller than or equals' },
    { id: 'greater-than', label: 'Is greater than' },
    { id: 'smaller-than', label: 'Is smaller than' },
    { id: 'selected', label: 'Includes' },
    { id: 'not-selected', label: 'Does not include' },
    { id: 'dropdown-equals', label: 'Equals' },
    { id: 'not-dropdown-equal', label: 'Is not equal to' }
];