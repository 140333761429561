import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@maplix/forms';
import { ILanguage, IMapResult, IPopupInfo, ISurveyImage, ISurveyMap, MapType } from '@maplix/utils';
import { ILegendUrl, ImageCls, MapCls } from '../../class';

@Component({
  selector: 'maplix-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapEditorComponent implements OnInit {
  @Input()
  public readonly loading: boolean;

  @Input()
  public readonly mapType: MapType = MapType.MAP;

  @Input()
  public mapForm: FormGroup<IMapResult | ISurveyMap | ISurveyImage>;

  @Input()
  public readonly map: MapCls | ImageCls;

  @Input()
  public WMSLegendUrls: ILegendUrl;

  @Input()
  public popupInfo: IPopupInfo;

  @Input()
  public readonly language: ILanguage;

  @Input()
  public isPreview: boolean = false;

  @Input()
  public showLayerEditor: boolean;

  @Input()
  public editorWidth: number = 400;

  constructor() {}

  ngOnInit(): void {}
}
