import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LegendPosition } from '@maplix/utils';

@Component({
  selector: 'maplix-sidebar-toggle',
  templateUrl: './sidebar-toggle.component.html',
  styleUrls: ['./sidebar-toggle.component.scss'],
})
export class SidebarToggleComponent implements OnInit {
  @Input()
  public showSidebox: boolean;

  @Output()
  private sideboxChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public readonly showText: string = 'Show legend';

  @Input()
  public readonly icon: string;

  @Input()
  public position: LegendPosition = LegendPosition.RIGHT;

  constructor() {}

  ngOnInit() {}

  public onToggleSidebox() {
    this.sideboxChange.emit(this.showSidebox);
  }
}
