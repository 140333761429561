export enum StravaSportType {
  EBikeRide = 'EBikeRide',
  EMountainBikeRide = 'EMountainBikeRide',
  GravelRide = 'GravelRide',
  Handcycle = 'Handcycle',
  Hike = 'Hike',
  InlineSkate = 'InlineSkate',
  MountainBikeRide = 'MountainBikeRide',
  Ride = 'Ride',
  RollerSki = 'RollerSki',
  Run = 'Run',
  Skateboard = 'Skateboard',
  TrailRun = 'TrailRun',
  Velomobile = 'Velomobile',
  Walk = 'Walk',
  Wheelchair = 'Wheelchair',
  Workout = 'Workout',
}

export const SPORT_TYPE_ICON_MAPPER = {
  EBikeRide: 'mdi mdi-bike',
  EMountainBikeRide: 'mdi mdi-bike',
  GravelRide: 'mdi mdi-bike',
  Handcycle: 'mdi mdi-bike',
  Hike: 'mdi mdi-run',
  InlineSkate: 'mdi mdi-roller-skate',
  MountainBikeRide: 'mdi mdi-bike',
  Ride: 'mdi mdi-bike',
  RollerSki: 'mdi mdi-roller-skate',
  Run: 'mdi mdi-run',
  Skateboard: 'mdi mdi-skate',
  TrailRun: 'mdi mdi-run',
  Velomobile: 'mdi mdi-bike',
  Walk: 'mdi mdi-run',
  Wheelchair: 'mdi mdi-wheelchair-accessibility',
  Workout: 'mdi mdi-run',
};

export const SPORT_TYPE_TM_MAPPER = {
  EBikeRide: 'EBIKE',
  EMountainBikeRide: 'EBIKE',
  GravelRide: 'BIKE',
  Handcycle: 'BIKE',
  Hike: 'FOOT',
  InlineSkate: 'OTHER',
  MountainBikeRide: 'BIKE',
  Ride: 'BIKE',
  RollerSki: 'OTHER',
  Run: 'FOOT',
  Skateboard: 'OTHER',
  TrailRun: 'FOOT',
  Velomobile: 'BIKE',
  Walk: 'FOOT',
  Wheelchair: 'FOOT',
  Workout: 'FOOT',
};
