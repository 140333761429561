import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiListResponse } from '@maplix/api';
import { IOrganisation, IOrganisationMember, IUserDetails } from '@maplix/utils';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';
import { getRolePermissions } from './role-permissions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppInitService {
  public organisation: IOrganisation;

  public user: IUserDetails;

  public member: IOrganisationMember;

  public async load(cookie: CookieService, http: HttpClient, environment: any, translate: TranslateService) {
    const progressBar = document.querySelector('.maplix-loading-progress') as HTMLDivElement;
    const activeWorkspaceJSON = cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;

    if (!activeWorkspace) {
      throw TypeError('No organisation');
    }

    if (progressBar) {
      progressBar.style.width = '25%';
    }

    const org = await http
      .get<IOrganisation>(`${environment.api}organisations/${activeWorkspace._id}`)
      .pipe(
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (!org) {
      throw TypeError('No organisation');
    }

    this.organisation = org;
    if (progressBar) {
      progressBar.style.width = '60%';
    }

    const userDetailsJSON = cookie.get(`userDetails${environment.env}`);
    const userDetails = userDetailsJSON ? JSON.parse(userDetailsJSON) : null;

    if (!userDetails) {
      throw TypeError('No user');
    }

    const user = await http
      .get<IUserDetails>(`${environment.api}users/${userDetails._id}`)
      .pipe(
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (!user) {
      throw TypeError('No user');
    }

    this.user = user;

    if (progressBar) {
      progressBar.style.width = '80%';
    }

    const member = await http
      .get<IApiListResponse<IOrganisationMember>>(
        `${environment.api}organisations/${org._id}/members?where={"user":"${user._id}"}`
      )
      .pipe(
        map(({ _items }) => _items[0]),
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (!member) {
      throw TypeError('No member');
    }

    this.member = member;

    this.setPermissions();

    await translate.use(org.defaultLanguage?.code);
  }

  private setPermissions() {
    const roles = this.member.roles;

    if (!roles || !roles.length) {
      // throw TypeError('No member roles');
      return;
    }

    const permissions = getRolePermissions(roles);
    // load permissions with ngx-permissions
  }
}
