<ul class="mb-1">
  <li>
    {{
      answer.value_label === 'OTHER' || answer.value_label === 'OPTOTHER'
        ? ('survey.OTHER' | translate)
        : answer.value_label
        ? answer.value_label
        : answer.value
    }}
    {{ answer.value_other ? ': ' + answer.value_other : '' }}
  </li>
</ul>
