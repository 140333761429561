import { TranslateService } from '@ngx-translate/core';
import { Map, MapBrowserEvent } from 'ol';
import Overlay, { Options } from 'ol/Overlay';
import { MapSettingsService } from '../services';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Coordinate } from 'ol/coordinate';

export class DrawTooltip extends Overlay {
  private geomType: string;
  private modify: boolean;
  private started: boolean;

  private destroyed: Subject<void> = new Subject();

  constructor(
    options: Options,
    private map: Map,
    private translate: TranslateService,
    private mapSettingsService: MapSettingsService
  ) {
    super(options);

    const element: HTMLElement = document.createElement('div');
    element.className = 'draw-tooltip';

    this.setElement(element);
    this.setPositioning('top-left');
    this.setOffset([30, -15]);

    this.mapSettingsService.loadingRouteSegment$
      .pipe(
        tap(() => {
          this.setTooltip(this.getPosition());
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public create(geomType: string): void {
    this.geomType = geomType;
    this.started = false;
    this.map.addOverlay(this);
  }

  public next() {
    this.started = false;
    this.modify = true;
  }

  public remove(): void {
    this.started = false;
    this.modify = false;
    this.getElement().innerHTML = '';
    this.map.removeOverlay(this);
    this.setPosition([-100, -100]);
    this.destroyed.next();
  }

  public setTooltip(coordinate: Coordinate) {
    this.setPosition(coordinate);
    let text;

    if (this.mapSettingsService.isLoadingRouteSegment) {
      text =
        '<div class="d-flex align-items-center"><i class="fa fa-circle-notch fa-spin mr-2 font-20"></i>' +
        this.translate.instant('map.info.LOADINGROUTE') +
        '</div>';
    } else if (!this.modify) {
      text = this.translate.instant('map.info.' + this.geomType + (this.started ? 'END' : 'START'));
    } else {
      text = this.translate.instant('map.info.' + this.geomType + 'MODIFY');
    }
    this.getElement().innerHTML = text;
  }

  public setStarted(started: boolean) {
    this.started = started;
    if (started) {
      this.modify = false;
    }
  }

  public getGeomType(): string {
    return this.geomType;
  }

  public show() {
    this.setVisible(true);
  }

  public hide() {
    this.setVisible(false);
  }
}
