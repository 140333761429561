import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import * as Color from 'color';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'maplix-color-picker',
  templateUrl: './custom-color-picker.component.html',
  styleUrls: ['./custom-color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomColorPickerComponent implements OnInit, OnChanges {
  @Input()
  public color: string;

  public colorHex: string;

  public opacityPct: number;

  @Output()
  public colorChange: EventEmitter<ColorEvent>;

  @Input()
  public hideOpacity: boolean;

  @Input()
  public hideCode: boolean;

  private colorInputChange: Subject<any> = new Subject();
  private destroyed: Subject<void> = new Subject();

  constructor() {
    this.colorChange = new EventEmitter<ColorEvent>();
  }

  ngOnInit() {
    this.colorHex = Color(this.color).hex();
    this.opacityPct = Math.round(Color(this.color).alpha() * 100);

    this.colorInputChange
      .pipe(takeUntil(this.destroyed), distinctUntilChanged(), debounceTime(300))
      .subscribe(($event) => {
        this.colorChange.emit($event);
      });
  }

  ngOnChanges() {
    this.colorHex = Color(this.color).hex();
    this.opacityPct = Math.round(Color(this.color).alpha() * 100);
  }

  public onEmitChange($event: ColorEvent) {
    this.colorChange.emit($event);
  }

  public onChangeHex() {
    this.colorHex = this.colorHex.replace(/\s/g, '');
    if (new RegExp(/^[0-9a-fA-F]{6}/i).test(this.colorHex)) {
      this.colorHex = `#${this.colorHex}`;
    }

    if (new RegExp(/^#[0-9a-fA-F]{6}/i).test(this.colorHex)) {
      const rgba: any = Color(this.colorHex).rgb();
      const color: any = {
        color: {
          rgb: {
            r: rgba.color[0],
            g: rgba.color[1],
            b: rgba.color[2],
            a: this.opacityPct / 100,
          },
          hex: this.colorHex,
        },
      };
      this.colorInputChange.next(color);
    }
  }
}
